webClient.controller('AirPaymentResponseOK', ['$scope', '$window', '$timeout', '$filter', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'gtmModel', 'omnitureService',
    function ($scope, $window, $timeout, $filter, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, gtmModel, omnitureService) {
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        var searchUrl = "/";
        var bkgData;
        $scope.bkDataMock = tseUtil.Session.get('bkDataMock');

        if (!$scope.bkDataMock) {

            searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();
            bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
            if (bkgData) {

                var keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
                bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);
                var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
                bkgData.banqueCasinoSchedule = tseUtil.Session.get("banqueCasino" + keyGenerated);
            }
        } else {
            bkgData = $scope.bkDataMock.value;
            var keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
            var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
        }

        tseUtil.Session.set('paxHistory', {});
        $scope.bkgData = bkgData;
        $window.$scope = $scope;
        $scope.siteType = $window.site.type;
        window.hideMessageTO = true;
        $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true';
        $scope.enableUpSellProducts = $scope.bkgData.accommodations?.any() || $scope.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
        if ($scope.bkgData.accommodations?.any()) $window.bookingContainAccommodation = true;

        $scope.enablePostBookingInsurance = $window.enablePostBookingInsurance;

        $scope.enableMtrip = $window.enableMtrip;

        //don't show cross sell when an hotel is already booked
        $scope.hideCrossSell = typeof $scope.bkgData.accommodations !== 'undefined' && $scope.bkgData.accommodations != null && $scope.bkgData.accommodations.any();

        //Add P5 Stats
        try {
            window.Typescript.Services.OrderServiceService.StoreP5StatInfo($window.forceLemmingsHost, $scope.bkgData.request.requestId,
                $scope.bkgData.bookingResponse.bookingId, $scope.bkgData.bookingResponse.numbers[0].bookingNumber,
                "P5OK", $window.partnerName, $window.location.href);
        } catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }

        $scope.handleInit = () => {
            console.log("INSERT NEWSLETTER")
            const isSubscribedToNewsLetter = $scope.bkgData.bookingRequest.contact.isSubscribedToNewsLetter
            if ($window.partnerName !== "carrefour-dp" && $window.partnerName !== "carrefour" || !isSubscribedToNewsLetter) {
                console.log("RETURN NEWSLETTER : ", $window.partnerName, isSubscribedToNewsLetter)
                return
            }

            const bookingId = $scope.bkgData.bookingResponse.bookingId
            const clientId = window.site.clientId;
            const clientInfos = {
                mail: $scope.bkgData.bookingRequest.contact.mail,
                firstName: $scope.bkgData.bookingRequest.buyer.firstName,
                lastName: $scope.bkgData.bookingRequest.buyer.lastName,
                isSubscribedToNewsLetter: isSubscribedToNewsLetter
            };
            window.Typescript.Newsletter.Services.NewsletterService.addBookingNewsletterStatistic(bookingId, clientId, clientInfos)
                .catch(function (error) {
                    console.log(error)
                })
        }


        bkgData.totalBasketPrice = function () {

            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                // mandatory Options
                var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                var mandatoryOptionsFees = 0;
                if (proposal.mandatoryFees) {
                    mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                        return accumulator + mandatoryFee.totalPrice;
                    }, 0);
                }
                return bkgData.prixTotal.value + bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
            } else {
                return bkgData.prixTotal.value;
            }
        };

        //Add Tracking facebook


        try {
            if (window.site.partnerId == 'cdiscountvol') {

                var departingDepartureDate = moment($scope.bkgData.purchaseResponse.itineraries.first().departureDateTime).format("YYYY-MM-DD");
                var departingArrivalDate = moment($scope.bkgData.purchaseResponse.itineraries.first().arrivalDateTime).format("YYYY-MM-DD");
                var returningDepartureDate = $scope.bkgData.request.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().departureDateTime).format("YYYY-MM-DD");
                var returningArrivalDate = $scope.bkgData.request.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().arrivalDateTime).format("YYYY-MM-DD");

                var cabinClassName = function (item) {
                    switch (item) {
                        case "Y":
                            return "economy";
                        case "S":
                            return "premium";
                        case "C":
                            return "business";
                        case "F":
                            return "first";
                        default:
                            return "economy";
                    }
                };

                function waitForfbq() {
                    if (typeof fbq === "undefined") {
                        requestAnimationFrame(waitForfbq);
                        return;
                    }
                    fbq('track', 'Purchase', {
                        type_service: 'VOYAGES',
                        content_category: 'VOL',
                        content_type: 'flight',
                        content_ids: $scope.bkgData.request.origin.cityIATA + '-' + $scope.bkgData.request.destination.cityIATA,
                        value: $scope.bkgData.prixTotal.value,
                        currency: 'EUR',
                        origin_airport: $scope.bkgData.purchaseResponse.itineraries[0].origin.airportIata,
                        destination_airport: $scope.bkgData.purchaseResponse.itineraries[0].destination.airportIata,
                        departing_departure_date: departingDepartureDate,
                        returning_departure_date: returningDepartureDate,
                        departing_arrival_date: departingArrivalDate,
                        returning_arrival_date: returningArrivalDate,
                        num_adults: $scope.bkgData.request.adults > 0 ? $scope.bkgData.request.adults : 0,
                        num_children: $scope.bkgData.request.childs > 0 ? $scope.bkgData.request.childs : 0,
                        num_infants: $scope.bkgData.request.infants > 0 ? $scope.bkgData.request.infants : 0,
                        travel_class: cabinClassName($scope.bkgData.request.cabinClass),
                        compagnie: $scope.bkgData.purchaseResponse.itineraries[0].marketingCarrier.name,
                        preferred_num_stops: $scope.bkgData.purchaseResponse.itineraries[0].stops
                    });
                }

                requestAnimationFrame(waitForfbq);

                var returningDeparturedate = '';
                var departingArrivaldate = '';
                var destinationAirport = '';
                var returningArrivalDate = '';
                //Multi Destination
                if ($scope.bkgData.request.multiDestination) {
                    var itinerariesMultuDestination = $scope.bkgData.request.itineraries;
                    for (var i = 0; i <= itinerariesMultuDestination.length; i++) {
                        var itinerariesData = $scope.bkgData.purchaseResponse.itineraries[i];
                        destinationAirport = itinerariesData.destination.cityIATA;
                        var itinerariesSegment = itinerariesData.segments[itinerariesData.segments.length - 1].arrivalDateTime;
                        departingArrivaldate = moment(itinerariesSegment).format("DD-MM-YYYY");

                        //fbq('track', 'Purchase',
                        //    {
                        //        content_type: 'flight',
                        //        departing_departure_date: moment(itinerariesData.date).format("DD-MM-YYYY"),
                        //        returning_departure_date: returningDeparturedate,
                        //        origin_airport: itinerariesData.origin.cityIATA,
                        //        destination_airport: destinationAirport,
                        //        departing_arrival_date: moment(itinerariesData.date).format("DD-MM-YYYY"),
                        //        returning_arrival_date: returningArrivalDate,
                        //        num_adults: $scope.bkgData.request.adults > 0 ? $scope.bkgData.request.adults : 0,
                        //        num_children: $scope.bkgData.request.childs > 0 ? $scope.bkgData.request.childs : 0,
                        //        num_infants: $scope.bkgData.request.infants > 0 ? $scope.bkgData.request.infants : 0,
                        //        travel_class: $scope.bkgData.request.cabinClass == 'Y' ? 'economy' : 'business',
                        //        value: $scope.bkgData.prixTotal.value,
                        //        currency: 'EUR'
                        //    });

                        //fbq('track', 'Purchase', {
                        //    type_service: 'VOYAGES',
                        //    type_conversion: 'VENTE',
                        //    type_produit: 'VOL'
                        //});
                    }

                } else {

                    //Aller-Retour /aller simple
                    if ($scope.bkgData.request.roundTrip) {

                        var itinerariesData = $scope.bkgData.purchaseResponse.itineraries[0];
                        var itinerariesSegment = itinerariesData.segments[itinerariesData.segments.length - 1].arrivalDateTime;
                        returningDeparturedate = moment(itinerariesSegment).format("DD-MM-YYYY");
                        departingArrivaldate = moment($scope.bkgData.request.goDate2.date).format("DD-MM-YYYY");
                        returningArrivalDate = moment($scope.bkgData.purchaseResponse.itineraries[1].segments[itinerariesData.segments.length - 1].arrivalDateTime).format("DD-MM-YYYY");
                        destinationAirport = $scope.bkgData.request.origin2.cityIATA;
                    } else {
                        if ($scope.bkgData.request.oneWay) {
                            destinationAirport = $scope.bkgData.request.destination.cityIATA;
                            var itinerariesData = $scope.bkgData.purchaseResponse.itineraries[0];
                            var itinerariesSegment = itinerariesData.segments[itinerariesData.segments.length - 1].arrivalDateTime;
                            returningArrivalDate = departingArrivaldate = returningDeparturedate = moment(itinerariesSegment).format("DD-MM-YYYY");
                        }
                    }

                    //fbq('track', 'Purchase',
                    //    {
                    //        content_type: 'flight',
                    //        departing_departure_date: moment($scope.bkgData.request.goDate.date).format("DD-MM-YYYY"),
                    //        returning_departure_date: returningDeparturedate,
                    //        origin_airport: $scope.bkgData.request.origin.cityIATA,
                    //        destination_airport: destinationAirport,
                    //        departing_arrival_date: departingArrivaldate,
                    //        returning_arrival_date: returningArrivalDate,
                    //        num_adults: $scope.bkgData.request.adults > 0 ? $scope.bkgData.request.adults : 0,
                    //        num_children: $scope.bkgData.request.childs > 0 ? $scope.bkgData.request.childs : 0,
                    //        num_infants: $scope.bkgData.request.infants > 0 ? $scope.bkgData.request.infants : 0,
                    //        travel_class: $scope.bkgData.request.cabinClass == 'Y' ? 'economy' : 'business',
                    //        value: $scope.bkgData.prixTotal.value,
                    //        currency: 'EUR'
                    //    });

                    //fbq('track', 'Purchase', {
                    //    type_service: 'VOYAGES',
                    //    type_conversion: 'VENTE',
                    //    type_produit: 'VOL'
                    //});
                }
            }

        } catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }

        //Tracking
        try {
            //GTM
            if (trackingService.isActivated()) {
                trackingService.initDataLayer('purchase');
                if (trackingService.loadSessionData('step', 4) != null) { //can be null for few WL sites
                    trackingService.loadSessionData('step', 4).convertToPurchase($scope.bkgData.bookingResponse);
                }
                trackingService.parseScope($scope);
                trackingService.pushDataLayer(null, null);

                if (window.partnerName == "aeroports-voyages") {
                    trackingService.neoDataLayer('order_confirmation');
                }

                if (trackingService.pageViewEvent) {
                    trackingService.pageViewEvent("transaction_page", "airpaymentresponse");
                }

                if (trackingService.ecommerceEvent && $window.sessionStorage.getItem('pushedEcommerceEvent') !== 'true') {
                    window.sessionStorage.setItem('pushedEcommerceEvent', 'true');
                    trackingService.ecommerceEvent(true);
                }
            }

            // Tracking for veepee, veepee-it, veepee-es and dp-veepee
            callTrackingFunction('order_confirmation');

            //omniture
            if (omnitureService.isActivated()) {
                console.info("=========================  send cdtm FLIGHT CONFIRMATION -- 1/1 =================");
                omnitureService.sendCdtm(omnitureService.FLIGHT_CONFIRMATION, $scope.bkgData);
                omnitureService.sendEvent(omnitureService.FLIGHT_CONFIRMATION, omnitureService.CONFIRMATION, null);
            }

            $window._actGoal = { 'settings': { 'value': '/g/encrypted/goal/txSgBAH7u5l5vCnRgwHyzTFsLptmlpqw', 'type': 'TRANSACTION', 'goalUrl': 'https://www.actito.be:443/actg', 'additionalParameters': [{ 'name': 'goalId', 'value': '3' }, { 'name': 'businessId', 'value': $scope.bkgData.bookingResponse.numbers[0].bookingNumber }, { 'name': 'amountCent', 'value': String($scope.bkgData.prixTotal.value * 100) }, { 'name': 'source', 'value': '' }, { 'name': 'comment', 'value': '' }] } };
            
            (function () {

                var _head = document.head || document.getElementsByTagName("head")[0];

                var sp = document.createElement('script'); sp.type = 'text/javascript'; sp.async = true; sp.defer = true;

                sp.src = ('https:' == document.location.protocol ? 'https://cdn.actito.be' : 'http://cdn.actito.be') + '/legacy/actito-goal/goal.js';

                _head.appendChild(sp);

            })();
        }
        catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }

        if (bkgData) {
            //try {
            //    Raven.captureMessage("Payment OK", {
            //        extra: {
            //            Payment:
            //            {
            //                requestId: bkgData.bookingRequest.requestId,
            //                purchaseId: bkgData.purchaseResponse.purchaseId,
            //                paymentId: bkgData.bookingResponse.paymentId,
            //                paymentMethod:bkgData.bookingResponse.paymentMethod.paymentMethodReference,
            //                bookingId: bkgData.bookingResponse.bookingId,
            //                site: window.site.partnerId,
            //                step: 5
            //            }
            //        },
            //        tags: {
            //            paymentLogType: "PaymentOK",
            //            paymentLogWidgetType: "",
            //            requestId: bkgData.bookingRequest.requestId,
            //            purchaseId: bkgData.purchaseResponse.purchaseId,
            //            paymentId: bkgData.bookingResponse.paymentId,
            //            paymentMethod:bkgData.bookingResponse.paymentMethod.paymentMethodReference,
            //            bookingId: bkgData.bookingResponse.bookingId,
            //            site: window.site.partnerId,
            //            step: 5
            //        }
            //    });
            //} catch (e) {
            //    console.log(e);
            //}


            $scope.numbers = bkgData.bookingResponse.numbers;
            $scope.bookingId = bkgData.bookingResponse.bookingId;
            $scope.agentEmail = null;

            $scope.myBookingsLink = '/reservation/details?reference=' + $filter('bookingNumber')($scope.numbers[0].bookingNumber) + '&email=' + bkgData.bookingRequest.contact.mail;
            $scope.noInsuranceSelected = angular.equals({}, bkgData.insurancesSelected);
            $scope.todayDeparture = moment().format("YYYY/MM/DD") === moment(bkgData.request.itineraries[0].goDate).format("YYYY/MM/DD");


            if ($window.site.loginInfo && $window.site.loginInfo.Agent)
                $scope.agentEmail = $window.site.loginInfo.Agent.Account;

            if ($window.site.loginInfo && $window.site.loginInfo.Client)
                $scope.clientPaymentMode = $window.site.loginInfo.Client.PaymentMode;


            if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {

                window.sendDoubleClickConfirm("confvol", bkgData.purchaseResponse.itineraries[0].origin.airportIata, bkgData.purchaseResponse.itineraries[0].destination.airportIata, bkgData.bookingRequest.passengers.length, bkgData.prixTotal.value);
                window.sendDoubleClickConfirm("confpvfr", bkgData.purchaseResponse.itineraries[0].origin.airportIata, bkgData.purchaseResponse.itineraries[0].destination.airportIata, bkgData.bookingRequest.passengers.length, bkgData.prixTotal.value);

                bkgData.insurancesSelected = _insuranceSelected;

                if (bkgData.banqueCasinoSchedule != undefined)
                    bkgData.banqueCasino = true;

                if ($scope.bkgData.bookingRequest.paymentMethod.isOption)
                    $scope.bkgData.bookingRequest.paymentMode = 4;

                $scope.paymentMode = $scope.bkgData.bookingRequest.paymentMode;

                $scope.$broadcast("onBookingSummaryUpdate");
            }
            else {
                $window.FLoading({ vAction: 'show' });
                $window.location.href = searchUrl;
            }

            var totalTax = 0;
            for (var i = 0; i < bkgData.purchaseResponse.proposalsRequested.fares.length; i++) {
                var fare = bkgData.purchaseResponse.proposalsRequested.fares[i];
                totalTax += fare.totalTax.value;
            }

            var fareWithOutTaxes = bkgData.purchaseResponse.proposalsRequested.totalAmount.value - totalTax;
            $window.fareWithOutTaxes = fareWithOutTaxes;

            try {
                if ($window.enableVPTracking && CookieControl.getCategoryConsent(0) == true) {
                    $window.VPTracking();
                }
            }
            catch (ex)
            {

            }

            $(document).trigger('bkgdata-loaded');

            $window.sendGoogleTrans(bkgData.bookingResponse.bookingNumber, bkgData.prixTotal.value, $scope.bkgData.purchaseResponse.itineraries[0].destination.airportIata);
            $window.sendGoogleConversion(bkgData.prixTotal.value);
        }
        else {
            $window.FLoading({ vAction: 'show' });
            $window.location.href = searchUrl;
        }

        //Taxes details click
        $scope.$on("displayTaxesDetailsClick", function (event, args) {
            $scope.displayTaxesDetailModal = true;
            $scope.modalTaxesKey = args.key.replaceAll(":", "-");
            $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
            $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
            $scope.modalTaxesPaxType = args.paxType;
            $scope.modalTaxesPaxFares = args.paxFares;
            $scope.modalPaxNumber = args.paxNumber;

            $scope.modalShowMargin = false;

            var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

            args.marketingCarriers.each(function (value, key) {

                if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                    $scope.modalShowMargin = true;
                }
            });

            $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, paxType: $scope.modalTaxesPaxType, fares: $scope.modalTaxesPaxFares, paxnumber: $scope.modalPaxNumber });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
            },
                200, false);

        });

        //Luaggage details click
        $scope.$on("displayLuggageDetailsClick", function (event, args) {
            $scope.displayLuggageDetailModal = true;
            $scope.modalLuggageBagType = args.bagType;

            $scope.$broadcast("updateLuggageDetailModalData", { bagType: $scope.modalLuggageBagType });

            $scope.modalShowMargin = false;

            var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

            args.marketingCarriers.each(function (value, key) {

                if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                    $scope.modalShowMargin = true;
                }
            });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $('[data-remodal-id="luggage-detail-modal"]').remodal().open();
            }, 200, false);
        });

        //don't show cros sell when we already have an hotel
        if ($scope.hideCrossSell === false) {
            //hotel cross sell load data
            console.log("hotel cross sell load data!!!");
            var airport = $scope.bkgData.purchaseResponse.itineraries[0].destination.airportIata;
            var fromDate = moment($scope.bkgData.purchaseResponse.itineraries[0].arrivalDateTime).format("YYYY-MM-DD");
            var toDate = null;
            if ($scope.bkgData.purchaseResponse.itineraries[1]) {
                toDate = moment($scope.bkgData.purchaseResponse.itineraries[1].departureDateTime).format("YYYY-MM-DD");
            }
            else {
                toDate = moment(fromDate).add(1, 'days').format("YYYY-MM-DD");
            }
            var nbPaxes = bkgData.request.totalPaxes;
            var arPax = [];
            for (i = 0; i < nbPaxes; i++) {
                var age = Math.floor(moment(new Date()).diff(moment(bkgData.bookingRequest.passengers[i].birthDate, "DD/MM/YYYY"), 'years', true));
                if (age < 17) arPax[i] = { "type": "CHILD", "age": age, "birthDate": moment(bkgData.bookingRequest.passengers[i].birthDate, ["DD/MM/YYYY", "YYYY-MM-DD"]).format('YYYY-MM-DD'), "lastName": bkgData.bookingRequest.passengers[i].lastName, "firstName": bkgData.bookingRequest.passengers[i].firstName };
                else arPax[i] = { "type": "ADULT", "age": age, "birthDate": moment(bkgData.bookingRequest.passengers[i].birthDate, ["DD/MM/YYYY", "YYYY-MM-DD"]).format('YYYY-MM-DD'), "lastName": bkgData.bookingRequest.passengers[i].lastName, "firstName": bkgData.bookingRequest.passengers[i].firstName };
            }


            var crossSellInterval = setInterval(function () {

                window.hotelXSellParam = {
                    "destination": {
                        "type": "airport",
                        "value": airport
                    },
                    "date": {
                        "from": fromDate,
                        "to": toDate
                    },
                    "nbPax": nbPaxes,
                    "pax": arPax
                };

                if (typeof crossSellController !== "undefined") {
                    if ($('#crossSellDiv').length == 0) {
                        crossSellController.fetchSearchFormContent();
                    }
                    else {
                        clearInterval(crossSellInterval);
                    }
                }
            }, 500);
        }
        else {
            if ($('#crossSellDiv').length === 0) {
                $('#crossSellDiv').remove();
            }
        }
        getBundleWidget("mrfly-widget-mtrip.js").done(function () {
            if (mrflyWidgets["mrfly-widget-mtrip"] !== undefined) {
                mrflyWidgets["mrfly-widget-mtrip"].$refs.instance.bookingnumber = $scope.bkgData.bookingResponse.numbers[0].bookingNumber;
            }
        });
    }
]);