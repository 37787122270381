/**
* @desc the ngBookingPurchaseStepOne provides support for collect and displaying information of passengers, flexy and branded fares. This is used in AirBookingBody
* @example:
* <div ng-booking-page-one request="bkgData.request" flexy="bkgData.bookingRequest.flexyFare" proposal="proposal" passengers="paxAmount" paxname="paxType" paxinfo="bkgData.bookingRequest.paxes" datelastarrival="lastDate" cards="cards" time="newProcess" selection="bkgData.selection"></div>
*/

webClient.directive('ngBookingPurchaseStepOne', ['$window', '$timeout', '$i18next',
    'templateService', 'tseUtil', 'tseApiClient', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($window, $timeout, $i18next, templateService, tseUtil, tseApiClient, trackingService, gtmProduct, omnitureService) {
        var directive = {};
        directive.restrict = 'EA';
        directive.scope = {
            request: "=",
            passengers: "=",
            paxname: "=",
            proposal: "=",
            itineraries: "=",
            paxinfo: "=",
            informationdata: "=",
            triptype: "=",
            flexyfare: "=",
            cards: "=",
            time: "=",
            bestprice: "=",
            datelastarrival: "=",
            hideflexy: "=",
            hideinsurance: "=",
            hideluggage: "=",
            purchaseresponse: "="
        };
        directive.link = function ($scope, $element) {

            templateService.compile($scope, $element, 'tpl/_PurchaseStep1TPL.html');
            $scope.isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
            $scope.key = "_fsx" + window.site.partnerId;
            $scope.hasFlightHotelFormSummary = hasCookie("FlightHotelFormSummary") && $window.getUrlVars()['hotelSummary'] === 'true';
            $scope.isServair = window.site.partnerId === "servair";
            //Add Cnas and Cnas dom tom
            $scope.isCnas = window.site.partnerId === "cnas";
            $scope.isCnasDomTom = window.site.partnerId === "cnasDomTom";
            $scope.isB2b = $window.site.type === 2;
            $scope.isB2c = $window.site.type === 1;
            $scope.isWl = $window.site.type === 6;
            $scope.isAdosspp = window.site.partnerId === "adosspp";
            $scope.freeflexLoaded = false;
            if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;
            $scope.isEuropa = window.partnerName === "europa_multipartner";
            $scope.addToHistory = function (request) {

                var req = request;

                var _type = request.roundTrip ? 0 : request.oneWay ? 1 : request.openJaw ? 2 : request.multiDestination ? 2 : 0;
                var _cabinClass = request.cabinClass;
                var _origin = request.itineraries[0].origin ? request.itineraries[0].origin.cityName + ' (' + (request.itineraries[0].origin.airportName || request.itineraries[0].origin.name) + ') ' + '(' + request.itineraries[0].origin.cityIATA + ')' : '';
                var _origin_value = request.itineraries[0].origin.value;
                var _destination = request.itineraries[0].destination ? request.itineraries[0].destination.cityName + ' (' + (request.itineraries[0].destination.airportName || request.itineraries[0].destination.name) + ') ' + '(' + request.itineraries[0].destination.cityIATA + ')' : '';
                var _destination_value = request.itineraries[0].destination ? request.itineraries[0].destination.value : '';
                var _goDate = request.itineraries[0].goDate.dateString;
                var _returnDate = request.roundTrip ? request.itineraries[1].goDate.dateString : '';
                var _adults = request.adults;
                var _childrens = request.childs;
                var _infants = request.infants;

                var _origin2 = "";
                var _origin2_value = "";
                var _destination2 = "";
                var _destination2_value = "";
                var _goDate2 = "";
                var _origin3 = "";
                var _origin3_value = "";
                var _destination3 = "";
                var _destination3_value = "";
                var _goDate3 = "";
                var _origin4 = "";
                var _origin4_value = "";
                var _destination4 = "";
                var _destination4_value = "";
                var _goDate4 = "";
                var _origin5 = "";
                var _origin5_value = "";
                var _destination5 = "";
                var _destination5_value = "";
                var _goDate5 = "";
                var _origin6 = "";
                var _origin6_value = "";
                var _destination6 = "";
                var _destination6_value = "";
                var _goDate6 = "";

                adultCount = 0;
                childCount = 0;
                infantCount = 0;

                if (request.multiDestination || request.openJaw) {
                    if (request.itineraries[1]) {
                        _origin2 = request.itineraries[1].origin.cityName + ' (' + (request.itineraries[1].origin.airportName || request.itineraries[1].origin.name) + ') ' + '(' + request.itineraries[1].origin.cityIATA + ')';
                        _origin2_value = request.itineraries[1].origin.value;
                        _destination2 = request.itineraries[1].destination.cityName + ' (' + (request.itineraries[1].destination.airportName || request.itineraries[1].destination.name) + ') ' + '(' + request.itineraries[1].destination.cityIATA + ')';
                        _destination2_value = request.itineraries[1].destination.value;
                        _goDate2 = request.itineraries[1].goDate.dateString;
                    }
                    if (request.itineraries[2]) {
                        _origin3 = request.itineraries[2].origin.cityName + ' (' + (request.itineraries[2].origin.airportName || request.itineraries[2].origin.name) + ') ' + '(' + request.itineraries[2].origin.cityIATA + ')';
                        _origin3_value = request.itineraries[2].origin.value;
                        _destination3 = request.itineraries[1].destination.cityName + ' (' + (request.itineraries[2].destination.airportName || request.itineraries[2].destination.name) + ') ' + '(' + request.itineraries[1].destination.cityIATA + ')';
                        _destination3_value = request.itineraries[2].destination.value;
                        _goDate3 = request.itineraries[2].goDate.dateString;
                    }
                    if (request.itineraries[3]) {
                        _origin4 = request.itineraries[3].origin.cityName + ' (' + (request.itineraries[3].origin.airportName || request.itineraries[3].origin.name) + ') ' + '(' + request.itineraries[3].origin.cityIATA + ')';
                        _origin4_value = request.itineraries[3].origin.value;
                        _destination4 = request.itineraries[3].destination.cityName + ' (' + (request.itineraries[3].destination.airportName || request.itineraries[3].destination.name) + ') ' + '(' + request.itineraries[3].destination.cityIATA + ')';
                        _destination4_value = request.itineraries[3].destination.value;
                        _goDate4 = request.itineraries[3].goDate.dateString;
                    }
                    if (request.itineraries[4]) {
                        _origin5 = request.itineraries[4].origin.cityName + ' (' + (request.itineraries[4].origin.airportName || request.itineraries[4].origin.name) + ') ' + '(' + request.itineraries[4].origin.cityIATA + ')';
                        _origin5_value = request.itineraries[4].origin.value;
                        _destination5 = request.itineraries[4].destination.cityName + ' (' + (request.itineraries[4].destination.airportName || request.itineraries[4].destination.name) + ') ' + '(' + request.itineraries[4].destination.cityIATA + ')';
                        _destination5_value = request.itineraries[4].destination.value;
                        _goDate5 = request.itineraries[4].goDate.dateString;
                    }
                    if (request.itineraries[5]) {
                        _origin6 = request.itineraries[5].origin.cityName + ' (' + (request.itineraries[5].origin.airportName || request.itineraries[5].origin.name) + ') ' + '(' + request.itineraries[5].origin.cityIATA + ')';
                        _origin6_value = request.itineraries[5].origin.value;
                        _destination6 = request.itineraries[5].destination.cityName + ' (' + (request.itineraries[5].destination.airportName || request.itineraries[5].destination.name) + ') ' + '(' + request.itineraries[5].destination.cityIATA + ')';
                        _destination6_value = request.itineraries[5].destination.value;
                        _goDate6 = request.itineraries[5].goDate.dateString;
                    }
                }

                var _key = _type + _cabinClass + _adults + _childrens + _infants;
                if (_origin_value) _key += _origin_value;
                if (_destination_value) _key += _destination_value;
                if (_goDate) _key += _goDate;
                if (_returnDate) _key += _returnDate;
                if (_origin2_value) _key += _origin2_value;
                if (_destination2_value) _key += _destination2_value;
                if (_goDate2) _key += _goDate2;
                if (_origin3_value) _key += _origin3_value;
                if (_destination3_value) _key += _destination3_value;
                if (_goDate3) _key += _goDate3;
                if (_origin4_value) _key += _origin4_value;
                if (_destination4_value) _key += _destination4_value;
                if (_goDate4) _key += _goDate4;
                if (_origin5_value) _key += _origin5_value;
                if (_destination5_value) _key += _destination5_value;
                if (_goDate5) _key += _goDate5;
                if (_origin6_value) _key += _origin6_value;
                if (_destination6_value) _key += _destination6_value;
                if (_goDate6) _key += _goDate6;

                var historyData = [_key, _type, _cabinClass, _origin, _origin_value, _destination, _destination_value, _goDate, _returnDate, _origin2, _origin2_value, _destination2, _destination2_value, _goDate2, parseInt(_adults), parseInt(_childrens), parseInt(_infants), "", _origin3, _origin3_value, _destination3, _destination3_value, _goDate3, _origin4, _origin4_value, _destination4, _destination4_value, _goDate4,
                    _origin5, _origin5_value, _destination5, _destination5_value, _goDate5, _origin6, _origin6_value, _destination6, _destination6_value, _goDate6];

                try {
                    var currentHistory = localStorage.getItem($scope.key);
                    if (!currentHistory) {
                        currentHistory = [];
                    }
                    else {
                        currentHistory = JSON.parse(currentHistory);
                    }

                    if (!currentHistory.some(function (el) {
                        return el[0] == historyData[0];
                    })) {
                        currentHistory.push(historyData);
                        localStorage.setItem($scope.key, JSON.stringify(currentHistory || []));
                    }

                }
                catch (e) { }

            };

            window.localStorage.removeItem("hasFlexy");

            if ($scope.$parent.bkgData.accommodations) {
                $scope.showAccommodationPaxRepartition = !$scope.hasFlightHotelFormSummary;
            } else {
                $scope.showAccommodationPaxRepartition = false;
            }
            //if from meta add the search in the history
            // if(document.referrer.indexOf("airresults") === -1){
            $scope.addToHistory($scope.request);
            // }

            $scope.getT = function (key) {
                var res = $i18next.t(key.toLowerCase());
                return res;
            };

            var flexies = []
            if (!Array.isArray($scope.proposal.flexies)) {
                flexies = objectToArray($scope.proposal.flexies);
                console.log("---> goes here flexies is not array ! ");
            } else {
                flexies = $scope.proposal.flexies;
                console.log("---> goes here flexies is array ! ");

            }
            
            //Initialize Variables         
            if (!$scope.isServair) {
                var flexiestab = [];
                flexies.forEach(function (flexy) { flexiestab.push([flexy.value]) })
                $scope.freeFlexList = flexiestab;
            } else {
                $scope.freeFlexList = [];
            }

            //$scope.enableFreeFlex = $scope.proposal.flexies && $scope.proposal.flexies[0] && $scope.proposal.flexies[0].propose === 2 && !$scope.isServair;
            if ($scope.freeFlexList.length > 0 && !$scope.isServair) {
                
                if ($scope.freeFlexList[0][0].flexyTypology === "FLEXY") {
                    $scope.enableFreeFlex = false;//B2B
                } else {
                    $scope.enableFreeFlex = true;
                }
            } else {
                $scope.enableFreeFlex = false;
            }

            $scope.getUrl = $window.getUrl;
            $scope.getTemplateUrl = $window.getTemplateUrl;
            $scope.siteType = $window.site.type;
            $scope.enableFlexy = $window.enableFlexy;
            $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
            $scope.adults = $scope.request.adults; //Number by type of passenger
            $scope.childs = $scope.request.childs; //Number by type of passenger
            $scope.infants = $scope.request.infants;//Number by type of passenger
            $scope.mandatoryInformation = $scope.informationdata && ($scope.informationdata["MandatoryInformation"] == "True");
            $scope.remarkPassenger = $scope.proposal.remarkPassenger;
            //Not B2b
            $scope.hideSpecialRequest = $window.site.type != 2;
            if ($scope.enableFreeFlex) {
                $scope.showFlexy = false;
            } else {
                $scope.showFlexy = $scope.freeFlexList.length > 0 && $scope.freeFlexList[0][0].totalPrice > 0 && !$scope.hideflexy && $scope.enableFlexy;
                var flexyData = {};
                flexyData.averagePriceInt = 0;
                flexyData.averagePriceDec = "00";
                if ($scope.showFlexy) {
                    var flexyDataLength = $scope.freeFlexList[0][0].averagePrice.toString().split('.');
                    if (flexyDataLength.length > 1) {
                        flexyData.averagePriceInt = flexyDataLength[0];
                        flexyData.averagePriceDec = flexyDataLength[1];
                    } else {
                        flexyData.averagePriceInt = flexyDataLength[0];
                    }
                }
                $scope.flexyData = flexyData;
            }
           
            $scope.paxes = [];
            $scope.hasDoubles = false;
            $scope.blockCgosPurchase = false;
            $scope.enableSeatMap = $scope.purchaseresponse.seatMap === true && !(typeof $window.accountNumber !== 'undefined' && typeof $window.seatMapAccountNumber !== 'undefined' && $window.accountNumber != $window.seatMapAccountNumber);
            if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;
            $scope.partnerName = $window.partnerName;

            $scope.loadingSeatMap = false;
            $scope.seatmapDataReady = false;
            $scope.previousProposalIndex = -1;
            $scope.maybeShouldReapplySeats = false;

            //Params
            var params = $window.getUrlVars();
            if (!$scope.paxinfo[0].lastName && params['lastname']) {
                $scope.paxinfo[0].lastName = decodeURIComponent(params['lastname'].replace(/\+/g, '%20'));
            }

            if (!$scope.paxinfo[0].firstName && params['firstname']) {
                $scope.paxinfo[0].firstName = decodeURIComponent(params['firstname'].replace(/\+/g, '%20'));
            }
            if (!$scope.paxinfo[0].phone && params['phone']) {

                var input = decodeURIComponent(params['phone']);
                if (libphonenumber.isValidNumber(input)) {
                    var phoneNb = libphonenumber.parse(input);
                    $scope.paxinfo[0].phone = phoneNb.phone;
                    $scope.paxinfo[0].phoneCountryCode = $window.countriesCode.filter(function (c) { return c.iso === phoneNb.country; })[0].code;
                }
            }

            if (!$scope.paxinfo[0].birthDate && params['birthdate']) {
                var dateString = $scope.datelastarrival;
                var birthDate = moment(decodeURIComponent(params['birthdate']));
                var dateMin = moment(dateString).subtract(100, 'years');

                if (window.site.partnerId === "visabusiness") var dateMax = moment(dateString).subtract(18, 'years');
                else var dateMax = moment(dateString).subtract(12, 'years');

                if (birthDate > dateMin && birthDate < dateMax) {
                    $scope.paxinfo[0].birthDate = moment(decodeURIComponent(params['birthdate']), "DD/MM/YYYY").format("DD/MM/YYYY");
                }
            }

            if (!$scope.paxinfo[0].title && params['title']) {
                if (params['title'] === "1" || params['title'] === "2") {
                    $scope.paxinfo[0].title = params['title'];
                }
            }
            // GetPriceAmountDecAndInt
            $scope.getPriceDecAndInt = function (price) {
                var priceData = {};
                var priceSplit = price.toString().split('.');
                priceData.int = priceSplit[0];
                if (priceSplit.length > 1) {
                    priceData.dec = priceSplit[1];
                } else {
                    priceData.dec = "00";
                }
                var result = priceData.int + "," + priceData.dec;
                return result;
            }

            $scope.updateAccommodationPaxes = function (paxes) {

                
                if (mrflyWidgets['mrfly-widget-accommodation-pax-form']) {
                    console.log("update paxinfo");
                    console.log(paxes);
                    for (var i = 0; i < paxes.length; i++) {
                        paxes[i].id = i;
                    }

                    hotelHelper.getPassangerHotelType(paxes, $scope.purchaseresponse.itineraries[1].date);

                    //splice(0) does like a clone(). Needed to trigger pxes watch event in widget
                    mrflyWidgets['mrfly-widget-accommodation-pax-form'].paxes = paxes.slice(0);
                }
            };

            if (!$scope.hideSpecialRequest) {
                getBundleWidget("mrfly-widget-special-request-purchase.js").done(function () {
                }).fail(function (jqxhr, settings, exception) {
                    console.log(exception);
                });
            }

            //Load Free Flex widget
            $scope.loadFreeFlex = function () {
                var flexData = 0;
                for (var i = 0; i < $scope.freeFlexList.length; i++) {
                    if ($scope.freeFlexList[i][0].selectable) {
                        $scope.freeFlexList[i][0].isActive = false;
                        flexData++;
                    }
                }
                if (flexData == 3) {
                    window.sessionStorage.setItem("inssurance.flex.flexDatalength", flexData);
                } else{
                    window.sessionStorage.setItem("inssurance.flex.flexDatalength", 0);
                }
                //check display old or new widget
                var widgetVersion = "mrfly-widget-flex.js";

                console.log(">>>>>>>" + parseInt($scope.freeFlexList[0][0].flexyReference));

                if (parseInt($scope.freeFlexList[0][0].flexyReference) >= 12 || $scope.showFlexy)
                    widgetVersion = "mrfly-widget-flex-v4.js";

                getBundleWidget(widgetVersion).done(function () {
                    var nbrPax = $scope.adults + $scope.childs + $scope.infants;

                    console.log("----> get BUNDLE WIDGET !!!! ----> $scope.freeFlexList  " + JSON.stringify($scope.freeFlexList));
                    mrflyWidgets["mrfly-widget-flex"].$refs.instance.flexData = $scope.freeFlexList;
                    mrflyWidgets["mrfly-widget-flex"].$refs.instance.nbrPax = nbrPax;
                    mrflyWidgets["mrfly-widget-flex"].$refs.instance.nbrAdtPax = $scope.adults;
                    mrflyWidgets["mrfly-widget-flex"].$refs.instance.nbrChdPax = $scope.childs;
                    mrflyWidgets["mrfly-widget-flex"].$refs.instance.nbrInfPax = $scope.infants;
                    mrflyWidgets["mrfly-widget-flex"].$refs.instance.showFlexy = $scope.showFlexy;

                    var offerData = window.sessionStorage.getItem("inssurance.totalflex.selectedOffer");
                    var flexRequestId = window.sessionStorage.getItem("inssurance.totalflex.requestId");
                    if (window.sessionStorage.getItem("inssurance.totalflex.selected")) {
                        mrflyWidgets["mrfly-widget-flex"].$refs.instance.noOption = true;
                    }
                    if (offerData != null && flexRequestId == $scope.$parent.bkgData.purchaseRequest.requestId) {
                       
                        mrflyWidgets["mrfly-widget-flex"].$refs.instance.selectedOffer = offerData;
                    }
                    
                    mrflyWidgets["mrfly-widget-flex"].$refs.instance.$on('selectedOffer', function (selectedOffer) {
                        //var result = $scope.freeFlexList.filter(x => x[0].flexyId == selectedOffer);
                        var result = [];
                        for (var i = 0; i < $scope.freeFlexList.length; i++) {
                            if ($scope.freeFlexList[i][0].flexyId == selectedOffer) {
                                $scope.freeFlexList[i][0].isActive = true;
                                result.push($scope.freeFlexList[i][0]);
                                break;
                            }
                        }

                        if (result.length > 0) {
                            window.sessionStorage.setItem("inssurance.totalflex.selected", true);
                            window.sessionStorage.setItem("inssurance.totalflex.selectedOffer", selectedOffer);
                            window.sessionStorage.setItem("inssurance.totalflex.requestId", $scope.$parent.bkgData.purchaseRequest.requestId);
                            var titleDescriptionItems = [];
                            var resultItem = result[0];
                            for (var i = 0; i < resultItem.prices.length; i++) {
                                var titleDescription = {};
                                titleDescription.priceAmount = resultItem.prices[i].priceAmount;
                                titleDescription.totalFlexyAmount = resultItem.prices[i].totalFlexyAmount;
                                titleDescription.paxType = resultItem.prices[i].paxType;
                                titleDescription.ptc = resultItem.prices[i].ptc;
                                titleDescription.quantity = resultItem.prices[i].quantity;
                                titleDescription.flexyDescription = resultItem.flexyDescription;
                                titleDescriptionItems.push(titleDescription);
                            }
                            $scope.$emit('totalFlexySelected', {
                                selected: true,
                                amount: resultItem.totalPrice,
                                paxTotalFlexPrices: resultItem.prices,
                                textFlexSelected: resultItem.flexyDescription,
                                titleDescriptionFlexyItems: titleDescriptionItems,
                                flexyReference: resultItem.flexyReference,
                                flexyId: resultItem.flexyId,
                                isActive: resultItem.isActive
                            });
                           
                        }
                        else {
                            window.sessionStorage.setItem("inssurance.totalflex.selected", false);
                            window.sessionStorage.setItem("inssurance.totalflex.selectedOffer", null);
                            window.sessionStorage.setItem("inssurance.totalflex.requestId", null);
                            $scope.$emit('totalFlexySelected', {
                                selected: false,
                                amount: 0,
                                textFlexSelected: "",
                                titleDescriptionFlexyItems: titleDescriptionItems,
                                flexyReference: null,
                                flexyId: null,
                                isActive: false
                            });
                        }

                    });
                    $scope.freeflexLoaded = true;
                }).fail(function (jqxhr, settings, exception) {
                    console.log(exception);
                });
            };

           
            if ($scope.enableFlexy && $scope.showFlexy || $scope.enableFreeFlex) {
                setTimeout(function () {
                    $scope.freeflexLoaded = true;
                    $scope.$apply();
                    $scope.loadFreeFlex();
                }, 200);
            }


            $scope.updateAccommodationPaxes($scope.paxinfo);

            $scope.$watch('proposal', function () {
                if (!$scope.isServair) {
                    var result = [];

                    var flexiestab = [];
                    if ($scope.proposal.flexies) {
                        if (!Array.isArray($scope.proposal.flexies)) {
                            objectToArray($scope.proposal.flexies).forEach(function (flexy) { flexiestab.push([flexy.value]) });
                        } else {
                            $scope.proposal.flexies.forEach(function (flexy) { flexiestab.push([flexy.value]) });
                        }
                    }

                    $scope.freeFlexList = flexiestab;

                    if (typeof (mrflyWidgets["mrfly-widget-flex"]) !== 'undefined') {
                        mrflyWidgets["mrfly-widget-flex"].$refs.instance.flexData = $scope.freeFlexList;
                        mrflyWidgets["mrfly-widget-flex"].$refs.instance.$emit('selectedOffer', mrflyWidgets["mrfly-widget-flex"].$refs.instance.selectedOffer);
                    }
                } else {
                    $scope.freeFlexList = [];
                }

                if ($scope.previousProposalIndex != $scope.proposal.index) {
                    $scope.maybeShouldReapplySeats = false;
                    if ($scope.previousProposalIndex == -1) {
                        $scope.maybeShouldReapplySeats = true;
                    }
                    $scope.previousProposalIndex = $scope.proposal.index;
                    tryToGetSeatMap();
                }
            }, true);

            $scope.$watch('paxinfo', function () {
                //Update accommodation
                $scope.updateAccommodationPaxes($scope.paxinfo);
                
                //check if
                

                if (mrflyWidgets['mrfly-widget-seatmap']) {

                    console.log("update airSeatMapRQ");

                    var data = JSON.parse(JSON.stringify(mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.airSeatMapRS));
                    data.airSeatMapRQ.passengers = $scope.paxinfo;
                    mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.airSeatMapRS = data;
                }
                if (mrflyWidgets["mrfly-widget-special-request-assistance"]) {
                    var paxInfos = JSON.parse(JSON.stringify($scope.paxinfo));
                    paxInfos.forEach(function (pax, index) {
                        if (pax.paxType === "0") text = $i18next.t("airbooking.luggage.adult") + " " + pax.typeCount;
                        if (pax.paxType === "1") text = $i18next.t("airbooking.luggage.child") + " " + pax.typeCount;
                        if (pax.paxType === "2") text = $i18next.t("airbooking.luggage.infant") + " " + pax.typeCount;

                        if (!pax.firstName) paxInfos[index].firstName = text;
                        if (!pax.lastName) paxInfos[index].lastName = "";
                        pax.index === 0 ? pax.active = true : pax.active = false;
                    });

                    mrflyWidgets["mrfly-widget-special-request-assistance"].$refs.instance.paxes = paxInfos;
                }

            }, true);

            //Additional baggage
            $scope.hasAdditionalBaggages = $scope.itineraries.any(function (x) { return x.additionalBaggages.length > 0; });

            //BestPrice
            //$scope.displayBestPrice = $scope.proposal.totalBestPrice && $scope.proposal.totalBestPrice.value > 0;
            //$scope.bestPricesAux = $window.objectClone($scope.proposal.fares);
            //$scope.totalBestPricesAux = $scope.proposal.totalBestPrice;

            //if ($scope.proposal.totalBestPrice)
            //    $scope.unitBestPrice = $scope.proposal.totalBestPrice.value / $scope.request.totalPaxes;

            //Add InspecLet Additional Baggage tag
            if ($scope.hasAdditionalBaggages) {
                window.sendInspectlet('tagSession', { HasAdditionalBaggages: true });
            }

            //Add InspecLet Uncompleted tag
            window.sendInspectlet('tagSession', { BookingOK: "Uncompleted" });

            //Flexy: Check conditions to show flexy      
            if ($window.localFlexyValidation) {
                if (!$scope.enableFlexy)
                    $scope.showFlexy = false;
            }

            //Select Flexy: Event to notify FlightBookingBody controller that flexy was added, item: true or false
            $scope.selectFlexy = function (item, flexyId) {
                $window.sessionStorage.setItem("inssurance.flexy.selectedOffer", flexyId);
                $window.sessionStorage.setItem("inssurance.flexyRequest.selectedOffer", $scope.$parent.bkgData.purchaseRequest.requestId);
                $scope.$emit("onFlexyClick", { isFlexy: item, flexyId: flexyId });
            };

            $scope.drawerUpdateFlexyOpened = false;
            $scope.toggleDrawerUpdateFlexy = function () {
                $scope.drawerUpdateFlexyOpened = !$scope.drawerUpdateFlexyOpened;
            };
            $scope.closeDrawerUpdateFlexy = function () {
                if ($scope.drawerUpdateFlexyOpened) {
                    $scope.drawerUpdateFlexyOpened = false;
                }
            };

            //afficher plus d'info
            $scope.moreInfoOpened = false;
            $scope.toggleMoreInfoOpened = function () {
                $scope.moreInfoOpened = !$scope.moreInfoOpened;
            };
            $scope.closeMoreInfoOpened = function () {
                if ($scope.moreInfoOpened) {
                    $scope.moreInfoOpened = false;
                }
            };

            //New Flexy Radio selection
            $scope.selectFlexyOption = function (id) {
                for (var i = 1; i <= 5; i++) {
                    if (id == ("i-smooth-flexy-option-" + i)) {
                        FSmoothOpen({ iSmooth: ("i-smooth-flexy-option-" + i), vForce: 'open' });
                    }
                    else {
                        FSmoothOpen({ iSmooth: ("i-smooth-flexy-option-" + i), vForce: 'close' });
                    }
                }
                
            }

            //Paxes
            for (var ptc in $scope.passengers) {
                if ($scope.passengers.hasOwnProperty(ptc)) {
                    for (var i = 0; i < $scope.passengers[ptc]; i++)
                        $scope.paxes.push({ key: ptc, name: $scope.paxname[ptc] });
                }
            }

            // getBundleWidget("mrfly-widget-fidelity-cards.js").done(function () {

            //     for (var i = 0; i < $scope.paxes.length; i++){

            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.cards = $scope.cards;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.countries = $window.countriesCode;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.sitePartnerId = $window.site.partnerId;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.siteType = $scope.siteType;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.remarkPassenger = $scope.remarkPassenger;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.position = i;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.mandatoryInformation = $scope.mandatoryInformation;

            //         var listYears = [];
            //         for (var k = new Date().getFullYear(); k <= (new Date().getFullYear() + 15) ; k++) {
            //             listYears.push(k);
            //         }

            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.listYears = listYears;

            //         //initial data
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.selectedCard = $scope.cards.first(function(val){return val.reference===$scope.paxinfo[i].fidelityCardType});
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.cardNumber = $scope.paxinfo[i].fidelityCardNumber;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.passportNumber = $scope.paxinfo[i].passportNumber;

            //         //if 10 we send 10 if 02 we send 2 
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$refs.expirationCalendar.selectedDay = $scope.paxinfo[i].passportExpireDate ? Number($scope.paxinfo[i].passportExpireDate.split('/')[0]) : null;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$refs.expirationCalendar.selectedMonth =  $scope.paxinfo[i].passportExpireDate ? Number($scope.paxinfo[i].passportExpireDate.split('/')[1]) : null;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$refs.expirationCalendar.selectedYear =$scope.paxinfo[i].passportExpireDate ? Number($scope.paxinfo[i].passportExpireDate.split('/')[2]) : null;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$refs.expirationCalendar.selectedDate = $scope.paxinfo[i].passportExpireDate;
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.selectedCountry = $window.countriesCode.first(function(val){return val.iso===$scope.paxinfo[i].passportCountry});
            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.remarksValue = $scope.paxinfo[i].commentaries;


            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$on('selectedCard', function (position, selectedCardNumber) {
            //             if(selectedCardNumber){
            //                 $scope.paxinfo[position].fidelityCardType = selectedCardNumber.reference;
            //             }
            //             else{
            //                 $scope.paxinfo[position].fidelityCardType = null;
            //             }

            //         });

            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$on('selectedCardNumber', function (position, cardNumber) {
            //             $scope.paxinfo[position].fidelityCardNumber = cardNumber;
            //         });

            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$on('selectedPassportNumber', function (position, passportNumber) {
            //             $scope.paxinfo[position].passportNumber = passportNumber;
            //         });

            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$on('selectedExpirationDate', function (position, dateSelected) {
            //             $scope.paxinfo[position].passportExpireDate = ('0' + dateSelected.day).slice(-2) + '/' + ('0' + dateSelected.month).slice(-2) + '/' + dateSelected.year;
            //         });

            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$on('selectedCountry', function (position, country) {
            //             if(country){
            //                 $scope.paxinfo[position].passportCountry = country.iso;
            //                 document.getElementsByName("i-country-"+position)[0].value =country.iso;
            //             }
            //             else{
            //                 $scope.paxinfo[position].passportCountry = null;
            //                 document.getElementsByName("i-country-"+position)[0].value='';
            //             }


            //         });

            //         mrflyWidgets['mrfly-widget-fidelity-cards-'+i].$refs.instance.$on('selectedRemarksValue', function (position, remarksValue) {
            //             $scope.paxinfo[position].commentaries = remarksValue;
            //         });
            //     }


            // });

            //input is a custom event declare in function.js: fireEvent(O_DATE_SELECT, "input");
            $(document).on('blur', '.paxinfo-lastname, .paxinfo-firstname', function () {
                var doubleIndex = -1;

                angular.forEach($scope.paxinfo, function (pax, key) {
                    if (pax.firstName != null && pax.lastName != null) {
                        var fName = pax.firstName.toLowerCase().trim();
                        var lName = pax.lastName.toLowerCase().trim();
                        var index = pax.index;

                        angular.forEach($scope.paxinfo, function (pax2, key) {
                            if (pax2.firstName != null && pax2.lastName != null) {
                                var fName2 = pax2.firstName.toLowerCase().trim();
                                var lName2 = pax2.lastName.toLowerCase().trim();
                                var index2 = pax2.index;

                                if (fName == fName2 && lName == lName2 && index != index2) {
                                    if (index2 > doubleIndex) {
                                        doubleIndex = index2;
                                    }
                                }
                            }
                        });
                    }
                });

                $scope.hasDoubles = doubleIndex > -1;

                //sends event to child directives
                $timeout(function () { $scope.$broadcast('updateNameAlert', doubleIndex) }, 100);


            });

            $scope.loadSeatMap = function (data, selectedSeatsKey) {

                getBundleWidget("mrfly-widget-seatmap.js").done(function () {
                    mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.airSeatMapRS = data;

                    var sessionSeats = window.sessionStorage.getItem(selectedSeatsKey);

                    if (sessionSeats != null && $scope.maybeShouldReapplySeats) {
                        var parsedSessionSeats = JSON.parse(sessionSeats);
                        mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.flightSeatSelection = parsedSessionSeats;
                        $scope.$emit('selectedSeats', parsedSessionSeats);
                    }
                    else {
                        mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.flightSeatSelection = {};
                        window.sessionStorage.setItem(selectedSeatsKey, JSON.stringify({}));
                        $scope.$emit('selectedSeats', {});
                        $scope.$apply();
                    }

                    mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.$on('updated', function (selectedSeats) {
                        window.sessionStorage.setItem(selectedSeatsKey, JSON.stringify(selectedSeats));
                        $scope.$emit('selectedSeats', selectedSeats);
                    });

                        mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.$on('popinOpened', function (selector) {
                            disableScroll($('body')[0]);
                            setTimeout(function () {
                                enableScroll($(selector)[0]);
                                if (typeof (window.onSeatMapUpdate) === "function") {
                                    window.onSeatMapUpdate();
                                }
                            }, 300);
                        });

                        mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.$on('popinClosed', function (selector) {
                            enableScroll($('body')[0]);
                            enableScroll($(selector)[0]);
                        });

                        mrflyWidgets["mrfly-widget-seatmap"].$refs.seatmap.$on('onSeatUpdate', function (selector) {
                            if (typeof (window.onSeatMapUpdate) === "function") {
                                window.onSeatMapUpdate();
                            }
                        });
                    })
                        .fail(function (jqxhr, settings, exception) {
                            console.log(exception);
                        });
                };

            $scope.dataPost = [];
            //Set Ready
            $scope.setReady = function () {
            $window.FLoading({ vAction: 'showNow' });
            if ($window.enableUnAccompaniedMinor) {
                $window.FLoading({ vAction: 'show' });
                var paxs = JSON.parse(JSON.stringify($scope.paxinfo));
                for (i = 0; i < paxs.length; i++) {
                    paxs[i].birthDate = moment(paxs[i].birthDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                    paxs[i].Type = paxs[i].paxType;
                }

                    var dataUnaccompanied = {
                        "purchaseId": $scope.$parent.bkgData.purchaseResponse.purchaseId,
                        "proposalIndex": $scope.$parent.bkgData.purchaseRequest.proposal,
                        "passengers": paxs,
                        "requestId": $scope.$parent.bkgData.purchaseRequest.requestId,
                        "transaction": 9,
                        "culture": "",
                        "market": "",
                    };
                $scope.apiClient.PURCHASE.unAccompaniedMinor(dataUnaccompanied).success(function (unAccompaniedRS) {
                    console.log("unAccompaniedRS");
                    console.log(unAccompaniedRS);
                    
                    if (unAccompaniedRS.blocking) {
                        $window.FLoading({ vAction: 'hide' });
                        console.log("unAccompaniedRS unAccompaniedRS.blocking true");
                        $scope.unAccompaniedMinorRule = unAccompaniedRS;

                        if (unAccompaniedRS.ruleType == 0) {
                            $scope.unAccompaniedMinorMessage = $scope.getT('unaccompaniedminor.rulemessage').replace('{age}', (parseInt(unAccompaniedRS.age) + 1));
                        } else {
                            $scope.unAccompaniedMinorMessage = $scope.getT('unaccompaniedminor.staticmessage');
                        }

                        $(document).on('click', '[data-remodal-action="close"]', function () {
                            $('[data-remodal-id="modal-warning-unaccompaniedminor"]').remodal({ hashTracking: false }).close()
                        });

                        $('[data-remodal-id="modal-warning-unaccompaniedminor"] > .alert > p')[0].innerHTML = $scope.unAccompaniedMinorMessage;
                        $('[data-remodal-id="modal-warning-unaccompaniedminor"]').addClass("remodal");
                        $('[data-remodal-id="modal-warning-unaccompaniedminor"]').remodal({ hashTracking: false }).open();


                        $([document.documentElement, document.body]).animate({
                            scrollTop: $("#i-accord-step-1").offset().top
                        }, 500);

                    } else {
                        console.log("unAccompaniedRS unAccompaniedRS.blocking false");
                        processStep1(unAccompaniedRS);
                    }
                }).error(function () {
                    processStep1(null);
                }).finally(function () {
                    $window.FLoading({ vAction: 'hide' });
                });
                } else {
                    processStep1(null);
                }
            };

            function tryToGetSeatMap() {
                if ($scope.enableSeatMap) {
                    //SeatMap
                    $scope.loadingSeatMap = true;
                    $scope.seatmapDataReady = false;

                    var seatMapRQ = {
                        purchaseId: $scope.$parent.bkgData.purchaseResponse.purchaseId,
                        proposalIndex: $scope.proposal.index,
                        passengers: $scope.paxinfo,
                        requestId: $scope.$parent.bkgData.purchaseRequest.requestId,
                        transaction: 8,
                        culture: "",
                        market: "",
                        product: {
                            itineraries: $scope.$parent.bkgData.purchaseResponse.itineraries
                        }
                    };

                    var setmapKey = 'setmap_' + seatMapRQ.purchaseId + "_proposal_index_" + $scope.proposal.index ;
                    var selectedSeatsKey = 'selectedSeats_' + seatMapRQ.purchaseId;

                    var loadSeatMapData = function (data) {
                        //both null and emty array can happen. An item whith choice != 1 doesn't have any seatmap
                        if (data.segmentsSeats != null && data.segmentsSeats.length > 0 && data.segmentsSeats.any(function (s) { return s.choice === 1 })) {
                            data.airSeatMapRQ = seatMapRQ;
                            data.segments = [];

                            angular.forEach(seatMapRQ.product.itineraries, function (itinerary, key) {
                                angular.forEach(itinerary.segments, function (segment, key) {
                                    data.segments.push(segment);
                                });
                            });

                            console.log(data);

                            window.sessionStorage.setItem(setmapKey, JSON.stringify(data));

                            $scope.hasSeatMap = true;
                            setTimeout(function () {
                                $scope.loadingSeatMap = false;
                                $scope.seatmapDataReady = true;
                                $scope.$apply();
                                $scope.loadSeatMap(data, selectedSeatsKey);
                            }, 200);
                        } else {
                            $scope.hasSeatMap = false;
                            $scope.loadingSeatMap = false;
                        }
                    }
                    if (window.sessionStorage.getItem(setmapKey) == null) {
                        //get seat map from api
                        $scope.apiClient.PURCHASE.seatMap(seatMapRQ).success(function (data) {
                            loadSeatMapData(data);
                        });
                    }
                    else {
                        var data = JSON.parse(window.sessionStorage.getItem(setmapKey));
                        loadSeatMapData(data);
                    }

                }

            }

            function processStep1(unAccompaniedRS) {
                $scope.dataPost = [];
                var missing = 0;
                if ($scope.paxinfo.length === 0)
                    missing = -1;

                if ($scope.hasDoubles)
                    missing = -1;

                for (var i = 0; i < $scope.paxinfo.length; i++) {
                    var item = $scope.paxinfo[i];
                    if (typeof item.title == 'undefined' || item.title == null || item.title == "") missing = 1;
                    if (typeof item.firstName == 'undefined' || item.firstName == null || item.firstName == "") missing = 2;
                    if (typeof item.lastName == 'undefined' || item.lastName == null || item.lastName == "") missing = 3;
                    if (typeof item.birthDate == 'undefined' || item.birthDate == null || item.birthDate == "") missing = 4;
                }

                if (typeof mrflyWidgets["mrfly-widget-flex"] !== 'undefined' && mrflyWidgets["mrfly-widget-flex"] !== null) {
                    //flex validation (check that user has checked eitehr an option or no-options)
                    var isValid = mrflyWidgets["mrfly-widget-flex"].$refs.instance.isValid();
                    if (isValid === false) {
                        missing = -1; //prevent step2 navigation
                    }
                }

                var handleValidation = function () {
                    if (missing === 0) {
                        if (typeof window.enableDupes !== 'undefined' && window.enableDupes) {
                            var dtoReservavation = {};
                            for (var i = 0; i < $scope.paxinfo.length; i++) {
                                dtoReservavation = {};
                                var item = $scope.paxinfo[i];
                                dtoReservavation.lastName = item.lastName.replace(/[^a-zA-Z ]/g, "");
                                dtoReservavation.firstName = item.firstName.replace(/[^a-zA-Z ]/g, "");
                                dtoReservavation.brithDate = moment(item.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                                dtoReservavation.title = item.title;
                                dtoReservavation.reference = ""
                                dtoReservavation.partnerId = $window.site.partnerId;
                                dtoReservavation.loginUrl = $window.site.loginUrl;
                                dtoReservavation.email = "";
                                dtoReservavation.bookingId = "";
                                dtoReservavation.agentId = "";
                                if (window.site.loginInfo !== null) {
                                    if (typeof window.site.loginInfo !== 'undefined') {
                                        if (typeof window.site.loginInfo.Agent != 'undefined') {
                                            if (typeof window.site.loginInfo.Agent.AgentId !== 'undefined') {
                                                dtoReservavation.agentId = window.site.loginInfo.Agent.AgentId;
                                            } else {
                                                dtoReservavation.agentId = "";
                                            }
                                        } else {
                                            dtoReservavation.agentId = "";
                                        }

                                    } else {
                                        dtoReservavation.agentId = "";
                                    }
                                } else {
                                    dtoReservavation.agentId = "";
                                }
                                //*** Aller simple 
                                if ($scope.purchaseresponse.itineraries.length == 1) {
                                    var itemItineraires = $scope.purchaseresponse.itineraries[0];
                                    dtoReservavation.goDate = itemItineraires.departureDateTime;
                                    dtoReservavation.goOrigine = itemItineraires.origin.cityIata;
                                    dtoReservavation.goOrigineCityName = itemItineraires.origin.cityName;
                                    dtoReservavation.goOrigineCountryName = itemItineraires.origin.countryName;
                                    dtoReservavation.goOriginename = itemItineraires.origin.airportName;
                                    dtoReservavation.goDestination = itemItineraires.destination.cityIata;
                                    dtoReservavation.goDestinationCityName = itemItineraires.destination.cityName;

                                    dtoReservavation.isAllerOnly = true;
                                    $scope.dataPost.push(dtoReservavation);
                                } else {
                                    if ($scope.purchaseresponse.itineraries.length > 1) {
                                        //***Multi destiniation
                                        if ($scope.request.multiDestination == true) {
                                            for (var j = 0; j < $scope.purchaseresponse.itineraries.length; j++) {
                                                var copyDtoReservation = Object.assign({}, dtoReservavation);
                                                var itemItineraires = $scope.purchaseresponse.itineraries[j];
                                                copyDtoReservation.goDate = itemItineraires.departureDateTime;
                                                copyDtoReservation.goOrigine = itemItineraires.origin.cityIata;
                                                copyDtoReservation.goOrigineCityName = itemItineraires.origin.cityName;
                                                copyDtoReservation.goOrigineCountryName = itemItineraires.origin.countryName;
                                                copyDtoReservation.goOriginename = itemItineraires.origin.airportName;
                                                copyDtoReservation.goDestination = itemItineraires.destination.cityIata;
                                                copyDtoReservation.isMultiDestination = true;
                                                $scope.dataPost.push(copyDtoReservation);
                                            }
                                        } else {
                                            console.log("$scope.purchaseresponse.itineraries[0]", $scope.purchaseresponse.itineraries);
                                            var itemItinerairesDepart = $scope.purchaseresponse.itineraries[0];
                                            console.log("itemItinerairesDeparTest", itemItinerairesDepart);
                                            //*** Aller/retour
                                            dtoReservavation.goDate = itemItinerairesDepart.departureDateTime;
                                            dtoReservavation.goOrigine = itemItinerairesDepart.origin.cityIata;
                                            dtoReservavation.goOrigineCityName = itemItinerairesDepart.origin.cityName;
                                            dtoReservavation.goOrigineCountryName = itemItinerairesDepart.origin.countryName;
                                            dtoReservavation.goOriginename = itemItinerairesDepart.origin.airportName;
                                            dtoReservavation.goDestination = itemItinerairesDepart.destination.cityIata;

                                            var itemItinerairesRetour = $scope.purchaseresponse.itineraries[1];
                                            dtoReservavation.backDate = itemItinerairesRetour.departureDateTime;
                                            dtoReservavation.backOrigine = itemItinerairesRetour.origin.cityIata;
                                            dtoReservavation.backOrigineCityName = itemItinerairesRetour.origin.cityName;
                                            dtoReservavation.backOrigineCountryName = itemItinerairesRetour.origin.countryName;
                                            dtoReservavation.backOriginename = itemItinerairesRetour.origin.airportName;
                                            console.log("itemItinerairesRetour", dtoReservavation);
                                            dtoReservavation.isAllerRetour = true;
                                            $scope.dataPost.push(dtoReservavation);
                                        }
                                    }
                                }
                            }
                            console.log("Voila ce qu'on envoie au dupe ");
                            console.log(JSON.stringify($scope.dataPost));
                            $window.Typescript.Dupes.Services.DupesService.getDupes($scope.dataPost).then(function (data) {
                                if (data.data.length > 0) {
                                    mrflyWidgets["mrfly-widget-reservation-vol"].$refs.instance.infosPassengers = data.data;
                                    mrflyWidgets["mrfly-widget-reservation-vol"].$refs.instance.isVisiblePopUp = true;
                                } else {
                                    validePopUp()
                                }
                            }, function (err) {
                                validePopUp();
                            });
                        } else {
                            validePopUp();
                        }
                    }
                    else if (missing === 2) {
                        $scope.blockCgosPurchase = true;
                        $scope.$apply();

                        var accordstep1 = document.getElementById('i-accord-step-1');
                        var fieldsValidated = accordstep1.getElementsByClassName('c-field-ok');
                        var fieldsToUncheck = [];

                        for (var i = 0; i < fieldsValidated.length; i++) {
                            var field = fieldsValidated[i];
                            fieldsToUncheck.push(field);
                        }

                        for (var i = 0; i < fieldsToUncheck.length; i++) {
                            var field = fieldsToUncheck[i];
                            field.classList.remove('c-field-ok');
                        }

                        $window.XLScrollTo({ iObj: 'warning-cgos' });
                        if ($('#i-loading-cover').length) {
                            $window.FLoading({ vAction: 'hide' });
                        }
                    }
                    else {
                        $window.XLFormValid({ iCont: 'i-accord-step-1', vScrollTo: true });

                        if ($scope.hasDoubles) {
                            XLFormValidStyle({ oObj: $(".paxinfo-lastname[double]")[0], vError: true, vErrorCode: 'pax-same-name' });
                        }
                        if ($('#i-loading-cover').length) {
                            $window.FLoading({ vAction: 'hide' });
                        }
                    }
                    //popup if message not blocking UnAccompaniedMinor Rule
                    if (unAccompaniedRS && unAccompaniedRS.unAccompaniedMinorMessage != null) {

                        $(document).on('click', '[data-remodal-action="close"]', function () {
                            $('[data-remodal-id="modal-warning-unaccompaniedminor"]').remodal({ hashTracking: false }).close()
                        });

                        $('[data-remodal-id="modal-warning-unaccompaniedminor"] > .alert > p')[0].innerHTML = $scope.getT('unaccompaniedminor.staticmessage');
                        $('[data-remodal-id="modal-warning-unaccompaniedminor"]').addClass("remodal");
                        $('[data-remodal-id="modal-warning-unaccompaniedminor"]').remodal({ hashTracking: false }).open();
                            
                    }
                }
                if (window.site.partnerId === "cgos" || window.site.partnerId === "cgos-agent-dp") {
                    // Not block anymore missing benificary for CGOS-PM-DP!
                    // || window.site.partnerId === "cgos-pm-dp") {
                    var passengersInbkg = $window.$scope.bkgData.bookingRequest.passengers;
                    $window.Typescript.Servair.Services.ServairService.CgosCheckPaxAyantDroit(passengersInbkg).then(
                        function (result) {
                            var info = result.data;
                            if (info == false) {
                                missing = 2;
                            }
                            handleValidation();
                        },
                        function (error) {
                            console.error("failed because " + error);
                            handleValidation();
                        }
                    );
                } else {
                    handleValidation();
                }
            }

            //End
            //scroll management for popin
            function preventDefault(e) {
                e = e || window.event;
                if (e.preventDefault)
                    e.preventDefault();
                e.returnValue = false;
            }
            function validePopUp() {
                if ($window.XLFormValid({ iCont: 'i-accord-step-1', vScrollTo: true })) {
                    //  if ($scope.siteType != 2) {
                    var paxHistory = tseUtil.Session.get('flightPaxHistory') || {};
                    paxHistory.request = $scope.request;
                    paxHistory.passengers = angular.copy($scope.paxinfo);

                    for (var i = 0; i < paxHistory.passengers.length; i++)
                        paxHistory.passengers[i].baggageByItinerary = null;
                    tseUtil.Session.set('flightPaxHistory', paxHistory);
                    //   }
                    $scope.$emit("setReadyStep", { step: 1 });

                    //*****************************************************************************************
                    //Merovingio Step 1
                    var bReq = $window.$scope.bkgData.bookingRequest;

                    //*****************************************************************************************
                    //Tracking
                    try {
                        //GTM
                        if (trackingService.isActivated()) {
                            trackingService.removeProduct('flexy');
                            if ($scope.flexyfare) {
                                trackingService.addProduct(gtmProduct.parseFlexy($scope.proposal));
                            }
                            trackingService.parseScope($scope);
                            trackingService.pushDataLayer('step', 2);
                        }
                        //omniture
                        if (omnitureService.isActivated()) {
                            console.info("=========================  send ctm TUnnel 2 -- 1/1 =================");
                            omnitureService.sendCdtm(omnitureService.PURCHASE_2_VOL, { request: $scope.request, purchaseResponse: $scope.purchaseresponse });
                            omnitureService.sendEvent(omnitureService.PURCHASE_2_VOL, omnitureService.FLIGHT_TUNNEL, null);
                        }
                    }
                    catch (ex) {
                        console.log(ex.message);
                        $window.trackingError = ex.message;
                    }

                }
                else {
                    if ($('#i-loading-cover').length) {
                        $window.FLoading({ vAction: 'hide' });
                    }
                }
            }

            function disableScroll(element) {
                if (element.addEventListener) // older FF
                    element.addEventListener('DOMMouseScroll', preventDefault, false);
                element.onwheel = preventDefault; // modern standard
                element.onmousewheel = preventDefault; // older browsers, IE
            }

            function enableScroll(element) {
                if (element.removeEventListener) // older FF
                    element.removeEventListener('DOMMouseScroll', preventDefault, false);
                element.onwheel = function (ev) { // modern standard
                    element.scrollTop += ev.deltaY;
                };
                element.onmousewheel = function (ev) { // older browsers, IE
                    if (ev.wheelDelta < 0) {
                        element.scrollTop += ev.y;
                    }
                    else {
                        element.scrollTop -= ev.y;
                    }
                };
            }
            getBundleWidget("mrfly-widget-reservation-vol.js").done(function () {
                mrflyWidgets["mrfly-widget-reservation-vol"].$refs.instance.langue = lng;
                mrflyWidgets["mrfly-widget-reservation-vol"].$refs.instance.$on('updated', function (selector) {
                    validePopUp();
                });

            }).fail(function (jqxhr, settings, exception) {
                console.log(exception);
            });

        };
        return directive;
    }]);