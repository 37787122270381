function freezeInfoHelper () { }

freezeInfoHelper.GenerateRequest = function (bookingRequest, purchaseRs, discountCode) { 
    var freezeInfoRq = {
        requestId: bookingRequest.requestId,
        purchaseId: purchaseRs.purchaseId,
        proposalIndex: purchaseRs.proposalsRequested.index,
        discountCode: discountCode,
        passengers: []
    };
    if (bookingRequest.insurance)
        freezeInfoRq.insuranceId = bookingRequest.insurance.id;

    bookingRequest.passengers.each(function (p) {
        var auxPaxPassportExpireDate = null;
        var auxPaxBirthDate = moment(p.birthDate, "DD-MM-YYYY");

        if (p.passportExpireDate != undefined)
            auxPaxPassportExpireDate = moment(p.passportExpireDate, "DD-MM-YYYY");

        var auxPasenger = {
            firstName: p.firstName,
            lastName: p.lastName,
            birthDate: auxPaxBirthDate.format("YYYY-MM-DD"),
            index: p.index,
            type: p.paxType,
            phoneCountryCode: p.phoneCountryCode,
            ptc: p.ptc,
            title: p.title === "1" ? 1 : p.title === "2" ? 2 : 0,
            passportNumber: p.passportNumber,
            passportCountry: p.passportCountry,
            additionalBaggages: [],
            fidelityCardNumber: p.fidelityCardNumber,
            fidelityCard: p.fidelityCardType
        }

        if (p.phoneCountryCode != undefined && p.phone != undefined && p.phoneCountryCode != '' && p.phone != '') {
            auxPasenger.phone = libphonenumber.parsePhoneNumberFromString("+{0}{1}".format(p.phoneCountryCode, p.phone)).format("INTERNATIONAL").replace(/\s/g, "");
        }

        if (auxPaxPassportExpireDate != undefined)
            auxPasenger.passportExpiry = auxPaxPassportExpireDate.format("YYYY-MM-DD");

        if (p.baggageByItinerary != null) {
            for (var bag in p.baggageByItinerary) {
                if (p.baggageByItinerary.hasOwnProperty(bag)) {
                    if (p.baggageByItinerary[bag].quantity > 0) {
                        auxPasenger.additionalBaggages.push({
                            itineraryKey: bag,
                            baggageKey: p.baggageByItinerary[bag].key,
                            baggageId: p.baggageByItinerary[bag].baggageId
                        });
                    }
                }
            }
        }
        freezeInfoRq.passengers.push(auxPasenger);
    });

    var selectedSeatsKey = 'selectedSeats_' + purchaseRs.purchaseId;
    var sessionSeats = window.sessionStorage.getItem(selectedSeatsKey);
    if (sessionSeats != null) {
        var selection = JSON.parse(sessionSeats);

        angular.forEach(selection, function (segment, key) {

            var segmentKey = key;

            angular.forEach(selection[key], function (select, key) {

                for (var i = 0; i < freezeInfoRq.passengers.length; i++) {
                    if (freezeInfoRq.passengers[i].index == select.passenger.index) {
                        if (freezeInfoRq.passengers[i].segmentSeats == null) {
                            freezeInfoRq.passengers[i].segmentSeats = [];
                        }
                        var segementSeat = {
                            segmentKey: segmentKey,
                            providerKey: select.seat.providerKey,
                            seatNumber: select.seat.number
                        };

                        freezeInfoRq.passengers[i].segmentSeats.push(segementSeat);
                    }
                }
            });
        });
    }

    return freezeInfoRq;

};

freezeInfoHelper.FillIsFreezeAvailable = function (bkgData) {
    if (bkgData.purchaseResponse.proposals[0].freezes == undefined) {
        bkgData.freezeIsAvailable = false;
    } else {
        bkgData.freezeIsAvailable = bkgData.purchaseResponse.proposals[0].freezes.any();
    }
};


freezeInfoHelper.FromApi = function (requestId, purchaseId, proposalIndex, bkgData) {
    var freezeInfoRq = {
        requestId: requestId,
        purchaseId: purchaseId,
        proposalIndex: proposalIndex,
        passengers: []
    };

    var mockpax = {
        birthDate: "1962-03-21",
        firstName: "",
        index: 0,
        lastName: "",
        ptc: "ADT",
        title: 1,
        type: "0"
    };

    freezeInfoRq.passengers.push(mockpax);

    $scope.apiClient.PURCHASE.freezeInfo(freezeInfoRq).success(function (data) {
        if (data.freezes.any()) {
            bkgData.freezeIsAvailable = true;
            return;
        } else {
            bkgData.freezeIsAvailable = false;
            return;
        }

        bkgData.freezeIsAvailable = false;
    });
};

