webClient.controller('B2B.accountController', ['$scope', '$window', '$timeout', '$i18next', '$http', 'tseApiClient', 'tseAirResultsHelper',
    function ($scope, $window, $timeout, $i18next, $http, tseApiClient, airHelper) {
        $scope.getText = function (key) { return $window.culture.texts[key]; };

        $scope.dataForTheBot = {};
        $scope.botFirstStart = true;
        $scope.insurancesBookedForBot = null;
        $scope.bookingDetailsLoaded = false;
        $scope.insuranceLoaded = false;
        $scope.isloadingco2 = null;
        $scope.enableCO2 = false;
        $scope.accomodationInfo = {};

        $scope.totalCo2Average = 0;
        $scope.bookingHasSeatmap = false;

        document.onclick = catchTheClickInTheBot;

        function catchTheClickInTheBot(event) {
            if (event) {
                if (event.type == "click") {

                    var overlay = document.getElementById('joe-chat-full-body-overlay');
                    if (overlay != null) {
                        overlay.style.display = 'none';
                    }
                    var element = event.target ? event.target : event.srcElement;

                    if (element.tagName === 'A' && element.href.includes('fbots')) {
                        event.preventDefault();
                        window.open(element.href);
                    }

                    if (element.tagName === 'IMG' && element.src.includes('fbots'))
                        window.open(element.src)
                }
            }
        }

        window.addEventListener('storage', message_receive);

        //RestApi client object
        var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        //apiClient.enableDebug();    //Only for debugging....
        window.airHelper = airHelper;

        //Set the current login information
        $scope.loginInfo = $window.site.loginInfo;
        $scope.clientId = $scope.loginInfo.Client.AccountNumber;
        $scope.paymentMode = $scope.loginInfo.Client.PaymentMode;
        $scope.loginId = $scope.loginInfo.LoginId;
        $scope.bookingRequest = {};
        //Put back default dates to avoid stressing the database
        $scope.bookingRequest.fromDate = moment().format('DD/MM/YYYY');
        $scope.bookingRequest.toDate = moment().format('DD/MM/YYYY');
        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
        $scope.pendingPayment = false;
        $scope.currentCabinClass = null;
        $scope.isCrossFaring = false;
        $scope.enableBrandedFares = $window.enableBrandedFares;
        $scope.enableContactForm = $window.enableContactForm;
        $scope.enableBlockAgencyRemarks = $window.enableBlockAgencyRemarks;
        $scope.productType = window.sessionStorage.getItem('selectedProductType') || 'flight';
        $scope.ignoreProductType = false;
        $scope.resaPrestation = "";

        $scope.dateType = 1;
        $scope.showDetail = false;

        $scope.paxType = {};
        $scope.paxType["ADT"] = "Adulte";
        $scope.paxType["CNN"] = "Enfant";
        $scope.paxType["INF"] = "Bébé";
        $scope.hotelOnly = false; //flag used to hide/show html elements

        // Drawer Vars
        $scope.drawerResaOpened = false;
        $scope.drawerDocumentsOpened = false;
        $scope.showEticket = false;
        $scope.showBill = false;
        $scope.showVoucher = false;
        $scope.showCB = false;

        // Travel diary
        $scope.travelDiaryStates = {
            show: false,
            isWaiting: false,
            isError: false
        }

        //****************Simple Payment Module ***********************************************
        $scope.paymentSimpleModule = function (paymentId) {
            $window.FLoading({ vAction: 'show' });
            $("#i-form").attr("action", "../airSimplePaymentModule/" + $scope.view.bookingId + "/" + paymentId + "/");
            $("#i-form").submit();
            $window.FLoading({ vAction: 'hide' });
        };

        //*************************Post Option Vale: Confirm **********************************
        $scope.confirmOption = function () {
            $scope.optionValue = { status: 0, bookingId: $scope.view.bookingId };
            $window.XLConfirm({
                vTitle: getT('airbooking.b2b.option.confirm'),
                vText: getT('airbooking.b2b.option.confirmtext'),
                vTextNo: getT('airbooking.b2b.option.no'),
                vTextYes: getT('airbooking.b2b.option.yesconfirm'),
                fCallBackYes: function () {
                    $window.FLoading({ vAction: 'show' });
                    $http.post('/api/api/payment/ProcessOptionWithoutPayment', {
                        requestId: $scope.view.bookingAdditionalData.RequestId.value,
                        bookingId: $scope.view.bookingId
                    }).success(function (response) {
                        if (response) {
                            XLConfirm({
                                vHeader: getT('airbooking.b2b.option.confirmed'),
                                vFlag: 'checked',
                                vTitle: getT('airbooking.b2b.option.emailwillbesent'),
                                vHideNo: true,
                                vHideYes: true,
                                vProgress: true
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 5000);
                        } else {
                            $window.XLConfirm({ vTitle: getT('airbooking.b2b.option.error'), vTextYes: 'OK', vHideNo: true });
                            setTimeout(function () {
                                window.location.reload();
                            }, 2000);
                        }
                    }).error(function () {
                        $window.FLoading({ vAction: 'hide' });
                        $window.XLConfirm({ vTitle: getT('airbooking.b2b.option.error'), vTextYes: 'OK', vHideNo: true });
                    });
                },
            });
        };

        //*************************Post Option Value: Cancel **********************************
        $scope.cancelOption = function () {
            $scope.optionValue = { status: 1, bookingId: $scope.view.bookingId };
            $window.XLConfirm({
                vTitle: getT('airbooking.b2b.option.confirmcancellation'),
                vTextNo: getT('airbooking.b2b.option.no'),
                vTextYes: getT('airbooking.b2b.option.yes'),
                fCallBackYes: function () {
                    $window.FLoading({ vAction: 'show' });
                    apiClient.postOptionValue($scope.optionValue).success(function (data) {

                        //Successful
                        if (data == 0) {
                            XLConfirm({
                                vTitle: getT('airbooking.b2b.option.cancellationconfirmed'),
                                vHideNo: true,
                                vHideYes: true,
                                vProgress: true
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 2000);
                        }

                        //Error
                        if (data == 1) {
                            $window.XLConfirm({ vTitle: getT('airbooking.b2b.option.error'), vTextYes: 'OK', vHideNo: true });
                            setTimeout(function () {
                                window.location.reload();
                            }, 2000);
                        }

                        //Option Already Confirmed
                        if (data == 2) {
                            XLConfirm({
                                vTitle: getT('airbooking.b2b.option.notapplied') + ' ' + getT('airbooking.b2b.option.alreadyconfirmed'),
                                vHideNo: true,
                                vHideYes: true,
                                vProgress: true
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 5000);
                        }

                        //Option Already Canceled
                        if (data == 3) {
                            XLConfirm({
                                vTitle: getT('airbooking.b2b.option.notapplied') + ' ' + getT('airbooking.b2b.option.alreadycancelled'),
                                vHideNo: true,
                                vHideYes: true,
                                vProgress: true
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 5000);
                        }
                        $window.FLoading({ vAction: 'hide' });
                    }).error(function () {
                        $window.FLoading({ vAction: 'hide' });
                        $window.XLConfirm({ vTitle: getT('airbooking.b2b.option.error'), vTextYes: 'OK', vHideNo: true });
                    });
                }
            });
        };
        //***************************EndPost Option Value************************************************************************************

        apiClient.B2B.getAgents().success(function (data) {
            var agentsToSearch = data.agents;
            $scope.agentsToSearchCount = data.agents.length;

            agentsToSearch.each(function (agent) {
                agent.firstName = agent.firstName.toLowerCase().capitalize();
                agent.lastName = agent.lastName.toLowerCase().capitalize();
                agent.fullName = (agent.firstName + ' ' + agent.lastName).trim();
                if (agent.fullName === "")
                    agent.fullName = "(No Name)";
            });
            agentsToSearch = agentsToSearch.orderBy(function (item) { return item.fullName; });
            agentsToSearch.unshift({
                fullName: $i18next.t('b2baccountreservation.allvendors') + " ({0})".format($scope.agentsToSearchCount),
                agentId: 'null'
            });
            $scope.agentsToSearch = agentsToSearch;
        });

        //Get City Name or Iata
        $scope.getCityNameOrIata = function (airportIata) {
            if ($scope.view.airports[airportIata].cityIata) {
                if ($scope.view.cities[$scope.view.airports[airportIata].cityIata])
                    return $scope.view.cities[$scope.view.airports[airportIata].cityIata].name;
            }
            else {
                if ($scope.view.airports[airportIata].name)
                    return $scope.view.airports[airportIata].name;
                else
                    return airportIata;
            }
        };


        //Get Terminal name
        $scope.getTerminalDisplayName = function (airport, terminal) {
            if ($scope.view.airports && $scope.view.airports[airport]) {
                $scope.listTerminalsAux = $scope.view.airports[airport].terminal;
                if ($scope.listTerminalsAux) {
                    $scope.listTerminalsAux = $scope.listTerminalsAux.groupByKey('code');
                    if ($scope.listTerminalsAux[terminal])
                        return terminalDisplayName = $scope.listTerminalsAux[terminal].name;
                }
            }
        };

        //********************************Booking List*********************************************************
        $scope.bookingListRequest = {};
        $scope.bookingList = {};

        $scope.bindBookingList = function (data) {
            $("#list").removeClass("c-form-disabled");

            $scope.bookingList = data;

            if (data.status === 0) {
                if ($scope.bookingList.bookings != null) {
                    $scope.bookingList.bookings.each(function (item) {
                        var byPaxType = item.flightPassengers.groupByKey("paxType");

                        item.paxAmount = {};
                        if (byPaxType[0]) {
                            if (window.isArray(byPaxType[0])) item.paxAmount["ADT"] = byPaxType[0].length;
                            else item.paxAmount["ADT"] = 1;
                        }
                        else
                            item.paxAmount["ADT"] = 0;

                        if (byPaxType[1]) {
                            if (window.isArray(byPaxType[1])) item.paxAmount["CNN"] = byPaxType[1].length;
                            else item.paxAmount["CNN"] = 1;
                        }
                        else
                            item.paxAmount["CNN"] = 0;
                        if (byPaxType[2]) {
                            if (window.isArray(byPaxType[2])) item.paxAmount["INF"] = byPaxType[2].length;
                            else item.paxAmount["INF"] = 1;
                        }
                        else item.paxAmount["INF"] = 0;
                    });
                }
            }

            $window.FLoading({ vAction: 'hide' });
        };

        $scope.getBookingList = function () {
            $scope.bookingList = null;
            $("#list").addClass("c-form-disabled");
            $window.FLoading({ vAction: 'show' });

            var bookingRQ = {
                enterprise: $scope.loginInfo.EnterpriseReference,
                accountNumber: $scope.loginInfo.Client.AccountNumber,
                agentId: $scope.bookingListRequest.agentId,
                bookingFromDate: $scope.bookingListRequest.FromBookingDate,
                bookingToDate: $scope.bookingListRequest.ToBookingDate,
                departureFromDate: $scope.bookingListRequest.FromDepartureDate,
                departureToDate: $scope.bookingListRequest.ToDepartureDate,
                paxName: $scope.bookingListRequest.paxName,
                bookingNumber: $scope.bookingListRequest.BookingSearch,
                details: true   //false to speed up search but lose "accomodations" value
            };

            console.log(bookingRQ);

            $scope.ignoreProductType = !(typeof $scope.bookingListRequest.paxName === 'undefined' || $scope.bookingListRequest.paxName === '');

            apiClient.AIR.getBookingSearch(bookingRQ).success(function (data) {
                data.bookings = data.bookings.filter(function (d) { return /^\d+$/i.test(d.bookingNumber); });
                $scope.bindBookingList(data);
            });

        }.getBuffered(600);

        $scope.getBookingList();

        $scope.$watch('$viewContentLoaded', function () {
            getBundleWidget("mrfly-widget-loader.js").done(function () { });
        });

        $scope.fillInsurances = function (bookingId, view) {

            //$scope.showDetail = false;
            $window.FLoading({ vAction: 'show' });
            $.ajax({
                url: '/api/api/Insurance/GetBookedInsurance',
                type: 'get',
                data: {
                    bookingId: bookingId,
                },
                success: function (data) {
                    $window.FLoading({ vAction: 'hide' });
                    if (data) {
                        console.info("fill $scope.view.insurances");
                        console.info(data["Name"]);
                        $scope.view.insurances = data;
                        $scope.insurancesBookedForBot = data.length > 0 ? data[0].Type : null;
                        if (data.length > 0 && data[0].ProtectionPlanStatus == 2) {
                            $scope.insuranceCanceled = true;
                        }
                        $scope.$apply();
                        $scope.insuranceLoaded = true;
                        //    $scope.dataForTheBot.insuranceBooked = data.length > 0 ? data[0].Type : null;
                    } else {
                        console.info("no insurances");
                        $scope.$apply();
                        $scope.insuranceLoaded = true;
                    }
                },
                error: function (data) {
                    $scope.$apply();
                    $window.FLoading({ vAction: 'hide' });
                    console.error("fillInsurances error");
                    console.error(data);
                    $scope.insuranceLoaded = true;
                }
            });
        }

        $scope.retrieveCo2Data = function () {
            var allSegments = [];

            $scope.totalCo2Average = 0;

            $scope.view.itineraries.forEach(function (it) {
                var segments = it.segments;
                for (var i = 0; i < segments.length; i++) {
                    var segmentId = (segments[i].operatingAirline == null ? segments[i].airline : segments[i].operatingAirline) + segments[i].departure.airport + segments[i].arrival.airport + segments[i].equipment;
                    allSegments.push(segmentId);
                }
                it.co2Data = {};
                it.co2Data.co2Average = 0;
            });

            var bodyRequest = {
                segments: allSegments
            }

            var getCO2ValueFromCabinClass = function (cabinClass, co2Data) {
                switch (cabinClass.toUpperCase()) {
                    case "Y":
                        return co2Data.co2Economy;
                    case "S":
                        return co2Data.co2PremiumEconomy;
                    case "C":
                        return co2Data.co2Business;
                    case "F":
                        return co2Data.co2First;
                    default:
                        return co2Data.co2Average;
                }
            };

            $scope.isloadingco2 = true;

            $window.Typescript.CO2.Services.CO2Service.getCO2(bodyRequest).then(function (axiosResponse) {
                try {
                    var responseJson = JSON.parse(axiosResponse.data);
                    $scope.resultCO2 = responseJson.body;
                } catch (e) {
                    console.warn("Exception : " + e);
                    $scope.isloadingco2 = false;
                    $scope.$apply();
                    return;
                }

                $scope.CO2Map = new Map();
                for (var i = 0; i < $scope.resultCO2.length; i++) {
                    $scope.CO2Map.set($scope.resultCO2[i].id, $scope.resultCO2[i]);
                }

                var atLeastOneItineraryHasCo2Data = false;
                $scope.view.itineraries.forEach(function (it) {
                    var segments = it.segments;
                    var hasAllSegments = true;
                    var localAverageCo2Consumption = 0;
                    for (var j = 0; j < segments.length; j++) {
                        var segmentId = (segments[j].operatingAirline == null ? segments[j].airline : segments[j].operatingAirline) + segments[j].departure.airport + segments[j].arrival.airport + segments[j].equipment;
                        var co2Data = $scope.CO2Map.get(segmentId);
                        if (co2Data === undefined || co2Data === null) {
                            hasAllSegments = false;
                            break;
                        }
                        var co2Consumption = getCO2ValueFromCabinClass(segments[j].cabinClass, co2Data);
                        localAverageCo2Consumption += co2Consumption;
                    }
                    if (hasAllSegments) {
                        it.co2Data.co2Average = localAverageCo2Consumption;
                        $scope.totalCo2Average += localAverageCo2Consumption;
                        atLeastOneItineraryHasCo2Data = true;
                    }
                });

                $scope.view.itineraries.forEach(function (it) {
                    it.co2Data.atLeastOneItineraryHasCo2Data = atLeastOneItineraryHasCo2Data;
                });

                $scope.isloadingco2 = false;
                $scope.$apply();
            }, function (err) {
                    console.error(err);
                    $scope.isloadingco2 = false;
                    $scope.$apply();
            });
        }

        $scope.fillDataForTheBot = function (data) {
            console.info("== Information about booking details ==");
            console.info(data);
            var booking = data.bookings[0];

            if (data.bookings.length == 2) {
                $scope.accomodationInfo = data.bookings[1].accommodations[0];
            }
            if ($scope.resaPrestation == "BASKET") {
                $scope.accomodationInfo = data.bookings[0].accommodations[0];
            }

            var userInfo;
            var siteType;

            if (window.site.type == 2) {
                siteType = "B2B";
                var loginInfo = window.site.loginInfo.Agent;
                userInfo = {
                    firstName: loginInfo.FirstName,
                    lastName: loginInfo.LastName,
                    email: loginInfo.Account,
                    mobilePhone: loginInfo.Phone
                };
            } else {
                siteType = "B2C";
                userInfo = {
                    firstName: booking.client.firstName,
                    lastName: booking.client.lastName,
                    email: booking.client.email,
                    mobilePhone: booking.client.mobilePhone
                };
            }

            var getStatusValueFromInt = function (statusInt) {
                switch (statusInt) {
                    case 0:
                        return "Creating";
                    case 1:
                        return "InProgress";
                    case 2:
                        return "Completed";
                    case 3:
                        return "Canceled";
                    case 4:
                        return "Closed";
                    case 5:
                        return "Archived";
                    case 6:
                        return "Pending";
                    case 7:
                        return "Cancelling";
                    default:
                        return "Unknown";
                }
            };

            var getSubStatusValueFromInt = function (subStatusInt) {
                switch (subStatusInt) {
                    case 0:
                        return "none";
                    case 1:
                        return "AccessProvider";
                    case 2:
                        return "FraudControl";
                    case 3:
                        return "Unpaid";
                    case 5:
                        return "ToIssueTicket";
                    case 6:
                        return "Modification";
                    case 7:
                        return "Litigation";
                    case 8:
                        return "Refund";
                    case 9:
                        return "Option";
                    case 10:
                        return "Recovery";
                    default:
                        return "Unknown";
                }
            };

            var possibleFullLC = booking.pnrs[0].productType === "LC" ||
                booking.pnrs[0].productType === "RC";
            var companyType = possibleFullLC ? "FULL LC" : "FULL REG";

            if (possibleFullLC) {
                for (var i = 0; i < booking.pnrs.length; i++) {
                    if (booking.pnrs[i].productType != "LC" && booking.pnrs[i].productType != "RC") {
                        companyType = "MIX";
                        break;
                    }
                }
            } else {
                for (var i = 0; i < booking.pnrs.length; i++) {
                    if (booking.pnrs[i].productType == "LC" || booking.pnrs[i].productType == "RC") {
                        companyType = "MIX";
                        break;
                    }
                }
            }

            var ticketType = "oneWay";
            var itinerariesArray = [];
            var nbItineraries = 0;
            for (var i = 0; i < booking.pnrs.length; i++) {
                for (var j = 0; j < booking.pnrs[i].itineraries.length; j++) {
                    itinerariesArray.push(booking.pnrs[i].itineraries[j]);
                }
            }
            nbItineraries = itinerariesArray.length;
            if (nbItineraries > 1) {
                ticketType = "multiDestination";
            }
            if (nbItineraries == 2) {
                var itinerary1 = itinerariesArray[0];
                var itinerary2 = itinerariesArray[1];
                if (itinerary1.origin == itinerary2.destination &&
                    itinerary1.destination == itinerary2.origin) {
                    ticketType = "roundTrip";
                }
            }

            itinerariesArray.sort(function (it1, it2) { return new Date(it1.date) - new Date(it2.date) });
            var brand = window.location.hostname.includes("h24travel") ? "H24travel" : "MISTERFLY";

            paxLeader = null;
            nbPax = 0;

            for (var i = 0; i < booking.pnrs.length; i++) {
                if (booking.pnrs[i].passengers.length > 0) {
                    paxLeader = booking.pnrs[i].passengers[0];
                    nbPax = booking.pnrs[i].passengers.length;
                }
            }
            
            var companyName = $scope.loginInfo.EnterpriseReference + "_" + booking.agency.accountNumber + "_" + booking.agency.tradeName;

            formatDate = function (date) {
                return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
                    + "/" + (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1))
                    + "/" + date.getFullYear();
            }

            var reservationDate = formatDate(new Date(booking.timestamp));
            var departureDateFirstFlight = formatDate(new Date(itinerariesArray[0].date));
            var departureDateLastFlight = formatDate(new Date(itinerariesArray[itinerariesArray.length - 1].date));

            $scope.dataForTheBot =
            {
                bookingNumber: $scope.view.bookingNumber,
                brand: brand,
                status: getStatusValueFromInt(booking.status),
                subStatus: getSubStatusValueFromInt(booking.subStatus),
                product: $scope.view.bookingNumber.slice(-1) == 'V' ? 'FLIGHT' : $scope.productType.toUpperCase(),
                market: window.market.toUpperCase(),
                siteType: siteType,
                reservationDate: reservationDate,
                local: window.site.culture == 'be' ? 'nl' : window.site.culture,
                partnerCode: booking.agency.accountNumber,
                partnerName: booking.agency.companyName,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                mobilePhone: userInfo.mobilePhone,
                companyType: companyType,
                companyName: companyName,
                ticketType: ticketType,
                departureDateFirstFlight: departureDateFirstFlight,
                departureDateLastFlight: departureDateLastFlight,
                nbItineraries: nbItineraries,
                optionBooked: booking.flexies.length > 0 ? booking.flexies[0].name : "",
                insuranceBooked: null,
                paxLeaderfirstName: paxLeader != null ? paxLeader.firstName : "",
                paxLeaderLastName: paxLeader != null ? paxLeader.lastName : "",
                paxLeaderPhone: paxLeader != null ? paxLeader.phone : "",
                companyNameWithTradeName: $scope.loginInfo.EnterpriseReference + "_" + booking.agency.accountNumber + "_" + booking.agency.tradeName,
                nbPax: nbPax
            };

            console.info("--- Data for the bot ***");
            console.info($scope.dataForTheBot);

            $scope.bookingDetailsLoaded = true;
        }

        function message_receive(event) {
            if (event.key != 'resetbot')
                return;
            if (event.newValue != null && window.location.hash != '') {
                console.info("resetting instance of chatbot....");
                var newURL = window.location.href.replace('#restartBot', '');
                window.location.href = newURL;
                window.location.reload();
            }
        }

        $scope.broadcast_bot_reset = function () {
            console.info("Broadcasting resetbot to everyone...");
            localStorage.setItem('resetbot', JSON.stringify('yes'));
            localStorage.removeItem('resetbot');
        }

        function loadChatBotInMemory(dataClient, dataBotHash, btnId) {

            localStorage.setItem('clientid', dataClient);
            localStorage.setItem('bothash', dataBotHash);
            localStorage.setItem('btnid', btnId);

            if (typeof Freshbots === 'undefined') {
                console.log("no frashbots loaded");
                var n = document.getElementsByTagName('script')[0],
                    s = document.createElement('script');
                s.id = "spd-busns-spt";
                s.async = "async";
                s.src = 'https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js';
                n.parentNode.insertBefore(s, n);
                s.onreadystatechange = s.onload = function (event) {
                    window.location.hash = window.location.hash.replace('#restartBot', '') + '#restartBot';
                    Freshbots.resetCustomer();
                };
            } else {
                window.location.hash = window.location.hash.replace('#restartBot', '') + '#restartBot';
                Freshbots.resetCustomer();
            }
        }

        function getChatBotDataRegular() {
            console.info("-----------------  getChatBotDataRegular  --------------------------");
            var dataBot =  {
                "cstmr::lng": $scope.dataForTheBot.local,
                "cstmr::xtrInfrmtn:Resa_Contact_LastName": $scope.dataForTheBot.lastName,
                "Resa_Paxleader_FirstName": $scope.dataForTheBot.paxLeaderfirstName,
                "Resa_Network": "",
                "Resa_AccountNumber": $scope.dataForTheBot.partnerCode,
                "Resa_Booking_Date": $scope.dataForTheBot.reservationDate,
                "Resa_LastItin_Date": $scope.dataForTheBot.departureDateLastFlight,
                "Resa_Brand": $scope.dataForTheBot.brand,
                "cstmr::xtrInfrmtn:Resa_Contact_Email": $scope.dataForTheBot.email,
                "Resa_Market": $scope.dataForTheBot.market,
                "Resa_Airline_Type": $scope.dataForTheBot.companyType,
                "Resa_Travel_Doc_Email": "",
                "Resa_EnterpriseID": $scope.loginInfo.EnterpriseReference,
                "Resa_Insurance": $scope.insuranceCanceled ? null : $scope.insurancesBookedForBot,
                "Resa_SousStatutDossier": $scope.dataForTheBot.subStatus,
                "Resa_Channel": $scope.dataForTheBot.siteType,
                "Resa_FirstItin_Date": $scope.dataForTheBot.departureDateFirstFlight,
                "Resa_Language": $scope.dataForTheBot.local,
                "Resa_nb_itineraries": $scope.dataForTheBot.nbItineraries,
                "Resa_StatutDossier": $scope.dataForTheBot.status,
                "Resa_Prestation": $scope.dataForTheBot.product,
                "Resa_NumeroDossier": $scope.dataForTheBot.bookingNumber,
                "Resa_PaxLeader_LastName": $scope.dataForTheBot.paxLeaderLastName,
                "Resa_nb_pax": $scope.dataForTheBot.nbPax,
                "cstmr::xtrInfrmtn:Resa_Contact_Phone": $scope.dataForTheBot.mobilePhone,
                "Resa_Options": $scope.dataForTheBot.optionBooked,
                "Resa_CompanyName": $scope.dataForTheBot.companyName,
                "cstmr::xtrInfrmtn:Resa_Contact_FirstName": $scope.dataForTheBot.firstName
            };
            console.info("--->>> data Bot");
            console.info(dataBot);

            return dataBot;
        }

        function getChatBotDataBasket() {
            console.info("-----------------  getChatBotDataBasket  --------------------------");
            var date1 = new Date($scope.accomodationInfo.to);
            var date2 = new Date($scope.accomodationInfo.from);
            var diffTime = Math.abs(date2 - date1);
            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var dataBotPanier = {
                "cstmr::lng": $scope.dataForTheBot.local,
                "cstmr::xtrInfrmtn:Resa_Contact_LastName": $scope.dataForTheBot.lastName,
                "Resa_Paxleader_FirstName": $scope.dataForTheBot.paxLeaderfirstName,
                "Resa_Network": "",
                "Resa_AccountNumber": $scope.dataForTheBot.partnerCode,
                "Resa_Booking_Date": $scope.dataForTheBot.reservationDate,
                "Resa_LastItin_Date": $scope.dataForTheBot.departureDateLastFlight,
                "Resa_Brand": $scope.dataForTheBot.brand,
                "cstmr::xtrInfrmtn:Resa_Contact_Email": $scope.dataForTheBot.email,
                "Resa_Market": $scope.dataForTheBot.market,
                "Resa_Airline_Type": $scope.dataForTheBot.companyType,
                "Resa_Travel_Doc_Email": "",
                "Resa_EnterpriseID": $scope.loginInfo.EnterpriseReference,
                "Resa_Insurance": $scope.insuranceCanceled ? null : $scope.insurancesBookedForBot,
                "Resa_SousStatutDossier": $scope.dataForTheBot.subStatus,
                "Resa_Channel": $scope.dataForTheBot.siteType,
                "Resa_FirstItin_Date": $scope.dataForTheBot.departureDateFirstFlight,
                "Resa_Language": $scope.dataForTheBot.local,
                "cstmr::phn": "",
                "Resa_nb_itineraries": $scope.dataForTheBot.nbItineraries,
                "Resa_StatutDossier": $scope.dataForTheBot.status,
                "Resa_Prestation": $scope.resaPrestation,
                "cstmr::eml": "",
                "Resa_NumeroDossier": $scope.dataForTheBot.bookingNumber,
                "Resa_PaxLeader_LastName": $scope.dataForTheBot.paxLeaderLastName,
                "cstmr::nm": "",
                "Resa_nb_pax": $scope.dataForTheBot.nbPax,
                "cstmr::xtrInfrmtn:Resa_Contact_Phone": $scope.dataForTheBot.mobilePhone,
                "Resa_Options": $scope.dataForTheBot.optionBooked,
                "Resa_CompanyName": $scope.dataForTheBot.companyName,
                "cstmr::xtrInfrmtn:Resa_Contact_FirstName": $scope.dataForTheBot.firstName,
                "Resa_supplier_code": $scope.accomodationInfo.supplierCode,
                "Resa_supplier_ref": $scope.accomodationInfo.customerBookingRef,
                "Resa_instance": $scope.dataForTheBot.brand,
                "Resa_number_days": diffDays,
                "Resa_Room_type": $scope.accomodationInfo.rooms[0].roomTypeName,
                "Resa_number_rooms": $scope.accomodationInfo.rooms.length,
                "Resa_paid_price": $scope.accomodationInfo.totalPriceRacknet,
                "Resa_destination": $scope.accomodationInfo.cityName,
                "Resa_flight_info": "",
                "Resa_accomname": $scope.accomodationInfo.accomName,
                "Resa_accom_idnum": $scope.accomodationInfo.accomCode
            };
            console.info("--->>> data Bot Panier");
            console.info(dataBotPanier);
            return dataBotPanier;
        }

        function startChatBotFromMemory(dataClient, dataBotHash, btnId) {
            (function (d, c) {
                if (!d.getElementById("spd-busns-spt")) {
                    var n = d.getElementsByTagName('script')[0],
                        s = d.createElement('script');
                    var loaded = false;
                    s.id = "spd-busns-spt";
                    s.async = "async";
                    s.setAttribute("data-self-init", "false");
                    s.setAttribute("data-init-type", "opt");
                    s.src = 'https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js';
                    s.setAttribute("data-client", dataClient);
                    s.setAttribute("data-bot-hash", dataBotHash);
                    //Bot other
                    if (dataBotHash == "af2625a992e5e8bcb06f95b16fd47c6b286d06e8") {
                        console.info("data-bot-version 86");
                        //s.setAttribute("data-bot-version", "86");
                    }
                    s.setAttribute("data-env", "prod");
                    s.setAttribute("data-region", "euc");
                    n.parentNode.insertBefore(s, n);
                    if (c) {
                        s.onreadystatechange = s.onload = function (event) {
                            if (!loaded) {
                                c();
                            }
                            loaded = true;
                            $scope.isBotLoaded = true;
                            toggleFreshbotLazyLoad(true, btnId);
                        };
                    }
                }
            })
                (document, function () {
                    Freshbots.initiateWidget(
                        {
                            autoInitChat: false,
                            getClientParams: function () {
                                var isBasket = localStorage.getItem('isbasket', isBasket);
                                return isBasket === "true" ? getChatBotDataBasket() : getChatBotDataRegular();
                            }
                        },
                        function (successResponse) {
                            console.info("succes on chatbot initialisation");
                            Freshbots.showWidget(true);
                            toggleFreshbotLazyLoad(false, btnId);
                            startInterceptor();
                        },
                        function (errorResponse) {
                            console.info("error on chatbot initialisation");
                            console.error(errorResponse);
                            toggleFreshbotLazyLoad(false, btnId);
                        }
                    );
                });
        }

        $scope.openRequestModal = function (request) {
            var selector = '';
            switch (request) {
                case 'help':
                    selector = '[component-name="mrfly-widget-special-request-assistance"]';
                    break;
                case 'animal':
                    selector = '[component-name="mrfly-widget-special-request-pet"]';
                    break;
                default:
                    selector = '[component-name="mrfly-widget-special-request-luggage"]';
            }
            if (selector) {
                $scope.closeDrawerResa();
                jQuery('html, body').animate({
                    scrollTop: jQuery(selector).offset().top
                }, 500);
                setTimeout(function () {
                    document.querySelector(selector.concat(' .c-btn')).click();
                }, 525);
            }
        }

        $scope.startChatBot = function (botIdentifier, btnId) {
            var dataClient;
            var dataBotHash;
            var isBasket = false;
            console.info("starting chatbot : " + botIdentifier);
            switch (botIdentifier) {
                case 'cancel':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "b33c370b6be445453e4263849430609267712c6a";
                    break;
                case 'other':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "af2625a992e5e8bcb06f95b16fd47c6b286d06e8";
                    break;
                case 'modifier':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "5e6f16356b5c011b2a0224ad6db629cd4afdc7ef";
                    break;
                case 'luggage':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "a54acfda4608cf6dbddd477539c05e14e325f593";
                    break;
                case 'basket':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "927131523369181cdfc09957dc0727e99eb1eeca";
                    break;
                case 'modifierBasket':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "d79816878eef3c0b01446b6f309aa687d44d2c4d";
                    isBasket = true;
                    break;
                case 'cancelBasket':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "33d80919f4be4fbfa5f1d547ea976279d2405ae8";
                    isBasket = true;
                    break;
                case 'otherBasket':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "7596561137184f96e1fd15399455311d4b9dff4d";
                    isBasket = true;
                    break;
                default:
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "af2625a992e5e8bcb06f95b16fd47c6b286d06e8 ";
            }
            localStorage.setItem('isbasket', isBasket);
            $scope.broadcast_bot_reset();
            loadChatBotInMemory(dataClient, dataBotHash, btnId);
        };

        function toggleFreshbotLazyLoad(init, btnId) {
            var btnBot = document.getElementById(btnId);
            if (btnBot) {
                if (init) {
                    btnBot.classList.add('__loading');
                } else {
                    btnBot.classList.remove('__loading');
                }
            }
        }

        function startInterceptor() {
            var minimize = document.querySelector('.pull-right.joe-icon');
            if (minimize) {
                minimize.addEventListener('click', function () {
                    setTimeout(function() { showInterceptor() }, 150)
                }, false);
            }
        }

        function showInterceptor() {
            var show = document.getElementById('mobile-chat-container');
            if (show) {
                show.addEventListener('click', function (event) {
                    event.stopPropagation();
                    Freshbots.showWidget(true)
                }, false);
            }
        }

        $scope.getBookingDetails = function (bookingId, hotelOnly) {
      
            $scope.hotelOnly = hotelOnly;

            $window.FLoading({ vAction: 'show' });
            apiClient.AIR.getBookingDetails([bookingId]).success(function (data) {

                if (data && data.status == 0) {
                    setTimeout(function () {
                        $window.scrollTo(0, 0);
                    }, 100);
                    $scope.showDetail = true;

                    $scope.bkgData = data;
                    if (data.equipments[0].type == 'TRN') {
                        $scope.productIsTrain = true;
                        $scope.bkgData.purchaseResponse = {
                            productIsTrain: true,
                        }
                    } else {
                        $scope.productIsTrain = false;
                        $scope.bkgData.purchaseResponse = {
                            productIsTrain: false,
                        }
                    }
                    console.log("-- getBookingDetails --");
                    console.log(data);
                    $scope.view = airHelper.getViewForBookingDetailsEntityVersion2(data);
                    $scope.flexyInfo = data.bookings[0].flexies[0];
                    $scope.fillInsurances(bookingId, $scope.view);
                    //sort paxes if first one is not an adult
                    if ($scope.view.paxes[0].paxType !== 0) {
                        $scope.view.paxes.reverse();
                    }

                    var booking = hotelOnly ? data.bookings[1] : data.bookings[0];

                    getBundleWidget("mrfly-widget-pricing-conditions.js").done(function () {
                        setTimeout(function () {

                            mrflyWidgets['mrfly-widget-pricing-conditions'].bookingNumber = booking.bookingNumber;
                            mrflyWidgets['mrfly-widget-pricing-conditions'].flexyInfo = booking.flexies[0];
                            mrflyWidgets['mrfly-widget-pricing-conditions'].requestId = bookingId;
                        }, 3000);
                    }); 

                    $scope.view.payments = booking.payments;

                    $scope.view.invoices = [];
                    apiClient.AIR.getInvoicesList(booking.bookingId).success(function (data) {
                        $scope.view.invoices = data.grep(function (i) { return i.number != null; });
                    });
                    apiClient.AIR.getETicketsList(booking.bookingId).success(function (data) {
                        $scope.view.etickets = data;
                    });

                    $window.Typescript.ETickets.Services.ETicketsService.getEticketsTrain(booking.bookingId, $scope.view.bookingNumber).then(function (axiosResponse) {
                        $scope.view.eticketstrain = axiosResponse.data;
                        $scope.$apply();
                    }, function (err) {

                    });
                    $scope.$broadcast("onBookingSummaryUpdate");
                    $scope.pendingPayment = $scope.view.payments && $scope.view.payments.any(function (payment) { return (payment.status == 0); });
                    $scope.isCrossFaring = $scope.view._innerData.bookings[0] && $scope.view._innerData.bookings[0].pnrs.length > 1;
                    $scope.isProductTO = $scope.view.itineraries[0].productType == 1;

                    if ($scope.view.status == 4 && $scope.view.option && $scope.view.option.status == 0) {
                        $scope.lastTicketing = moment(($scope.view.option.lastTicketing), 'YYYY/MM/DD').toDate();
                    }

                    

                    //Insurances
                    if ($scope.view.insurances) {
                        $scope.view.insurances.each(function (insurance) {
                            if (insurance.companyReference == 2 && insurance.insuranceReference == 2)
                                insurance.displayName = $i18next.t('airbooking.insurance.multipleriskspack');
                            else
                                insurance.displayName = insurance.insuranceName;
                        });
                    }


                    //accommodation
                    if (booking.accommodations && booking.accommodations.any()) {
                        $scope.view.showaccomodationinfo = true;

                        $scope.view.bookingRef = booking.accommodations[0].bookingRef;
                        $scope.view.voucherUri = booking.accommodations[0].voucherUri;

                        //replace flight info by hotel info
                        if (hotelOnly) {

                            $scope.view.status = booking.status;
                            $scope.view.bookingNumber = booking.bookingNumber;
                            $scope.view.fares.items = booking.fares;
                            $scope.view.accommodation = booking.accommodations[0];

                            var hotelMargin = 0;

                            $scope.view.fares.items.each(function (fare) {
                                if (fare.type === 70 && fare.subtype === 901) {
                                    fare.name = $i18next.t('airbooking.hotelcommission');
                                    hotelMargin += parseFloat(fare.amount);
                                }
                                if (fare.type === 11 && fare.subtype === 100) {
                                    fare.name = $i18next.t('mrfly-widget-accommodation-price-detail.hotelamount');
                                }
                            });

                            var total = parseFloat(booking.accommodations[0].totalPriceRacknet) + hotelMargin;
                            var int = Math.floor(total);
                            var fixed = total.toFixed(2).toString();
                            var dec = fixed.substring(fixed.length - 2, fixed.length);
                            $scope.view.fares.total.int = int;
                            $scope.view.fares.total.dec = dec;
                        }

                        //nights number
                        var nights = moment(booking.accommodations[0].to).diff(moment(booking.accommodations[0].from), "days");
                        booking.accommodations[0].nightNumber = nights;

                        getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {

                            mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                            mrflyWidgets['mrfly-widget-accommodation-info'].resources = i18next.store.data.dev.translation;
                            mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = booking.accommodations[0];
                            mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;
                            mrflyWidgets['mrfly-widget-accommodation-info'].siteIsB2b = window.site.isLogged && window.site.type == 2;

                            var margin = booking.fares.filter(function (f) { return f.type === 70 && f.subtype === 901; });
                            if (margin.length > 0) {
                                mrflyWidgets['mrfly-widget-accommodation-info'].agencyMarginHotel = margin[0].amount;
                            }
                        });
                    }

                        getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {
                            
                            var extraDetails = [];
                            var totalAmount = 0;
                            for (var fare of booking.fares) { totalAmount += fare.amount * fare.quantity; }
                            mrflyWidgets['mrfly-widget-basket-total-price'].price = totalAmount;
                            /*
                            var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                            var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);
                            Array.prototype.push.apply(extraDetails, bags);
                            Array.prototype.push.apply(extraDetails, insurances);

                            //Seatmap
                            var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                            if (seatmapObj != null) {
                                var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                                Array.prototype.push.apply(extraDetails, seatmap);
                            }
                            */
                            var basketDetails = basketHelper.getPriceDetailsDetailedFromBooking(
                                $window.site.type,
                                $scope.bkgData.bookings[0],
                                { value: 45, margin: 0 },
                                extraDetails,
                                $scope.bkgData.equipments[0].type == 'TRN' ? "RAIL" : "FLIGHT"
                            );
                            
                            mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                        })
                        .fail(function (jqxhr, settings, exception) {
                            console.log(exception);
                        });
                    


                    //on n'autorise l'ajout que pour les booking confirmé et inprogress et qui ont leur date de retour qui n'est pas passé (ou aller si aller simple) 
                    $scope.allowAddSpecialRequest = false;

                    var checkLastFlightDate = function () {
                        if ($scope.view.request.oneWay) {
                            return  moment($scope.view.request.goDate.date) > Date.now();
                        }
                        if ($scope.view.request.roundTrip || $scope.view.request.openJaw) {
                            return moment($scope.view.request.goDate2.date) > Date.now();
                        }
                        if ($scope.view.request.multi) {
                            var thereStillFlightToCome = false;
                            $scope.view.request.itineraries.forEach(function (itinerary) {
                                if (moment(itinerary.goDate.date) > Date.now()) {
                                    thereStillFlightToCome = true;
                                }
                            });
                            return thereStillFlightToCome;
                        }
                    }

                    if ($scope.view.status == 2) {
                        $scope.allowAddSpecialRequest = checkLastFlightDate();
                    } else if ($scope.view.status == 1) {
                        var acceptedSubstatus = [0, 5, 6];
                        if (acceptedSubstatus.indexOf($scope.view.subStatus) != -1) {
                            $scope.allowAddSpecialRequest = checkLastFlightDate();
                        } 
                    }

                    if ($scope.allowAddSpecialRequest) {

                        //wait until div visible to load js 
                        var loadSpecialRequestOnReady = setInterval(function () {
                            if ($('.special-request-component').length > 0) {
                                getBundleWidget("mrfly-widget-special-request.js").done(function () {

                                }).fail(function (jqxhr, settings, exception) {
                                    console.log(exception);
                                });
                                clearInterval(loadSpecialRequestOnReady);
                            }
                        }, 200); // check every 200ms

                    }

                    if (!hotelOnly) {

                        getBundleWidget("mrfly-widget-request-recap.js").done(function () {
                            console.log("Loading done");
                            //set params do mapping

                            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

                            airSearchRequest.adultNumber = $scope.view.request.adults;
                            airSearchRequest.childNumber = $scope.view.request.childs;
                            airSearchRequest.infantNumber = $scope.view.request.infants;
                            airSearchRequest.isOneWay = $scope.view.request.oneWay;
                            airSearchRequest.isRoundTrip = $scope.view.request.roundTrip;
                            airSearchRequest.isOpenJaw = $scope.view.request.openJaw;
                            airSearchRequest.isMultiDestination = $scope.view.request.multi;

                            //Map itineraries
                            airSearchRequest.itineraries = [];
                            $scope.view.itineraries.forEach(function (itinerary) {
                                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                                ins.date = itinerary.departureDate;

                                ins.departureAirportIata = itinerary.departure;
                                ins.departureCityIata = itinerary.origin;
                                ins.departureName = $scope.getCityNameOrIata(itinerary.departure);

                                ins.arrivalAirportIata = itinerary.arrival;
                                ins.arrivalCityIata = itinerary.destination;
                                ins.arrivalName = $scope.getCityNameOrIata(itinerary.arrival);

                                airSearchRequest.itineraries.push(ins);
                            });
                            airSearchRequest.productType = $scope.bkgData.purchaseResponse.productIsTrain ? "RAIL" : "FLIGHT";
                            mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
                            mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
                            mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
                            mrflyWidgets['mrfly-widget-request-recap'].resources = i18next.store.data.dev.translation;
                        });
                    }

                    //Start here: branded fares
                    $scope.hasBrandedFare = $scope.view &&
                        $scope.view._innerData.bookings[0] &&
                        $scope.view._innerData.bookings[0].pnrs &&
                        $scope.view._innerData.bookings[0].pnrs.any(function (item) {
                            if (item.brand && item.brand.brandServices && item.brand.brandServices.length > 0)
                                return true;
                        });

                    if ($scope.hasBrandedFare) {
                        $scope.brand = $scope.view._innerData.bookings[0].pnrs[0].brand;
                        if ($scope.brand) {
                            $scope.brand.displayName = $scope.brand.displayName.capitalize();
                            $scope.brand.includedServices = [];
                            $scope.brand.brandServices.each(function (bService) {
                                if (bService.pricing == 0) {
                                    if (bService.description != null && bService.description != undefined)
                                        bService.descriptionHtml = bService.description.replaceAll("\n", "<br/>");

                                    bService.cssClass =
                                        (bService.rficGroup + (bService.rficSubGroup ? "-" + bService.rficSubGroup : ""))
                                            .toLowerCase();
                                    $scope.brand.includedServices.push(bService);
                                }
                            });
                        }


                        $scope.hasIncludedBaggage = $scope.view.itineraries[0] &&
                            $scope.view.itineraries[0].baggages[0]
                            && $scope.view.itineraries[0].baggages[0].quantity > 0;
                    }
                    //End here

                    $window.FLoading({ vAction: 'hide' });
                    $("#list").removeClass("c-form-disabled");

                    // fix the product type for resa hotel and panier
                    if (data.bookings.length == 1 && data.bookings[0].accommodations.length > 0) {
                        console.log("THIS IS BASKET");
                        $scope.productType = "BASKET";
                        $scope.resaPrestation = "BASKET";
                    }

                    if (data.bookings.length == 2) {
                        console.log("THIS IS PANIER B2B");
                        $scope.productType = "BASKET";
                        $scope.resaPrestation = "PANIER_B2B";
                    }

                    $scope.fillDataForTheBot(data);

                    if (window.enableCO2 && !$scope.view.request.multi) {
                        $scope.enableCO2 = true;
                        $scope.retrieveCo2Data();
                    }

                    $scope.view.paxes.forEach(function (paxe) {
                        if (paxe.segmentSeats && paxe.segmentSeats.length > 0) {
                            $scope.bookingHasSeatmap = true;
                            return;
                        }
                    });

                }
                else
                    console.log("no data");
            });
        };

        
        $scope.generateTravelDiary = function () {
            if ($scope.travelDiaryStates.isWaiting === true) {
                return;
            }
            $scope.travelDiaryStates.isWaiting = true;
            $scope.travelDiaryStates.isError = false;

            const loginInfo = $window.site.loginInfo;
            const rootUrl = $window.site.rootUrl.replace(/\/$/, '')
            //window.open(`/API/TravelPlateform/GetTravelDiary?mId=${loginInfo.Agent.AgentId}&lId=${loginInfo.LoginId}&clientId=${loginInfo.Client.ClientId}&agencyNumber=${loginInfo.Client.AccountNumber}&bookingReference=${extractNumbersFromString($scope.view.bookingNumber)}`, '_blank');
            window.open(rootUrl + "/account/reservations/traveldiary?bookingNumber=" + $scope.view.bookingNumber, '_blank');
            $scope.travelDiaryStates.isWaiting = false;
        }

        $scope.getFormattedDate = function (date) {
            return moment(date).format('D MMMM YYYY');
        }

        $scope.getInvoiceDownloadLink = function (invoiceId, forceDownload, bookingNumber) {
            return apiClient.AIR.getInvoiceDownloadLink(invoiceId, forceDownload, bookingNumber);
        };

        $scope.getETicketDownloadLink = function (invoiceId, forceDownload, bookingNumber) {
            return apiClient.AIR.getETicketDownloadLink(invoiceId, forceDownload, bookingNumber);
        };

        $scope.exportExcel = function () {
            var url = apiClient.AIR.getBookingListExportLink($scope.bookingListRequest);
            $window.location.href = url;
        };

        $scope.returnToList = function () {
            $scope.showDetail = false;
        };
        $scope.callDrop = function (_event, type) {
            var varIsCountry = (type == 'country') ? 1 : 0;
            var name = 'i-' + type;
            var value = $("#ul-" + type).get(0);
            FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
            if (type == 'date') {
                $scope.updateDates();
            }
            $scope.getBookingList();
        };
        $scope.updateDates = function () {
            $scope.bookingListRequest.FromDepartureDate = null;
            $scope.bookingListRequest.ToDepartureDate = null;
            $scope.bookingListRequest.FromBookingDate = null;
            $scope.bookingListRequest.ToBookingDate = null;
            var from = moment($scope.bookingRequest.fromDate, 'DD/MM/YYYY');
            var to = moment($scope.bookingRequest.toDate, 'DD/MM/YYYY');

            if ($scope.dateType == 1) {
                $scope.bookingListRequest.FromBookingDate = from.isValid() ? from.format('YYYY-MM-DD') : '1970-01-01';
                // add one day since date filter is < and not <=
                $scope.bookingListRequest.ToBookingDate = to.isValid() ? to.add(1, 'days').format('YYYY-MM-DD') : '2270-01-01';
            }
            else {
                $scope.bookingListRequest.FromDepartureDate = from.isValid() ? from.format('YYYY-MM-DD') : '1970-01-01';
                // add one day since date filter is < and not <=
                $scope.bookingListRequest.ToDepartureDate = to.isValid() ? to.add(1, 'days').format('YYYY-MM-DD') : '2270-01-01';
            }
        };

        //**************************
        $scope.sendReceiptShow = function (index) {
            var value = $("#i-payment-receipt-" + index).get(0);
            FPop({ oTarget: value, iPop: 'i-receipt-pop-' + index, vStyle: 'classic' });
        };
        $scope.sendReceipt = function (payment, index) {
            if (XLFormValid({ iCont: 'i-form-receipt-' + index })) {
                var pId = payment.paymentId;
                var pspId = payment.bankCards[0].paymentPspId;
                var bId = $scope.view.bookingId;
                var bNum = $scope.view.bookingNumber;
                var to = $("#i-receipt-email-" + index).val();
                var mail = { bookingId: bId, bookingNumber: bNum, paymentId: pId, paymentPspId: pspId, addressTo: to, type: "ReceiptOfPayment" };

                var okMSG = function () { XLConfirm({ vTitle: geT('airbooking.b2b.option.emailsent'), vTextYes: 'OK', vHideNo: true }); };
                var failMSG = function () { XLConfirm({ vTitle: getT('airbooking.b2b.option.emailerror'), vTextYes: 'OK', vHideNo: true }); };

                apiClient.sendMail(mail).success(function (response) {
                    if (response) okMSG();
                    else failMSG();
                    $("#i-receipt-email-" + index).val('');
                }).error(function () { failMSG(); });


                FPopHide({ iPop: 'i-receipt-pop-' + index });
            }
        };
        //**************************
        $scope.sendEticketShow = function () {
            var value = $("#i-eticket").get(0);
            FPop({ oTarget: value, iPop: 'i-eticket-pop', vStyle: 'classic' });
        };
        $scope.sendEticket = function () {
            if (XLFormValid({ iCont: 'i-form-eticket' })) {
                var bId = $scope.view.bookingId;
                var bNum = $scope.view.bookingNumber;
                var to = $("#i-eticket-email").val();
                var mail = { bookingId: bId, bookingNumber: bNum, addressTo: to, type: "ETicket" };

                var okMSG = function () { XLConfirm({ vTitle: geT('airbooking.b2b.option.emailsent'), vTextYes: 'OK', vHideNo: true }); };
                var failMSG = function () { XLConfirm({ vTitle: getT('airbooking.b2b.option.emailerror'), vTextYes: 'OK', vHideNo: true }); };

                apiClient.sendMail(mail).success(function (response) {
                    if (response) okMSG();
                    else failMSG();
                    $("#i-eticket-email").val('');
                }).error(function () { failMSG(); });

                FPopHide({ iPop: 'i-eticket-pop' });
            }
        };
        //**************************
        $scope.clearFilters = function () {
            $scope.bookingListRequest = {};
            $scope.bookingRequest = {};
        };

        $scope.searchBookings = function () {
            $scope.updateDates();
            $scope.getBookingList();
        };

        $scope.selectProductType = function (type) {
            $scope.productType = type;
            $window.sessionStorage.setItem('selectedProductType', type);
        };

        //**************************

        //This is only for debug
        $window.$scope = $scope;
        $window.$scope.apiClient = apiClient;

        $scope.loadBookingDetails = function (hash) {
            var m = hash.match(/([^#$]+)(?:#([^#$]+))?/i);
            var detail = m[1];
            var type = m.length > 1 ? m[2] : null;
            var hotelOnly = type && type.toUpperCase() === 'H';
            $scope.getBookingDetails(detail, hotelOnly);
            $window.sendAnalytics('/Mybookings/View', 'Booking view');
            $window.sendInspectlet("pageUrl", $window.getUrl('Mybookings/View'));
        };

        //************************************************************************************************************************
        //OnLoad
        if (window.location.hash !== "") {
            $scope.updateDates();// set default values to avoid timeout

            if (window.location.hash.includes('#restartBot')) {
                var clientId = localStorage.getItem('clientid');
                var botHash = localStorage.getItem('bothash');
                var btnId = localStorage.getItem('btnid');

                var startTimestampWaitForData;

                function waitForData(timestamp) {
                    if (startTimestampWaitForData === undefined) {
                        startTimestampWaitForData = timestamp;
                    }
                    var elapsed = timestamp - startTimestampWaitForData;
                    if ($scope.insuranceLoaded && $scope.bookingDetailsLoaded) {
                        console.info("loadchatbotfrommemory");
                        startChatBotFromMemory(clientId, botHash, btnId);
                    } else {
                        if (elapsed < 10000) {
                            requestAnimationFrame(waitForData);
                        } else {
                            toggleFreshbotLazyLoad(false, btnId);
                        }
                    }
                }

                function waitForDrawer() {
                    if (document.getElementById('c-drawer-btn') != null) {
                        if (btnId !== 'button-open-resa-drawer')
                            $scope.toggleDrawerResa();
                        toggleFreshbotLazyLoad(true, btnId);
                    } else {
                        requestAnimationFrame(waitForDrawer);
                    }
                }

                requestAnimationFrame(waitForDrawer);
                requestAnimationFrame(waitForData);
            }


            $scope.loadBookingDetails(window.location.hash.replace('#restartBot',''));
        }
        else {
            $scope.updateDates();
            $scope.getBookingList();
            $window.sendAnalytics('/Mybookings/', 'List of booking');
            $window.sendInspectlet("pageUrl", $window.getUrl('Mybookings/'));
        }

        //Detect if hash changes.
        var hashChanged = function (callback) {
            var lastHash = window.location.hash;
            var innerFunc = function () {
                if (window.location.hash != lastHash) {
                    lastHash = window.location.hash;
                    if (callback)
                        callback(lastHash);
                }
                setTimeout(innerFunc, 100);
            };
            setTimeout(innerFunc, 100);
        };
        hashChanged(function (hashValue) {
            if (hashValue === "" || window.location.hash === "#") {
                $scope.showDetail = false;
                $scope.$apply(function () {
                    setTimeout(function () {
                        $window.scrollTo(0, 0);
                        $("#list").removeClass("c-form-disabled");
                    }, 100);
                });
                $window.sendAnalytics('/Mybookings/', 'List of booking');
                $window.sendInspectlet("pageUrl", $window.getUrl('Mybookings/'));
            }
            else {
                $scope.loadBookingDetails(window.location.hash);
            }

            if (!$scope.botFirstStart) {
                Freshbots.deActivate(true);
            }
            console.log(hashValue);
        });

        //GoToContactForm
        //@desc this function build the url to pass to contact form with the customer data
        $scope.goToContactForm = function () {
            var contactUrl = "../contact/nous-contacter/";
            if ($scope.view.bookingNumber)
                contactUrl = "../contact/nous-contacter/" + $scope.view.bookingNumber;

            //Check agent information to autofill fields in contact form
            if ($scope.loginInfo && $scope.loginInfo.Agent && $scope.loginInfo.Agent.FirstName
                && $scope.loginInfo.Agent.LastName && $scope.loginInfo.Agent.LastName && $scope.loginInfo.Agent.Account) {
                contactUrl = "../contact/nous-contacter/" +
                    $scope.view.bookingNumber + "/" +
                    $scope.loginInfo.Agent.FirstName + "/" +
                    $scope.loginInfo.Agent.LastName + "/" +
                    $scope.loginInfo.Agent.Account;
            }
            window.location.href = contactUrl;
        };
        //************************************************************************************************************************

        //Open the modal with the key provided
        $scope.openModal = function (key) {
            if (key) {
                $timeout(function () {
                    $('[data-remodal-id=' + key + ']').remodal().open();
                },
                    200,
                    false);
            }
        };

        // Prepare the object to display in the Taxes Modal
        $scope.prepareTaxesDetailModal = function (key, paxType, fareItem, quantity) {
            $scope.displayTaxesDetailModal = false;
            if (fareItem) {

                var faresTaxes = airHelper.prepareTaxesObjectForBkgDetails(fareItem);

                if (faresTaxes) {

                    var taxesDetailsObject = {
                        paxtype: paxType,
                        key: key,
                        paxnumber: quantity,
                        fares: faresTaxes
                    };

                    $scope.modalTaxesKey = taxesDetailsObject.key;
                    $scope.modalTaxesPaxType = taxesDetailsObject.paxtype;
                    $scope.modalTaxesPaxFares = taxesDetailsObject.fares;
                    $scope.modalTaxesPaxNumber = taxesDetailsObject.paxnumber;

                    $scope.$broadcast("updateTaxesDetailModalData",
                        {
                            key: $scope.modalTaxesKey,
                            paxType: $scope.modalTaxesPaxType,
                            fares: $scope.modalTaxesPaxFares,
                            paxnumber: $scope.modalPaxNumber
                        });

                    $scope.displayTaxesDetailModal = true;

                    $scope.openModal('taxes-detail-modal-' + $scope.modalTaxesKey + '-' + $scope.modalTaxesPaxType);

                } else
                    return;
            }
        };

        $scope.getInsurancePdf = function (insurance) {
            if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.insuranceReference === '00001') {
                return $window.getUrl('page/pdfviewer?pdfname=conditionsassuranceannulation');
            }
            else if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.insuranceReference === '00002') {
                return $window.getUrl('page/pdfviewer?pdfname=detailsgarantimultiinsurance');
            }
            else if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.insuranceReference === '00003') {
                return $window.getUrl('page/pdfviewer?pdfname=conditionsassurancerapatriement');
            }
            else if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.insuranceReference === '00004') {
                return $window.getUrl('page/pdfviewer?pdfname=conditionsassurancerapatriement_0004');
            }
            else
                return null;
        };

        $scope.getInsuranceName = function (insurance) {
            if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') &&
                (insurance.insuranceReference === '00004' || insurance.insuranceReference === '00004')) {
                return $i18next.t('mrfly-widget-insurance-payment.subtitle');
            }
            else
                return insurance.displayName;
        };

        $scope.getInsuranceContractNumber = function (insurance) {
            if (insurance.companyReference === '00002' && insurance.insuranceReference === '00001') {
                return 'AX2016061';
            }
            else if (insurance.companyReference === '00002' && insurance.insuranceReference === '00002') {
                return 'AX2016060';
            }
            else if (insurance.companyReference === '00002' && insurance.insuranceReference === '00003') {
                return 'AX2016062';
            }
            else if (insurance.companyReference === '00002' && insurance.insuranceReference === '00004') {
                return 'AX2019216';
            }
            if (insurance.companyReference === '00001' && insurance.insuranceReference === '00001') {
                return '7 906 155';
            }
            else if (insurance.companyReference === '00001' && insurance.insuranceReference === '00002') {
                return '7 906 157';
            }
            else if (insurance.companyReference === '00001' && insurance.insuranceReference === '00003') {
                return '7 906 156';
            }
            else
                return null;
        };
        $scope.print = function () {
            window.print();
        };

        $scope.toggleDrawerResa = function () {
            $scope.drawerResaOpened = !$scope.drawerResaOpened;
            $scope.drawerDocumentsOpened = false;
        };

        $scope.closeDrawerResa = function () {
            if ($scope.drawerResaOpened) {
                $scope.drawerResaOpened = false;
            }
        };

        $scope.toggleDrawerDocuments = function () {
            $scope.drawerDocumentsOpened = !$scope.drawerDocumentsOpened;
            $scope.closeDocumentsTabs();
            $scope.drawerResaOpened = false;
        };

        $scope.closeDrawerDocuments = function () {
            if ($scope.drawerDocumentsOpened) {
                $scope.closeDocumentsTabs();
                $scope.drawerDocumentsOpened = false;
            }
        };

        $scope.closeDocumentsTabs = function () {
            $scope.showEticket = false;
            $scope.showBill = false;
            $scope.showVoucher = false;
            $scope.showCB = false;
        };

        var headerHeight = 0;
        var stickyScrollOffset = undefined;
        var isTablet = window.matchMedia('(min-width: 768px)');

        if (!isTablet.matches) {
            $window.addEventListener('scroll', stickyDrawerBarHandler, false);
        }

        $window.addEventListener('resize', function () {
            resetStickyDrawerBar();
            if (!isTablet.matches) {
                $window.addEventListener('scroll', stickyDrawerBarHandler, false);
            } else {
                $window.removeEventListener('scroll', stickyDrawerBarHandler, false);
            }
        });

        function stickyDrawerBarHandler(event) {
            var scrollTop = (event.target.documentElement.scrollTop !== 0) ? event.target.documentElement.scrollTop : event.target.body.scrollTop;
            var fixed = checkFixedHeader();
            var drawerBtn = document.getElementById('c-drawer-btn');
            var drawerBar = document.getElementsByClassName('c-drawer__bar').item(0);
            var tchatBot = document.getElementsByClassName('cl-chatbox-toggler').item(0);
            if (!stickyScrollOffset && drawerBtn) {
                var bodyScrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body).scrollTop;
                stickyScrollOffset = drawerBtn.getBoundingClientRect().top + drawerBtn.getBoundingClientRect().height + bodyScrollTop;
            }
            if (fixed && headerHeight === 0) {
                headerHeight = document.getElementsByTagName('header').item(0).offsetHeight;
                stickyScrollOffset -= headerHeight;
                stickyTransitionListener();
            }
            if (scrollTop && drawerBar) {
                if (scrollTop >= stickyScrollOffset) {
                    drawerBar.classList.add('--sticky');
                    tchatBot ? tchatBot.classList.add('--bottom') : undefined;
                }
                if (scrollTop < stickyScrollOffset) {
                    drawerBar.classList.remove('--sticky');
                    tchatBot ? tchatBot.classList.remove('--bottom') : undefined;
                }
            }
        }

        function checkFixedHeader() {
            var header = document.getElementsByTagName('header').item(0);
            if (header) {
                return window.getComputedStyle(header).position === 'fixed' || header.className.indexOf('__fixed') > -1;
            }
        }

        function stickyTransitionListener() {
            var header = document.getElementsByTagName('header').item(0);
            if (header) {
                header.addEventListener('transitionend', function () {
                    stickyScrollOffset = undefined;
                    headerHeight = 0;
                });
            }
        }

        function resetStickyDrawerBar() {
            stickyScrollOffset = undefined;
            headerHeight = 0;
            document.getElementsByClassName('c-drawer__bar').item(0) ? document.getElementsByClassName('c-drawer__bar').item(0).classList.remove('--sticky') : undefined;
            document.getElementsByClassName('cl-chatbox-toggler').item(0) ? document.getElementsByClassName('cl-chatbox-toggler').item(0).classList.remove('--bottom') : undefined;
        }
    }]);