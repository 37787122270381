webClient.controller('AirOption', ['$scope', '$window', '$timeout', '$http', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, $http, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {

        //Initialize Variables
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();
        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        $scope.bkgData = bkgData;
        $scope.dossierNumber = bkgData.bookingResponse.bookingNumber;
        $scope.isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
        getBundleWidget("mrfly-widget-loader.js").done(function () {

        });

        window.$scope = $scope;
        window.hideMessageTO = true;
        window.displayBrandedFaresSummary = true;
        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
        $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true';
        $scope.accommodation = null;

        var user = $window.site.loginInfo;
        if (user) {
            var clientPaymentMode = user.Client.PaymentMode;

            if (clientPaymentMode)
                $scope.clientPaymentMode = clientPaymentMode;
        }

        //Tracking
        try {
            //GTM
            if (trackingService.isActivated()) {
                trackingService.initDataLayer('checkout');
                trackingService.loadSessionData('step', 3);
                trackingService.setOption('OPTION');
                trackingService.parseScope($scope);
                trackingService.pushDataLayer('step', 4);
            }

            //omniture
            if (omnitureService.isActivated()) {
                omnitureService.sendEvent(omnitureService.PURCHASE_4_VOL, omnitureService.FLIGHT_TUNNEL, null);
            }
        }
        catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }
        var getBasketDetails = function () {
            var siteTypeIsB2b = $window.site.type === 2;
            var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
            var mandatoryFees = [];
            var totalAccommodations = siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel.value : 0;
            $scope.bkgData.accommodations.forEach(function (accommodation) {
                totalAccommodations += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
            });
            mandatoryFees.push({
                flexyDescription: getT('airbooking.flightprice'),
                totalPrice: $scope.bkgData.prixTotal.value,
                prices: []
            });
            mandatoryFees.push({
                flexyDescription: getT('mrfly-widget-accommodation-info.hotelprice'),
                totalPrice: totalAccommodations,
                prices: []
            });
            if (proposal.mandatoryFees) {
                proposal.mandatoryFees.forEach(function (mandatoryFee) {
                    mandatoryFee.flexyDescription = getT('mrfly-widget-basket.bookingfees');
                });
                Array.prototype.push.apply(mandatoryFees, proposal.mandatoryFees);
            }
            return mandatoryFees;
        }
        $scope.LoadVueJsWidgets = function () {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {

                //nights number
                var nights = moment($scope.bkgData.accommodations[0].to).diff(moment($scope.bkgData.accommodations[0].from), "days");
                $scope.bkgData.accommodations[0].nightNumber = nights;

                getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {

                    mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                    mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = $scope.bkgData.accommodations[0];
                    mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;
                    mrflyWidgets['mrfly-widget-accommodation-info'].siteIsB2b = window.site.isLogged && window.site.type == 2;
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });


                getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {
                    mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.bkgData.totalBasketPrice();
                    var extraDetails = [];

                    var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                    var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);
                    Array.prototype.push.apply(extraDetails, bags);
                    Array.prototype.push.apply(extraDetails, insurances);

                    //Seatmap
                    var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                    if (seatmapObj != null) {
                        var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                        Array.prototype.push.apply(extraDetails, seatmap);
                    }

                    //Discount 
                    if ($scope.bkgData.discountInfo) {
                        var discount = {
                            description: getT('airbooking.discountcode'),
                            totalPrice: $scope.bkgData.discountInfo.applicableAmount,
                            details: [],
                            type: 'DISCOUNT'
                        };

                        Array.prototype.push.apply(extraDetails, [ discount ]);
                    }

                    var basketDetails = basketHelper.getPriceDetails(
                        $window.site.type,
                        $scope.bkgData.purchaseResponse.proposalsRequested,
                        $scope.bkgData.accommodations,
                        $scope.bkgData.agencyMarginHotel,
                        extraDetails,
                        $scope.bkgData.purchaseResponse.productIsTrain ? "RAIL" : "FLIGHT",
                    );

                    mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });

            }
        };

        if (bkgData) {

            bkgData.totalBasketPrice = function () {
                if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                    if (siteTypeIsB2b) {
                        // mandatory Options
                        var insurance = 0;
                        if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                            insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                        }
                        var totalAmount = ($scope.bkgData.prixTotalWithoutMargin.value - insurance + $scope.bkgData.accommodations[0].totalPriceRacknet);
                        $scope.bkgData.agencyMarginHotel.value = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                        var basketPrice = totalAmount + $scope.bkgData.agencyMarginHotel.value + insurance;
                        console.log("AirOption.B2B.basketPrice", basketPrice);
                        return basketPrice;
                    }
                    else {
                        var basketPrice = $scope.bkgData.prixTotal.value + $scope.bkgData.accommodations[0].totalPriceForcedPrice;
                        console.log("AirOption.B2C.basketPrice", basketPrice);
                        return basketPrice;
                    }
                } else {
                    var basketPrice = $scope.bkgData.prixTotal.value;
                    console.log("AirOption.Other.basketPrice", basketPrice);
                    return basketPrice;
                }
            };

            /*
            bkgData.totalBasketPrice = function (purchaseResponse) {
                //TO REVIEW
                if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                    return bkgData.prixTotal.value + bkgData.accommodations[0].totalPriceForcedPrice;
                } else {
                    return bkgData.prixTotal.value;
                }
            }
            */

            var keyGenerated = "";
            keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;

            bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);

            if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {
                var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
                bkgData.insurancesSelected = _insuranceSelected;

                if (bkgData.accommodations && bkgData.accommodations.any()) {
                    $scope.LoadVueJsWidgets();
                }

                $scope.$broadcast("onBookingSummaryUpdate");

                $scope.goTo = function (item) {

                    if (window.mrflyWidgets['airbooking-process-loader'] !== null && typeof window.mrflyWidgets['airbooking-process-loader'] !== 'undefined') {
                        window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");
                    }

                    //Tracking
                    try {
                        //GTM
                        if (trackingService.isActivated()) {
                            trackingService.pushPageData('/Flightsearch/Payment/ConfirmationOption', 'Payment Confirmation Option', lng, false);
                        }
                    }
                    catch (ex) {
                        console.log(ex.message);
                        $window.trackingError = ex.message;
                    }

                    $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Payment/Confirmation Option'));

                    $http.post('/api/api/payment/createoption', {
                        requestId: $scope.bkgData.bookingRequest.requestId,
                        bookingId: bkgData.bookingResponse.bookingId
                    }).then(function (response) {
                        //check response status
                        if (response.status === 200) {
                            var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", response.data);
                            window.location.href = finishUrl;
                        }
                    });
                };
            }
            else {

                if (window.mrflyWidgets['airbooking-process-loader'] !== null && typeof window.mrflyWidgets['airbooking-process-loader'] !== 'undefined') {
                    window.mrflyWidgets['airbooking-process-loader'].$store.commit("hideLoader");
                }
                window.location.href = searchUrl;
            }
        }
        else {
            $window.FLoading({ vAction: 'show' });
            window.location.href = searchUrl;
        }

        //Taxes details click
        $scope.$on("displayTaxesDetailsClick", function (event, args) {
            $scope.displayTaxesDetailModal = true;
            $scope.modalTaxesKey = args.key.replaceAll(":", "-");
            $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
            $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
            $scope.modalTaxesPaxFares = args.paxFares;
            $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, fares: $scope.modalTaxesPaxFares });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
            },
                200, false);

        });

    }]);