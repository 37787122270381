//**************************************************************************************************
//Define TSE Module
var tseModule = angular.module('tseModule', []);

//**************************************************************************************************
//Utilities Service
tseModule.service('tseUtil', ['$window', '$timeout', '$q', function ($window, $timeout, $q) {
    var self = this;

    //**************************************************************************************************
    //Object
    this.getOrDefault = function (item, defaultValue) {
        if (typeof defaultValue === "undefined") defaultValue = null;
        var response = defaultValue;
        if (typeof item !== "undefined") response = item;
        if (defaultValue != null && item == null) response = defaultValue;
        return response;
    };
    this.async = function (methodToAsync) {
        var def = $q.defer();

        $timeout(function () {
            def.notify(methodToAsync);
            return methodToAsync();
        }).then(
            function success(resp) { def.resolve(resp); },
            function failure(resp) { def.reject(resp); }
        );

        return def.promise;
    };

    //**************************************************************************************************
    //Stopwatch
    this.StopWatch = {};
    this.StopWatch.createNew = function () {
        return new function () {
            var _startDate = new Date();
            var _lastDate = null;

            this.getElapsed = function () {
                if (!_startDate) return 0;
                if (_lastDate)
                    return _lastDate - _startDate;
                else
                    return new Date() - _startDate;
            }
            this.stop = function () {
                _lastDate = new Date();
                return this.getElapsed();
            }
            this.start = function () {
                _lastDate = null;
                if (!_startDate)
                    _startDate = new Date();
            }
            this.clear = function () {
                _lastDate = null;
                _startDate = null;
            }
        }
    };

    //**************************************************************************************************
    //Array
    this.Array = {};
    this.Array.asyncGrep = function (array, grepFunction) {
        return self.async(function () { return self.Array.grep(array, grepFunction) });
    };
    this.Array.asyncMap = function (array, mapFunction) {
        return self.async(function () { return self.Array.map(array, mapFunction) });
    };
    this.Array.asyncEach = function (array, eachFunction) {
        return self.async(function () { return self.Array.each(array, eachFunction) });
    };
    this.Array.asyncDoEach = function (array) {
        return self.async(function () { return self.Array.doEach(array) });
    };

    //**************************************************************************************************
    //String
    this.String = {};
    this.String.createRandom = function (length) {
        length = self.getOrDefault(length, 10);
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };

    //**************************************************************************************************
    //Url
    this.Url = {};
    this.Url.appendValueToUrl = function (url, key, value) {
        return self.Url.appendToUrl(url, key + "=" + value);
    };
    this.Url.appendValueToUrlBefore = function (url, key, value) {
        var kv = key + "=" + value;
        if (url) {
            if (url.indexOf("?") > -1) {
                var url_0 = url.substr(0, url.indexOf("?") + 1);
                var url_1 = url.substr(url.indexOf("?") + 1);
                return url_0 + kv + "&" + url_1;
            }
            else
                return url + "?" + kv;
        }
        else
            return null;
    };
    this.Url.appendToUrl = function (url, appendText) {
        if (url) {
            if (url.indexOf("?") > -1)
                return url + "&" + appendText;
            else
                return url + "?" + appendText;
        }
        else
            return null;
    };
    this.Url.getRequestUrl = function (url, controller, id, query) {
        var resp = null;
        id = self.getOrDefault(id, null);
        query = self.getOrDefault(query, null);
        if (!url.endsWith("/"))
            url += "/";
        if (id == null)
            resp = encodeURI(url + controller);
        else
            resp = encodeURI(url + controller + "/" + id.replace(/\./g, ""));
        if (query != null)
            resp = this.appendToUrl(resp, query);
        return resp;
    };

    //**************************************************************************************************
    //Location
    this.Location = {};
    this.Location.isSecure = $window.location.protocol == "http:" ? false : true;
    this.Location.getQueryString = function () {
        if ($window.location.href.indexOf("?") > -1)
            return $window.location.href.slice($window.location.href.indexOf("?") + 1);
        else
            return "";
    };
    this.Location.getQueryStringVar = function (key) {
        return unescape($window.location.search.replace(new RegExp("^(?:.*[&\\?]" + escape(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    };
    this.Location.getHashVar = function (key) {
        return unescape($window.location.hash.replace(new RegExp("^(?:.*[&\\#]" + escape(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    };
    this.Location.navigateTo = function (url) {
        var f = document.createElement("FORM");
        f.action = url;

        var indexQM = url.indexOf("?");
        if (indexQM >= 0) {
            // the URL has parameters => convert them to hidden form inputs
            var params = url.substring(indexQM + 1).split("&");
            for (var i = 0; i < params.length; i++) {
                var keyValuePair = params[i].split("=");
                var input = document.createElement("INPUT");
                input.type = "hidden";
                input.name = keyValuePair[0];
                input.value = keyValuePair[1];
                f.appendChild(input);
            }
        }

        document.body.appendChild(f);
        f.submit();
    };
    //**************************************************************************************************
    //Debug
    this.Debug = {};
    this.Debug.log = function (text) {
        if ($window.isDefined(console)) {
            var con = self.getOrDefault(console, null);
            if (con != null)
                con.log(text);
        }
    };

    //**************************************************************************************************
    //LocalStorage
    this.Storage = { key: "__keys_timestamp" };
    this.Storage.isAvailable = $window.isDefined($window.Storage);
    this.Storage.get = function (key) {
        var val = $window.localStorage.getItem(key);
        return angular.fromJson(val);
    };
    this.Storage.set = function (key, obj) {
        var val = angular.toJson(obj);
        var keys = self.Storage.get(self.Storage.key) || [];
        try {
            $window.localStorage.setItem(key, val);
            keys.push(key);
            $window.localStorage.setItem(self.Storage.key, angular.toJson(keys))
        } catch (err) {
            self.Debug.log("Quota exceeded... removing old element.")
            var kRmv = keys.first();
            self.Storage.remove(kRmv);
            self.Storage.set(key, obj);
        }
    };
    this.Storage.remove = function (key) {
        var keys = self.Storage.get(self.Storage.key) || [];
        keys = keys.where(function (k) { return k != key; });
        $window.localStorage.removeItem(key);
        $window.localStorage.setItem(self.Storage.key, angular.toJson(keys));
    };
    //**************************************************************************************************
    //SessionStorage
    this.Session = { key: "__keys_timestamp" };
    this.Session.isAvailable = $window.isDefined($window.Storage);
    this.Session.get = function (key) {
        var val = $window.sessionStorage.getItem(key);
        return angular.fromJson(val);
    };
    this.Session.set = function (key, obj) {
        var val = angular.toJson(obj);
        var keys = self.Session.get(self.Session.key) || [];
        try {
            $window.sessionStorage.setItem(key, val);
            keys.push(key);
            $window.sessionStorage.setItem(self.Session.key, angular.toJson(keys))
        } catch (err) {
            self.Debug.log("Quota exceeded... removing old element.")
            var kRmv = keys.first();
            self.Session.remove(kRmv);
            self.Session.set(key, obj);
        }
    };
    this.Session.remove = function (key) {
        var keys = self.Session.get(self.Session.key) || [];
        keys = keys.where(function (k) { return k != key; });
        $window.sessionStorage.removeItem(key);
        $window.sessionStorage.setItem(self.Session.key, angular.toJson(keys));
    };

    //**************************************************************************************************
    //Cookies
    this.Cookies = {};
    this.Cookies.get = function (name) {
        var nameEQ = name + "=";
        var ca = $window.document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    };
    this.Cookies.set = function (name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        else var expires = "";
        var nCookie = name + "=" + value + expires + "; path=/";
        if ($window.location.protocol == "https:")
            nCookie = nCookie + ";secure";
        $window.document.cookie = nCookie;
    };
    this.Cookies.remove = function (name) {
        if (self.Cookies.get(name))
            self.Cookies.set(name, "", -1);
    };
}]);

//**************************************************************************************************    
//Base RestClient Service
tseModule.service('tseRestClient', ['$window', '$http', '$q', 'tseUtil', function ($window, $http, $q, util) {
    this.createClient = function (url, urlSSL, authToken) {
        //Base Client Class
        var restClient = function () {
            var self = this;

            this.url = url;
            this.urlSSL = util.getOrDefault(urlSSL, url);
            this.authToken = util.getOrDefault(authToken, util.Cookies.get("authToken_" + $window.site.partnerId));
            this.authToken = util.getOrDefault(this.authToken, util.Cookies.get("authToken"));
            this.mappingName = $window.site.mappingName;
            this.authTokenMode = util.getOrDefault(util.Cookies.get("authTokenMode"), 0);
            if ($window.site.partnerId == "be-nl" || $window.site.partnerId == "be-fr" || $window.site.partnerId == "be-en" || $window.site.partnerId == "be-it") {
                this.loginId = util.getOrDefault(util.Cookies.get("lid_" + "be-hsot"), null);
            } else {
                this.loginId = util.getOrDefault(util.Cookies.get("lid_" + $window.site.partnerId), null);
            }
            this.debugMode = false;
            this.useSSL = util.Location.isSecure;
            this.successCallback = function (controller, id, data, status, headers, config) {
                if (self.debugMode) {
                    util.Debug.log("DEBUG: Controller: " + controller + "/" + id + " -> ");
                    util.Debug.log(data);
                }
            };
            this.failureCallback = function (controller, id, data, status, headers, config) {
                if (data && data.Type == "Token") {
                    if (data.HaveToRenew) {
                        util.Cookies.remove("authToken");
                        util.Cookies.remove("authToken_" + $window.site.partnerId);
                        if (self.debugMode) {
                            $window.alert("DEBUG: " + data.Exception + "\r\nThe page will be reloaded with a new token.");
                        }
                    }
                    if (data.HaveToChangeAuthMode) {
                        util.Cookies.set("authTokenMode", 1);
                        if (self.debugMode) {
                            $window.alert("DEBUG: " + data.Exception + "\r\nThe page will be reloaded using another kind of AuthMode (queryString mode)");
                        }
                    }
                    location.reload(true);
                    return;
                }
                if (self.debugMode) {
                    var message = data;
                    if (data.message)
                        message = util.getOrDefault(data.message, null);
                    $window.alert("DEBUG: Error: " + status + "\r\nController: " + controller + "\r\n" + message);
                }
            };

            //Set transformations
            $http.defaults.transformRequest = function (value) {
                return angular.toJson(value);
            };
            $http.defaults.transformResponse = function (value) {

                //avoid parsing null object
                if (!value)
                    return value;

                if (value.indexOf("TokenMaxRequestException") > -1 || value.indexOf("TokenExpiredException") > -1 || value.indexOf("TokenHostException") > -1 ||
                    value.indexOf("TokenIpAddressException") > -1 || value.indexOf("TokenMaximumException") > -1 || value.indexOf("TokenRequestException") > -1 ||
                    value.indexOf("TokenUserAgentException") > -1 || value.indexOf("TokenInvalidException") > -1) {
                    return { Exception: value, Type: 'Token', HaveToRenew: true, HaveToChangeAuthMode: false };
                }
                if (value.indexOf("TokenInvalidApiKeyException") > -1) {
                    return { Exception: value, Type: 'Token', HaveToRenew: false, HaveToChangeAuthMode: true };
                }
                if (value.indexOf("TokenIsMissingException") > -1) {
                    if ($window.isDefined(self.authToken) && self.authToken)
                        return { Exception: value, Type: 'Token', HaveToRenew: false, HaveToChangeAuthMode: true };
                    else
                        return { Exception: value, Type: 'Token', HaveToRenew: true, HaveToChangeAuthMode: false };
                }

                //if (util.String.startsWith(value, "\"") && util.String.endsWith(value, "\""))
                if (value != "")
                    try {
                        return angular.fromJson(value);
                    }
                    catch (err) {
                        //console.log(err)
                    }

                return value;
            };

            //E-Tag Storage
            var eTagPrefix = "eTag-";
            var eTagKey = function (url) {
                if (util.getOrDefault(url) == null) return null;
                return eTagPrefix + url;
            };
            var eTagSave = function (url, etag, response) {
                url = util.getOrDefault(url);
                etag = util.getOrDefault(etag);
                if (url != null && etag != null) {
                    var sObj = { ETag: etag, Response: response };
                    util.Session.set(eTagKey(url), sObj);
                }
            };
            var eTagLoad = function (url) {
                url = util.getOrDefault(url);
                if (url != null) {
                    return util.Session.get(eTagKey(url));
                }
            };

            //Methods
            this['get'] = function (controller, id, query) {
                id = util.getOrDefault(id, null);
                query = util.getOrDefault(query, null);
                var currentUrl = self.useSSL ? self.urlSSL : self.url;
                var actionUrl = util.Url.getRequestUrl(currentUrl, controller, id, query);

                //if (util.Session.isAvailable) {
                //    $http.defaults.headers.get = {};
                //    var eTagObj = eTagLoad(actionUrl);
                //    if (eTagObj != null)
                //        $http.defaults.headers.get['If-None-Match'] = eTagObj.ETag;
                //}
                $http.defaults.withCredentials = true;
                if (this.authTokenMode == 0) {
                    $http.defaults.headers.common.AuthToken = this.authToken;
                    $http.defaults.headers.common.MappingName = this.mappingName;
                } else {
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "AuthToken", this.authToken);
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "MappingName", this.mappingName);
                }

                if (this.loginId)
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "lid", this.loginId);

                var deferred = $q.defer();
                deferred.notify(actionUrl);
                $http.get(actionUrl).then(function (resp) {
                    //if (util.Session.isAvailable) {
                    //    var etag = resp.headers().etag;
                    //    eTagSave(actionUrl, etag, resp);
                    //}
                    deferred.resolve(resp);
                    return resp;
                }, function (resp) {
                    if (resp.status === 304 && util.Session.isAvailable) {
                        deferred.resolve(eTagObj.Response);
                        return eTagObj.Response;
                    } else {
                        deferred.reject(resp);
                        return $q.reject(resp);
                    }
                });
                var promise = deferred.promise;
                promise.success = function (fn) { promise.then(function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };

                return promise.error(function (data, status, headers, config) {
                    self.failureCallback(controller, id, data, status, headers, config);
                }).success(function (data, status, headers, config) {
                    self.successCallback(controller, id, data, status, headers, config);
                });
            };
            this['delete'] = function (controller, id, query) {
                id = util.getOrDefault(id, null);
                query = util.getOrDefault(query, null);
                var currentUrl = self.useSSL ? self.urlSSL : self.url;
                var actionUrl = util.Url.getRequestUrl(currentUrl, controller, id, query);
                $http.defaults.withCredentials = true;

                if (this.authTokenMode == 0) {
                    $http.defaults.headers.common.AuthToken = this.authToken;
                    $http.defaults.headers.common.MappingName = this.mappingName;
                } else {
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "AuthToken", this.authToken);
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "MappingName", this.mappingName);
                }
                if (this.loginId)
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "lid", this.loginId);


                return $http['delete'](actionUrl).error(
                    function (data, status, headers, config) {
                        self.failureCallback(controller, id, data, status, headers, config);
                    }
                ).success(
                    function (data, status, headers, config) {
                        self.successCallback(controller, id, data, status, headers, config);
                    }
                );
            };
            this['head'] = function (controller, id, query) {
                id = util.getOrDefault(id, null);
                query = util.getOrDefault(query, null);

                var currentUrl = self.useSSL ? self.urlSSL : self.url;
                var actionUrl = util.Url.getRequestUrl(currentUrl, controller, id, query);

                $http.defaults.withCredentials = true;
                if (this.authTokenMode == 0) {
                    $http.defaults.headers.common.AuthToken = this.authToken;
                    $http.defaults.headers.common.MappingName = this.mappingName;
                } else {
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "AuthToken", this.authToken);
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "MappingName", this.mappingName);
                }
                if (this.loginId)
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "lid", this.loginId);

                return $http.head(actionUrl).error(
                    function (data, status, headers, config) {
                        self.failureCallback(controller, id, data, status, headers, config);
                    }
                ).success(
                    function (data, status, headers, config) {
                        self.successCallback(controller, id, data, status, headers, config);
                    }
                );
            };
            this['post'] = function (controller, id, data, query) {
                id = util.getOrDefault(id, null);
                data = util.getOrDefault(data, {});
                query = util.getOrDefault(query, null);

                var currentUrl = self.useSSL ? self.urlSSL : self.url;
                var actionUrl = util.Url.getRequestUrl(currentUrl, controller, id, query);

                $http.defaults.withCredentials = true;
                if (this.authTokenMode == 0) {
                    $http.defaults.headers.common.AuthToken = this.authToken;
                    $http.defaults.headers.common.MappingName = this.mappingName;
                } else {
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "AuthToken", this.authToken);
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "MappingName", this.mappingName);
                }
                if (this.loginId)
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "lid", this.loginId);

                return $http.post(actionUrl, data).error(
                    function (data, status, headers, config) {
                        self.failureCallback(controller, id, data, status, headers, config);
                    }
                ).success(
                    function (data, status, headers, config) {
                        self.successCallback(controller, id, data, status, headers, config);
                    }
                );
            };
            this['put'] = function (controller, id, data, query) {
                id = util.getOrDefault(id, null);
                data = util.getOrDefault(data, {});
                query = util.getOrDefault(query, null);

                var currentUrl = self.useSSL ? self.urlSSL : self.url;
                var actionUrl = util.Url.getRequestUrl(currentUrl, controller, id, query);

                $http.defaults.withCredentials = true;
                if (this.authTokenMode == 0) {
                    $http.defaults.headers.common.AuthToken = this.authToken;
                    $http.defaults.headers.common.MappingName = this.mappingName;
                } else {
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "AuthToken", this.authToken);
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "MappingName", this.mappingName);
                }
                if (this.loginId)
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "lid", this.loginId);

                return $http.put(actionUrl, data).error(
                    function (data, status, headers, config) {
                        self.failureCallback(controller, id, data, status, headers, config);
                    }
                ).success(
                    function (data, status, headers, config) {
                        self.successCallback(controller, id, data, status, headers, config);
                    }
                );
            };

            this['postForm'] = function (controller, id, data, query) {
                id = util.getOrDefault(id, null);
                data = util.getOrDefault(data, {});
                query = util.getOrDefault(query, null);

                var currentUrl = self.useSSL ? self.urlSSL : self.url;
                var actionUrl = util.Url.getRequestUrl(currentUrl, controller, id, query);
                actionUrl = util.Url.appendValueToUrl(actionUrl, "AuthToken", this.authToken);
                actionUrl = util.Url.appendValueToUrl(actionUrl, "MappingName", this.mappingName);
                if (this.loginId)
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "lid", this.loginId);

                var _form = document.createElement("form");
                _form.action = actionUrl;
                _form.method = "POST";
                if (data) {
                    for (var prop in data) {
                        var _item = document.createElement("input");
                        _item.type = "hidden";
                        _item.name = prop;
                        _item.value = data[prop];
                        _form.appendChild(_item);
                    }
                }
                var _btn = document.createElement("input");
                _btn.type = "submit";
                _btn.id = "btnSubmit";
                _btn.id = "btnSubmit";
                _form.appendChild(_btn);

                return _form;
            };
        };
        return new restClient();
    };
}]);

//**************************************************************************************************
//Api Client Service
tseModule.service('tseApiClient', ['$window', '$http', '$timeout', '$q', 'tseUtil', 'tseRestClient', function ($window, $http, $timeout, $q, util, restClient) {
    this.createClient = function (url, urlSSL, authToken) {
        //ApiClient Class
        var apiClient = function () {
            var self = this;
            var client = restClient.createClient(url, urlSSL, authToken);
            var clientData = restClient.createClient($window.site.loginUrl, urlSSL, authToken);
            //General binding
            this.enableDebug = function () {
                client.debugMode = true;
            };
            this.disableDebug = function () {
                client.debugMode = false;
            };
            this.bindSuccessCallback = function (successCallback) {
                client.successCallback = successCallback;
            };
            this.bindFailureCallback = function (failureCallback) {
                client.failureCallback = failureCallback;
            };

            //Controllers Name
            var controllers = {
                TokenController: 'Token',
                StorageController: 'Storage',
                AutocompleteController: 'Autocomplete',
                IpAddressController: 'IpAddress',
                AirlinesController: 'Airlines',
                FidelityCardsController: 'FidelityCards',
                AirFaresSearchController: 'AirFaresSearch',
                BookingController: 'BkgBooking',
                BankCasinoController: 'BkgBankCasino',
                BrandedFaresController: 'BkgBrandedFares',
                DiscountController: 'BkgDiscount',
                PaymentController: 'Payment',
                Payment3DSController: 'Payment3DS',
                SimplePaymentController: 'SimplePayment',
                PayPalTokenController: 'PaypalToken',
                PaymentMethodsController: 'PaymentMethods',
                SimplePaymentMethodsController: 'SimplePaymentMethods',
                SendEmail: 'SendEmail',
                WorldTourController: 'WorldTour',
                OptionsController: 'BkgOptions',
                MerovingioController: 'Merovingio',
                DupesController: 'API/api/Dupes',
                AIR: {
                    PartnerCommission: 'AIRPartnerCommission',
                    PartnerCommissionRange: 'AIRPartnerCommissionRange',
                    BookingSearch: 'AirBookingSearch',
                    BookingSearch2: 'AirBookingSearch2',
                    BkgBookingSearch: 'BkgBookingSearch',
                    BookingDetails: 'AirBookingDetails',
                    BookingInvoices: 'AirBookingInvoices',
                    BookingETickets: 'AirBookingETickets',
                    AirPricePredictions: 'AirPricePredictions'
                },
                B2B: {
                    B2BLogin: 'B2BLogin',
                    B2BAccount: 'B2BAccount',
                    B2BAgents: 'B2BAgents',
                    B2BClientMargin: 'B2BClientMargin',
                    B2BClientMarginHotel: 'B2BClientMarginHotel',
                    B2BClientMarginDpk: 'B2BClientMarginDpk',
                    B2BClientMarginCar: 'B2BClientMarginCar',
                    B2BClientMarginTransfert: 'B2BClientMarginTransfer',
                    B2BClientMarginVacationRental: 'B2BClientMarginVacationRental',
                    B2BRequests: 'B2BRequests',
                    B2BFareNotes: 'B2BFareNotes',
                    B2BNetworkMargin: 'B2BNetworkMargin'
                },
                PURCHASE: {
                    New: 'purchase/new',
                    Insurance: 'purchase/insurance',
                    Discount: 'purchase/discount',
                    CreateBooking: 'purchase/createbooking',                    
                    PaymentMethods: 'purchase/paymentmethods',
                    CasinoScoring: 'purchase/casinoscoring',
                    SeatMap: 'purchase/seatmap',
                    UnAccompaniedMinor: 'purchase/unaccompaniedminor',
                    FreezeInfo: 'purchase/freezeinfo'
                },              
                ACCOMMODATION: {
                    Add: 'accommodation/add',
                    Get: 'accommodation/get',
                    GetPurchase: 'accommodation/getpurchase'
                }
            };

            //Token
            this.getToken = function (apiKey, phrase) {
                return client.post(controllers.TokenController, null, { apiKey: apiKey, phrase: phrase }).success(function (data) { client.authToken = data.token; });
            };
            this.setToken = function (authToken) {
                client.authToken = authToken;
            };

            //Storage
            this.getStorage = function (id) {
                var deferred = $q.defer();
                deferred.notify(id);
                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };

                client.get(controllers.StorageController, id).then(
                    function success(resp) {
                        resp.data = JSON.parse(resp.data);
                        if (resp.data)
                            resp.data = resp.data[0];
                        deferred.resolve(resp);
                        return resp;
                    },
                    function fail(resp) {
                        deferred.reject(resp);
                        return resp;
                    });

                return promise;
            };
            this.setStorage = function (id, value) {
                value = JSON.stringify([value]);
                return client.post(controllers.StorageController, id, value);
            };

            //World Tour
            this.sendWorldTourForm = function (form) {
                return client.post(controllers.WorldTourController, null, form);
            };

            //Autocomplete
            this.getAutocomplete = function (term) {
                return client.get(controllers.AutocompleteController, term);
            };

            //Airlines
            this.getAirlines = function (term) {
                return client.get(controllers.AirlinesController, term);
            };

            //Fidelity Cards
            this.getFidelityCards = function (term) {
                return client.get(controllers.FidelityCardsController, term);
            };

            //IpAddress
            this.getIpAddress = function () {
                return client.get(controllers.IpAddressController, null);
            };
            //Dupes
            this.getDupes = function (data) {
                console.log("clientData",clientData);
                return clientData.post(controllers.DupesController, "getdupes", data);
            };

            //AirFaresSearch
            this.setAirFaresSearch = function (searchObject) {
                return client.post(controllers.AirFaresSearchController, null, searchObject);
            };
            this.getAirFaresSearchResults = function (requestId) {
                var deferred = $q.defer();
                deferred.notify(requestId);

                var swatch = util.StopWatch.createNew();

                var internalTries = [250, 500, 500, 500, 500, 500, 500, 500, 1000, 2000, 2000, 2000, 2000, 1000, 1000, 1000, 1000, 1000, 1000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000];
                var internalGet = function () {
                    util.Debug.log("Getting response id: " + requestId);
                    client.get(controllers.AirFaresSearchController, requestId).then(
                        function success(resp) {
                            if (resp.status === 200) {
                                swatch.stop();
                                util.Debug.log("Total response time: " + swatch.getElapsed() + "ms");
                                deferred.resolve(resp);
                            }
                            else if (resp.status === 204) {
                                $timeout(internalGet, 500);
                                /*var time = internalTries.shift();
                                if (time) {
                                    util.Debug.log("Wait " + time + "ms for getting response id: " + requestId);
                                    
                                } else
                                    deferred.reject(resp);*/
                            }
                            else
                                deferred.reject(resp);
                            return resp;
                        },
                        function fail(resp) {
                            deferred.reject(resp);
                            return resp;
                        });
                };
                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                swatch.start();
                internalGet();
                return promise;
            };

            //Booking
            this.createBooking = function (data) {
                if (data && $window.site) {
                    data.culture = $window.site.culture;
                }
                return client.post(controllers.BookingController, null, data);
            };
            this.getBookingResponse = function (requestId) {
                var deferred = $q.defer();
                deferred.notify(requestId);

                var swatch = util.StopWatch.createNew();

                var internalTries = [50, 1000, 2000, 2000, 2000, 2000, 1000, 1000, 1000, 1000, 1000, 1000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000];
                var internalGet = function () {
                    util.Debug.log("Getting booking response id: " + requestId);
                    client.get(controllers.BookingController, requestId).then(
                        function success(resp) {
                            if (resp.status === 200) {
                                swatch.stop();
                                util.Debug.log("Total booking response time: " + swatch.getElapsed() + "ms");
                                deferred.resolve(resp);
                            }
                            else if (resp.status === 204) {
                                var time = internalTries.shift();
                                if (time) {
                                    util.Debug.log("Wait " + time + "ms for getting booking response id: " + requestId);
                                    $timeout(internalGet, time);
                                } else
                                    deferred.reject(resp);
                            }
                            else
                                deferred.reject(resp);
                            return resp;
                        },
                        function fail(resp) {
                            deferred.reject(resp);
                            return resp;
                        });
                };
                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                swatch.start();
                internalGet();
                return promise;
            };
            this.processBooking = function (bookingRequest) {
                var deferred = $q.defer();
                deferred.notify(bookingRequest);

                self.createBooking(bookingRequest).success(function (data) {
                    util.Debug.log("Booking Created = " + data);
                    self.getBookingResponse(data).success(function (_data, _status, _headers, _config) {
                        deferred.resolve({ data: _data, status: _status, headers: _headers, config: _config });
                    }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });
                }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });

                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                return promise;
            };

            //BankCasino
            this.getBankCasinoSchedule = function (data) {
                return client.post(controllers.BankCasinoController, null, data, "ttype=10");
            };
            this.getBankCasinoScoring = function (data) {
                return client.post(controllers.BankCasinoController, null, data, "ttype=11");
            };

            //Branded Fares
            this.getBrandedFares = function (data) {
                return client.post(controllers.BrandedFaresController, null, data);
            };

            //DiscountCode
            this.getDiscountCodeCheck = function (data) {
                return client.post(controllers.DiscountController, null, data);
            };

            //Payment
            this.createPayment = function (data) {
                return client.post(controllers.PaymentController, null, data);
            };
            this.getPayment = function (requestId) {
                var deferred = $q.defer();
                deferred.notify(requestId);

                var swatch = util.StopWatch.createNew();

                var internalTries = [100, 100, 100, 1000, 1000, 1000, 1000, 1000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000];
                var internalGet = function () {
                    util.Debug.log("Getting payment response id: " + requestId);
                    client.get(controllers.PaymentController, requestId).then(
                        function success(resp) {
                            if (resp.status === 200) {
                                swatch.stop();
                                util.Debug.log("Total payment response time: " + swatch.getElapsed() + "ms");
                                deferred.resolve(resp);
                            }
                            else if (resp.status === 204) {
                                var time = internalTries.shift();
                                if (time) {
                                    util.Debug.log("Wait " + time + "ms for getting payment response id: " + requestId);
                                    $timeout(internalGet, time);
                                } else
                                    deferred.reject(resp);
                            }
                            else
                                deferred.reject(resp);
                            return resp;
                        },
                        function fail(resp) {
                            deferred.reject(resp);
                            return resp;
                        });
                };
                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                swatch.start();
                internalGet();
                return promise;
            };
            this.processPayment = function (paymentRequest) {
                var deferred = $q.defer();
                deferred.notify(paymentRequest);

                self.createPayment(paymentRequest).success(function (data) {
                    util.Debug.log("Payment Created = " + data);
                    self.getPayment(data).success(function (_data, _status, _headers, _config) {
                        deferred.resolve({ data: _data, status: _status, headers: _headers, config: _config });
                    }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });
                }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });

                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                return promise;
            };

            //Payment 3DS
            this.createPayment3DS = function (data) {
                return client.post(controllers.Payment3DSController, null, data);
            };
            this.getPayment3DS = function (requestId) {
                var deferred = $q.defer();
                deferred.notify(requestId);

                var swatch = util.StopWatch.createNew();

                // esperamos 4.05 minutos
                var internalTries = [100, 100, 100, 1000, 1000, 1000, 1000, 1000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000];
                var internalGet = function () {
                    util.Debug.log("Getting payment paybox response id: " + requestId);
                    client.get(controllers.Payment3DSController, requestId).then(
                        function success(resp) {
                            if (resp.status === 200) {
                                swatch.stop();
                                util.Debug.log("Total payment paybox response time: " + swatch.getElapsed() + "ms");
                                deferred.resolve(resp);
                            }
                            else if (resp.status === 204) {
                                var time = internalTries.shift();
                                if (time) {
                                    util.Debug.log("Wait " + time + "ms for getting payment paybox response id: " + requestId);
                                    $timeout(internalGet, time);
                                } else
                                    deferred.reject(resp);
                            }
                            else
                                deferred.reject(resp);
                            return resp;
                        },
                        function fail(resp) {
                            deferred.reject(resp);
                            return resp;
                        });
                };
                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                swatch.start();
                internalGet();
                return promise;
            };
            this.processPayment3DS = function (paymentRequest) {
                var deferred = $q.defer();
                deferred.notify(paymentRequest);

                self.createPayment3DS(paymentRequest).success(function (data) {
                    util.Debug.log("Payment Paybox Created = " + data);
                    self.getPayment3DS(data).success(function (_data, _status, _headers, _config) {
                        deferred.resolve({ data: _data, status: _status, headers: _headers, config: _config });
                    }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });
                }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });

                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                return promise;
            };



            //SimplePayment
            this.createSimplePayment = function (data) {
                return client.post(controllers.SimplePaymentController, null, data);
            };
            this.getSimplePayment = function (requestId) {
                var deferred = $q.defer();
                deferred.notify(requestId);

                var swatch = util.StopWatch.createNew();

                var internalTries = [100, 100, 100, 1000, 1000, 1000, 1000, 1000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000];
                var internalGet = function () {
                    util.Debug.log("Getting simple payment response id: " + requestId);
                    client.get(controllers.SimplePaymentController, requestId).then(
                        function success(resp) {
                            if (resp.status === 200) {
                                swatch.stop();
                                util.Debug.log("Total simple payment response time: " + swatch.getElapsed() + "ms");
                                deferred.resolve(resp);
                            }
                            else if (resp.status === 204) {
                                var time = internalTries.shift();
                                if (time) {
                                    util.Debug.log("Wait " + time + "ms for getting simple payment response id: " + requestId);
                                    $timeout(internalGet, time);
                                } else
                                    deferred.reject(resp);
                            }
                            else
                                deferred.reject(resp);
                            return resp;
                        },
                        function fail(resp) {
                            deferred.reject(resp);
                            return resp;
                        });
                };
                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                swatch.start();
                internalGet();
                return promise;
            };
            this.processSimplePayment = function (paymentRequest) {
                var deferred = $q.defer();
                deferred.notify(paymentRequest);

                self.createSimplePayment(paymentRequest).success(function (data) {
                    util.Debug.log("Payment Created = " + data);
                    self.getSimplePayment(data).success(function (_data, _status, _headers, _config) {
                        deferred.resolve({ data: _data, status: _status, headers: _headers, config: _config });
                    }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });
                }).error(function (_data, _status, _headers, _config) { deferred.reject({ data: _data, status: _status, headers: _headers, config: _config }); });

                var promise = deferred.promise;
                promise.success = function (fn) { promise['then'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                promise.error = function (fn) { promise['catch'](function (response) { fn(response.data, response.status, response.headers, response.config); }); return promise; };
                return promise;
            };

            //PayPal Token
            this.getPayPalToken = function (paypalRQ) {
                return client.post(controllers.PayPalTokenController, null, paypalRQ);
            }

            //Payment Methods
            this.getPaymentMethods = function (bookingRQ) {
                return client.post(controllers.PaymentMethodsController, null, bookingRQ);
            }

            this.getSimplePaymentMethods = function (simplePaymentMethodsRq) {
                return client.post(controllers.SimplePaymentMethodsController, null, simplePaymentMethodsRq);
            }
            //


            this.sendMail = function (mailRQ) {
                return client.post(controllers.SendEmail, null, mailRQ);
            };

            //OPTIONS
            this.getOptionValue = function (bookingId) {
                return client.get(controllers.OptionsController, bookingId);
            };
            this.postOptionValue = function (option) {
                return client.post(controllers.OptionsController, null, option);
            };

            //AIR
            this.AIR = {};
            this.AIR.PartnerCommission = {};
            this.AIR.PartnerCommission.getByEnterpriseId = function (enterpriseId) {
                return client.get(controllers.AIR.PartnerCommission, null, "enterpriseId=" + enterpriseId);
            };
            this.AIR.PartnerCommission.getByCommissionId = function (commissionId) {
                return client.get(controllers.AIR.PartnerCommission, null, "commissionId=" + commissionId);
            };
            this.AIR.PartnerCommissionRange = {};
            this.AIR.PartnerCommissionRange.getByCommissionId = function (commissionId) {
                return client.get(controllers.AIR.PartnerCommissionRange, null, "commissionId=" + commissionId);
            };
            this.AIR.PartnerCommissionRange.getByCommissionRangeId = function (commissionRangeId) {
                return client.get(controllers.AIR.PartnerCommissionRange, null, "commissionRangeId=" + commissionRangeId);
            };
            this.AIR.PartnerCommissionRange.add = function (commissionRange) {
                return client.post(controllers.AIR.PartnerCommissionRange, null, commissionRange);
            };
            this.AIR.PartnerCommissionRange.update = function (commissionRange) {
                return client.put(controllers.AIR.PartnerCommissionRange, null, commissionRange);
            };
            this.AIR.PartnerCommissionRange['delete'] = function (commissionRangeId) {
                return client['delete'](controllers.AIR.PartnerCommissionRange, null, "commissionRangeId=" + commissionRangeId);
            };
            this.AIR.getBookingList = function (searchRQ) {
                return client.post(controllers.AIR.BookingSearch, null, searchRQ);
            };
            this.AIR.getBookingList2 = function (searchRQ) {
                return client.post(controllers.AIR.BookingSearch2, null, searchRQ);
            };
            this.AIR.getBookingSearch = function (searchRQ) {
                return client.post(controllers.AIR.BkgBookingSearch, null, searchRQ);
            };
            this.AIR.getBookingListExportLink = function (searchRQ, forceDownload) {
                var actionUrl = url + "../download/searchexcel?";
                actionUrl = util.Url.appendValueToUrl(actionUrl, "AuthToken", client.authToken);
                actionUrl = util.Url.appendValueToUrl(actionUrl, "MappingName", client.mappingName);
                if (client.loginId)
                    actionUrl = util.Url.appendValueToUrl(actionUrl, "lid", client.loginId);

                if (searchRQ) {
                    for (var prop in searchRQ) {
                        if (searchRQ[prop])
                            actionUrl = util.Url.appendValueToUrl(actionUrl, prop, searchRQ[prop]);
                    }
                }
                return actionUrl + ((forceDownload == true) ? "&download=1" : "");
            };
            this.AIR.getBookingDetails = function (searchRQ) {
                query = "?";
                if (util.Location.getQueryStringVar("account"))
                    query = util.Url.appendValueToUrl(query, "account", util.Location.getQueryStringVar("account"));
                if (util.Location.getQueryStringVar("partnerReference"))
                    query = util.Url.appendValueToUrl(query, "partnerReference", util.Location.getQueryStringVar("partnerReference"));
                if (util.Location.getQueryStringVar("accountNumber"))
                    query = util.Url.appendValueToUrl(query, "accountNumber", util.Location.getQueryStringVar("accountNumber"));

                return client.post(controllers.AIR.BookingDetails, null, searchRQ, query);
            };
            this.AIR.getBookingId = function (reference, account) {
                return client.get(controllers.AIR.BookingSearch, null, "reference=" + reference + "&account=" + account);
            };
            this.AIR.getInvoicesList = function (bookingId) {
                return client.get(controllers.AIR.BookingInvoices, bookingId);
            };
            this.AIR.getInvoiceDownloadLink = function (invoiceId, forceDownload,bookingNumber) {
                var invoiceURL = url + "../download/invoices?iid=" + invoiceId + "&lid=" + client.loginId + "&bookingNumber=" + bookingNumber;
                return invoiceURL + ((forceDownload == true) ? "&download=1" : "");
            };
            this.AIR.getETicketsList = function (bookingId) {
                return client.get(controllers.AIR.BookingETickets, bookingId);
            };
            this.AIR.getETicketDownloadLink = function (eticketId, forceDownload, bookingNumber) {
                var eticketURL = url + "../download/etickets?iid=" + eticketId + "&lid=" + client.loginId + "&bookingNumber="+bookingNumber;
                return eticketURL + ((forceDownload == true) ? "&download=1" : "");
            };
            this.AIR.getPricePredictions = function (selection) {
                if (selection) {
                    var oneWay = selection.inbound ? false : true;
                    var proposal = oneWay ? selection.outbound.proposal : selection.inbound.proposal;
                    var rqPrediction = { price: proposal.totalFare.value, itineraries: [] };
                    rqPrediction.itineraries.push({
                        origin: selection.outbound.segments.first().departure.airport,
                        destination: selection.outbound.segments.last().arrival.airport,
                        airline: selection.outbound.marketingCarrier,
                        stops: selection.outbound.segments.length - 1,
                        date: selection.outbound.departureDate
                    });
                    if (!oneWay) {
                        rqPrediction.itineraries.push({
                            origin: selection.inbound.segments.first().departure.airport,
                            destination: selection.inbound.segments.last().arrival.airport,
                            airline: selection.inbound.marketingCarrier,
                            stops: selection.inbound.segments.length - 1,
                            date: selection.inbound.departureDate
                        });
                    }
                    return client.post(controllers.AIR.AirPricePredictions, null, rqPrediction);
                }
                return null;
            };

            //CPT
            this.B2B = {};
            //
            this.B2B.login = function (accountNumber, account, password) {
                return client.post(controllers.B2B.B2BLogin, null, {
                    "accountNumber": accountNumber,
                    "account": account,
                    "password": password
                });
            };
            this.B2B.getLoginInfo = function () {
                return client.get(controllers.B2B.B2BLogin);
            };
            this.B2B.getAccountInfo = function () {
                return client.get(controllers.B2B.B2BAccount);
            };
            this.B2B.getAgents = function () {
                return client.get(controllers.B2B.B2BAgents, "all");
            };
            this.B2B.getAgentsList = function () {
                return client.get(controllers.B2B.B2BAgents, "list");
            };
            this.B2B.createAgent = function (agent) {
                return client.post(controllers.B2B.B2BAgents, null, agent);
            };
            this.B2B.updateAgent = function (agent) {
                return client.put(controllers.B2B.B2BAgents, null, agent);
            };
            this.B2B.enableAgent = function (agent) {
                return client.put(controllers.B2B.B2BAgents, null, agent, "activate=1");
            };
            this.B2B.disableAgent = function (agent) {
                return client.put(controllers.B2B.B2BAgents, null, agent, "deactivate=1");
            };
            this.B2B.getClientMargin = function (toRange) {
                if (toRange)
                    return client.get(controllers.B2B.B2BClientMargin, null, "toRange=" + toRange);
                else
                    return client.get(controllers.B2B.B2BClientMargin);
            };
            this.B2B.addClientMargin = function (clientMargin) {
                return client.post(controllers.B2B.B2BClientMargin, null, clientMargin);
            };
            this.B2B.updateClientMargin = function (clientMargin) {
                return client.put(controllers.B2B.B2BClientMargin, null, clientMargin);
            };
            this.B2B.deleteClientMargin = function (toRange) {
                return client['delete'](controllers.B2B.B2BClientMargin, null, "toRange=" + toRange);
            };
            this.B2B.sendRequests = function (request) {
                return client.post(controllers.B2B.B2BRequests, null, request);
            };

            this.B2B.getFareNotes = function (request) {
                return client.post(controllers.B2B.B2BFareNotes, null, request);
            };
            this.B2B.getNetWorkMargin = function (obj) {
                if (obj.toRange)
                    return client.get(controllers.B2B.B2BNetworkMargin, null, "enterpriseId=" + obj.enterpriseId + "&networkCodeId=" + obj.networkCodeId + "&toRange=" + obj.toRange);
                else
                    return client.get(controllers.B2B.B2BNetworkMargin, null, "enterpriseId=" + obj.enterpriseId + "&networkCodeId=" + obj.networkCodeId);
            };


            //**
            this.B2B.getClientMarginHotel = function () {
                return client.get(controllers.B2B.B2BClientMarginHotel);
            };
            this.B2B.getClientMarginDpk = function () {
                return client.get(controllers.B2B.B2BClientMarginDpk);
            };
            this.B2B.getClientMarginCar = function () {
                return client.get(controllers.B2B.B2BClientMarginCar);
            };
            this.B2B.getClientMarginTransfert = function () {
                return client.get(controllers.B2B.B2BClientMarginTransfert);
            };
            this.B2B.getClientMarginVacationRental = function () {
                return client.get(controllers.B2B.B2BClientMarginVacationRental);
            };
            this.B2B.updateClientMarginHotel = function (margin) {
                return client.post(controllers.B2B.B2BClientMarginHotel, null, margin);
            };
            this.B2B.updateClientMarginDpk = function (margin) {
                return client.post(controllers.B2B.B2BClientMarginDpk, null, margin);
            };
            this.B2B.updateClientMarginCar = function (margin) {    
                return client.post(controllers.B2B.B2BClientMarginCar, null, margin);
            };
            this.B2B.updateClientMarginTransfert = function (margin) {
                return client.post(controllers.B2B.B2BClientMarginTransfert, null, margin);
            };
            this.B2B.updateClientMarginLocation = function (margin) {
                return client.post(controllers.B2B.B2BClientMarginVacationRental, null, margin);
            };
            this.B2B.deleteClientMarginDpk = function (toRange) {
                return client['delete'](controllers.B2B.B2BClientMarginDpk, null);
            };
            this.B2B.deleteClientMarginHotel = function (toRange) {
                return client['delete'](controllers.B2B.B2BClientMarginHotel, null);
            };
            this.B2B.deleteClientMarginCar = function (toRange) {
                return client['delete'](controllers.B2B.B2BClientMarginCar, null);
            };

            //PURCHASE
            this.PURCHASE = {};
            this.PURCHASE.new = function (newPurchaseRQ) {
                return client.post(controllers.PURCHASE.New, null, newPurchaseRQ);
            };

            this.PURCHASE.unAccompaniedMinor = function (unAccompaniedMinor) {
                return client.post(controllers.PURCHASE.UnAccompaniedMinor, null, unAccompaniedMinor);
            };

            this.PURCHASE.freezeInfo = function (freezeInfo) {
                return client.post(controllers.PURCHASE.FreezeInfo, null, freezeInfo);
            };

            this.PURCHASE.seatMap = function (seatMapRQ) {
                return client.post(controllers.PURCHASE.SeatMap, null, seatMapRQ);
            };

            this.PURCHASE.calculateInsurance = function (insuranceCalculationRQ) {
                return client.post(controllers.PURCHASE.Insurance, null, insuranceCalculationRQ);
            };
            this.PURCHASE.checkDiscount = function (checkDiscountRQ) {

                return client.post(controllers.PURCHASE.Discount, null, checkDiscountRQ);

            };
            this.PURCHASE.createBooking = function (bookingRQ) {
                return client.post(controllers.PURCHASE.CreateBooking, null, bookingRQ);
            };            
            this.PURCHASE.paymentMethods = function (paymentMethodsRQ) {
                return client.post(controllers.PURCHASE.PaymentMethods, null, paymentMethodsRQ);
            };
            this.PURCHASE.casinoScoring = function (casinoScoringRQ) {
                return client.post(controllers.PURCHASE.CasinoScoring, null, casinoScoringRQ);
            };

            //ACCOMODATION
            this.ACCOMMODATION = {};
            this.ACCOMMODATION.add = function (addAccommodationRQ) {
                return client.post(controllers.ACCOMMODATION.Add, null, addAccommodationRQ);
            };
            this.ACCOMMODATION.get = function (getAccommodationRQ) {
                return client.post(controllers.ACCOMMODATION.Get, null, getAccommodationRQ);
            };
            this.ACCOMMODATION.getPurchase = function (getPurchaseRQ) {
                return client.post(controllers.ACCOMMODATION.GetPurchase, null, getPurchaseRQ);
            };

        };

        return new apiClient();
    };
}]);
