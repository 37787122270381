webClient.directive('ngFlightAlertsRoundTrip', ['$window', '$i18next', 'templateService',
    function ($window, $i18next, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        bookingdata: "=",
        fullalertcabinmessage: "=",
        hidealertmessageto: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_FlightAlertsRoundTripTPL.html');

        //Initialize Variables
        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.isProductTO = $scope.siteType === 2 && $scope.bookingdata.purchaseResponse.isProductTo;
        $scope.isProductKiwi = $scope.bookingdata.purchaseResponse.kiwi;
        var flightTypeIsRoundTrip = $scope.bookingdata.purchaseResponse.tripType === 2;
        var flightTypeIsOneWay = $scope.bookingdata.purchaseResponse.tripType === 1;
        $scope.adultFare = $scope.bookingdata.purchaseResponse.proposalsRequested.fares.first(function (x) { return x.paxType == 0 });
        $scope.auxItineraries = $window.objectClone($scope.bookingdata.purchaseResponse.itineraries);
        $scope.auxItineraries = $scope.auxItineraries.groupByKey("key");

        // List the iatas of all segments, contains duplicated values but does not impact performance
        $scope.iatasList = $scope.bookingdata.purchaseResponse.itineraries.flatMap(it => it.segments).map(s => s.airline.iata.toLowerCase());
        // Filter in the companies with alert Cabin Luggages that should be displayed 
        $scope.companiesAlertsCabinLuggage = $window.companiesWithAlertCabinLuggages.filter(c => $scope.iatasList.contains(c.Iata.toLowerCase()));
        // Reduce to one dimension array, easier to search on. 
        $scope.companiesAlertsCabinLuggageIatasOnly = [];
        $scope.companiesAlertsCabinLuggage.each(function (elem) {
            $scope.companiesAlertsCabinLuggageIatasOnly.push(elem.Iata);
        });

        $scope.getWarningsFlights = function () {
            $scope.technicalStops = [];
            $scope.longStops = [];
            $scope.segmentsInTrain = [];
            $scope.segmentsInBus = [];
            $scope.listCabinClassDown = [];
            $scope.listCabinClassUp = [];
            $scope.itinerariesDifferentCabinClass = [];
            $scope.listItineraryCabinClass = {};
            $scope.airportsThatChangeDuringTransit = [];
            $scope.segmentsCabinLuggage = [];
            $scope.containsTransitInUs = false;
            $scope.destinationIsChina = false;
            $scope.dayChange = ($scope.bookingdata.purchaseResponse.itineraries.any(function (it) { return it.dayChange > 0 }));
            $scope.ryanair = false;
            $scope.norwegian = false;

            if (flightTypeIsRoundTrip) {

                //Different Departure Arrival Airport on Origin City
                if ($scope.bookingdata.purchaseResponse.itineraries[0] && $scope.bookingdata.purchaseResponse.itineraries[1]) {
                    $scope.isSameOutboundOriginInboundDestination = $scope.bookingdata.purchaseResponse.itineraries[0].origin.cityIata === $scope.bookingdata.purchaseResponse.itineraries[1].destination.cityIata;
                    $scope.isSameOutboundDestinationInboundOrigin = $scope.bookingdata.purchaseResponse.itineraries[0].destination.cityIata === $scope.bookingdata.purchaseResponse.itineraries[1].origin.cityIata;

                    //Different Departure Arrival Airport on Origin City Example: Salir de ORY y regresar a CDG
                    $scope.diffDepartureArrivalAirportOrigin = (($scope.isSameOutboundOriginInboundDestination) && ($scope.bookingdata.purchaseResponse.itineraries[0].origin.airportIata != $scope.bookingdata.purchaseResponse.itineraries[1].destination.airportIata));

                    //Different Departure Arrival Airport on DestinationCity 
                    $scope.diffDepartureArrivalAirportDestination = $scope.isSameOutboundDestinationInboundOrigin && $scope.bookingdata.purchaseResponse.itineraries[0].destination.airportIata != $scope.bookingdata.purchaseResponse.itineraries[1].origin.airportIata;
                }

                //City proposed is different to city requested on destination
                if ($scope.bookingdata.purchaseResponse.itineraries[0].destination.cityIata)
                    $scope.cityProposedDiffCityRequested = ($scope.bookingdata.request.destination.cityIATA != $scope.bookingdata.purchaseResponse.itineraries[0].destination.cityIata);

                //City proposed is different to city requested on origin
                if ($scope.bookingdata.purchaseResponse.itineraries[0].origin.cityIata)
                    $scope.cityProposedDiffCityRequestedOrigin = ($scope.bookingdata.request.origin.cityIATA != $scope.bookingdata.purchaseResponse.itineraries[0].origin.cityIata);
            }

            if (flightTypeIsOneWay) {
                if ($scope.bookingdata.purchaseResponse.itineraries[0].origin.cityIata)
                    $scope.cityProposedDiffCityRequestedOriginFlightOneWay = ($scope.bookingdata.request.origin.cityIATA != $scope.bookingdata.purchaseResponse.itineraries[0].origin.cityIata);

                if ($scope.bookingdata.purchaseResponse.itineraries[0].destination.cityIata)
                    $scope.cityProposedDiffCityRequestedFlightOneWay = ($scope.bookingdata.request.destination.cityIATA != $scope.bookingdata.purchaseResponse.itineraries[0].destination.cityIata);
            }

            var segmentsList = [];
            $scope.bookingdata.purchaseResponse.itineraries.each(function (it, index) {
                it.segments.each(function (itSegment) {

                    // if(itSegment.arrival.countryName === "États-Unis"){
                    //     $scope.containsTransitInUs = true;
                    // }
                    segmentsList.push(itSegment.arrival.cityIata); //Transit time greater than 6 hours

                    //Transit time greater than 6 hours
                    if (itSegment.stopTime.hh >= 6 && itSegment.stopTime.mm >= 0) {
                        $scope.longStops.push({
                            arrival: itSegment.arrival.cityName,
                            stopTime: itSegment.stopTime,
                            originCityIata: it.origin.cityIata,
                            destinationCityIata: it.destination.cityIata,
                            itineraryIndex: index
                        });
                    }

                    //Technical Stop
                    if (itSegment.stops > 0) {
                        $scope.technicalStops.push({
                            departure: itSegment.departure.airportIata,
                            arrival: itSegment.arrival.airportIata,
                            originCityIata: it.origin.cityIata,
                            destinationCityIata: it.destination.cityIata,
                            itTotalSegments: it.segments.length
                        });
                    }

                    //Segment in train
                    if (itSegment.equipment.type === 3 && !$scope.bookingdata.purchaseResponse.productIsTrain) {
                        $scope.segmentsInTrain.push({
                            duration: { hh: itSegment.flightTime.hh, mm: itSegment.flightTime.mm },
                            cityNameDeparture: itSegment.departure.cityName,
                            cityNameArrival: itSegment.arrival.cityName
                        });
                    }

                    //Segment in bus
                    if (itSegment.equipment.type === 2) {
                        $scope.segmentsInBus.push({
                            duration: { hh: itSegment.flightTime.hh, mm: itSegment.flightTime.mm },
                            cityNameDeparture: itSegment.departure.cityName,
                            cityNameArrival: itSegment.arrival.cityName
                        });
                    };
                    //Segment with Cabin luggage to pay
                    if ((itSegment.airline.iata.toLowerCase() === "fr" || itSegment.airline.iata.toLowerCase() === "rk") && moment(itSegment.departureDateTime, "YYYY-MM-DD").isAfter('2018-11-01', 'day')) {
                        $scope.segmentsCabinLuggage.push({
                            airlineIata: itSegment.airline.iata,
                            airlineName: itSegment.airline.name
                        });
                        $scope.ryanair = true;
                    }

                    if ($scope.companiesAlertsCabinLuggageIatasOnly.contains(itSegment.airline.iata.toLowerCase())) {
                        $scope.segmentsCabinLuggage.push({
                            airlineIata: itSegment.airline.iata,
                            airlineName: itSegment.airline.name
                        });
                    }

                    var proposals = $scope.bookingdata.purchaseResponse.proposals;
                    var baggageQuantity = 0;

                    try {
                        baggageQuantity = proposals[0].fares[0].itineraries[index].baggageQuantity;
                    }
                    catch(e) {
                        baggageQuantity = 0;
                    }

                    //Segment with Cabin luggage to pay
                    if (itSegment.airline.iata.toLowerCase() === "dy" && baggageQuantity == 0) {

                        $scope.segmentsCabinLuggage.push({
                            airlineIata: itSegment.airline.iata,
                            airlineName: itSegment.airline.name
                        });
                        $scope.norwegian = true;
                    }

                });
            });


            //if one of the departure or destination is in US we don't warn about the policy rules in the US

            if ($scope.bookingdata.request.origin && $scope.bookingdata.request.origin.countryISO === "US") {
                segmentsList = [];
            }
            if ($scope.bookingdata.request.origin2 && $scope.bookingdata.request.origin2.countryISO === "US") {
                segmentsList = [];
            }
            if ($scope.bookingdata.request.destination && $scope.bookingdata.request.destination.countryISO === "US") {
                segmentsList = [];
            }
            if ($scope.bookingdata.request.destination2 && $scope.bookingdata.request.destination2.countryISO === "US") {
                segmentsList = [];
            }

            if ($scope.bookingdata.request.destination && $scope.bookingdata.request.destination.countryISO === "CN") {
                $scope.destinationIsChina = true;
            }

            if (segmentsList.length > 0) {
                jQuery.ajax({
                    type: 'GET',
                    traditional: true,
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                    url: '/api/api/autocomplete/ContainsUsSegments',
                    data: { listCityIata: segmentsList },
                    success: function (result) {
                        $scope.containsTransitInUs = result;
                    }
                });

            }

            $scope.warnLongTransitTime = $scope.longStops.any();

            $scope.warnSegmentTrainOrBusOutbound = $scope.segmentsInTrain.any() || $scope.segmentsInBus.any();

            $scope.warnSegmentCabinLuggage = $scope.segmentsCabinLuggage.any();

            $scope.airportChangeDuringTheTransit = $scope.bookingdata.purchaseResponse.itineraries.each(
                function (it, itIndex) {
                    if (it.segments.length > 1) {
                        return it.segments.any(function (seg, i) {
                            if (i + 1 < it.segments.length) {
                                if (it.segments[i + 1] != undefined &&
                                    (it.segments[i + 1].departure.airportIata != seg.arrival.airportIata)) {
                                    $scope.airportsThatChangeDuringTransit.push({
                                        segmentArrivalCityName: seg.arrival.cityName,
                                        segmentArrivalAirportName: seg.arrival.airportName,
                                        nextSegmentDepartureAirportName: it.segments[i + 1].departure.airportName,
                                        originCityIata: it.origin.cityIata,
                                        destinationCityIata: it.destination.cityIata,
                                        itineraryTitle: itIndex === 0 ? $i18next.t('airbooking.dep') : itIndex === 1 ? $i18next.t('airbooking.ret') : ''
                                    });
                                    return true;
                                }
                            }
                        });
                    }
                });

            //Cabin Class alerts                          
            $scope.adultFare.itineraries.each(function (it, index) {
                $scope.listItineraryCabinClass[it.key] = { cabinClassSort: [] };
                it.segments.each(function (seg) {

                    if ($scope.listItineraryCabinClass[it.key]) {
                        $scope.listItineraryCabinClass[it.key].cabinClassSort.push(seg.cabinClass.sort);
                    }

                    if ($scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass]) {
                        if (seg.cabinClass.sort < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            $scope.listCabinClassDown.push({
                                itineraryOrigin: $scope.auxItineraries[it.key].origin.cityIata,
                                itineraryDestination: $scope.auxItineraries[it.key].destination.cityIata,
                                itineraryKey: it.key,
                                cabinClassName: seg.cabinClass.name,
                                itineraryIndex: index
                            });
                        }
                        else if (seg.cabinClass.sort > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            $scope.listCabinClassUp.push({
                                itineraryOrigin: $scope.auxItineraries[it.key].origin.cityIata,
                                itineraryDestination: $scope.auxItineraries[it.key].destination.cityIata,
                                itineraryKey: it.key,
                                cabinClassName: seg.cabinClass.name,
                                itineraryIndex: index
                            });
                        }
                    }
                });
            });
            if ($scope.isProductKiwi) {
                getBundleWidget("mrfly-widget-modal.js").done(function () {

                    $scope.showPopInSelfConnect = function () {
                        mrflyWidgets['reducModal'].$store.commit("showModal");
                    }

                    $.ajax({
                        url: '/api/api/kiwi/GetPageKiwi',
                        data: '',
                        dataType: 'json',
                        contentType: 'application/json; charset=utf-8',
                        type: 'GET',
                        async: false,
                        success: function (response) {
                            mrflyWidgets['reducModal'].$refs.modal.additionalData = response;
                            mrflyWidgets['reducModal'].$refs.modal.width = 850;
                            mrflyWidgets['reducModal'].$refs.modal.showHeader = true;
                        },
                        error: function () {

                        }
                    });

                }).fail(function (jqxhr, settings, exception) {
                    console.log(exception);
                });
            }

            $scope.cabinClassUpOrDown = $scope.listCabinClassDown.any() || $scope.listCabinClassUp.any();

            //Cabin class upgrade or downgrade total
            $scope.adultFare.itineraries.each(function (it, index) {
                var distinctCabinClass = $scope.listItineraryCabinClass[it.key].cabinClassSort.distinct();
                if (distinctCabinClass.length == 1 && $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass]) {
                    if (distinctCabinClass[0] > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                        $scope.listItineraryCabinClass[it.key].upgradeTotal = true;
                        $scope.itinerariesDifferentCabinClass.push({
                            itineraryOrigin: $scope.auxItineraries[it.key].origin.cityIata,
                            itineraryDestination: $scope.auxItineraries[it.key].destination.cityIata,
                            itineraryKey: it.key,
                            cabinClassName: it.segments[0].cabinClass.name,
                            itineraryIndex: index,
                            up: true
                        });
                    }

                    if (distinctCabinClass[0] < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                        $scope.listItineraryCabinClass[it.key].downgradeTotal = true;
                        $scope.itinerariesDifferentCabinClass.push({
                            itineraryOrigin: $scope.auxItineraries[it.key].origin.cityIata,
                            itineraryDestination: $scope.auxItineraries[it.key].destination.cityIata,
                            itineraryKey: it.key,
                            cabinClassName: it.segments[0].cabinClass.name,
                            itineraryIndex: index,
                            down: true
                        });
                    }

                }
            });

            return $scope.dayChange
                || $scope.longTransitTime
                || $scope.technicalStops.length > 0
                || $scope.airportsThatChangeDuringTransit.length > 0
                || $scope.warnSegmentTrainOrBusOutbound
                || $scope.warnSegmentCabinLuggage
                || $scope.diffDepartureArrivalAirportOrigin
                || $scope.diffDepartureArrivalAirportDestination
                || $scope.cityProposedDiffCityRequestedOriginFlightOneWay
                || $scope.cityProposedDiffCityRequestedFlightOneWay
                || $scope.cityProposedDiffCityRequested
                || $scope.cityProposedDiffCityRequestedOrigin
                || $scope.cabinClassUpOrDown
                || $scope.itinerariesDifferentCabinClass.length > 0;
        };

        $scope.hasWarningRoundTrip = $scope.getWarningsFlights();
    };
    return directive;
}]);
