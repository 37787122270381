webClient.controller('flightHotelSearchForm', ['$scope', '$i18next', '$interval', 'tseUtil', '$window', function ($scope, $i18next, $interval, tseUtil, $window) {
    $scope.show = false;
    $scope.maxTravellers = 9;
    $scope.rooms = [];
    $scope.hasError = false;
    $scope.errorMessage = "";
    $scope.informationMessage = "";
    $scope.histories = [];
    $scope.historyOpened = false;
    $scope.forcehidehistory = false;
    $scope.directFlight = false;
    $scope.differentFlightAndHotelDate = false;
    $scope.goFlightDate = "";
    $scope.returnFlightDate = "";
    $scope.nightNumbers = 0;
    $scope.hotelAutocompleteKey = "";
    $scope.isB2b = $window.site.type == 2;
    $scope.productTypeFilter = window.productTypeFilterFlightHotel ? window.productTypeFilterFlightHotel : 0;
    $scope.idPartner = window.site.partnerId;
    $scope.cssClassPartner = "";
    switch ($scope.idPartner) {
        case "carrefourdp":
            $scope.cssClassPartner = "carrefour-custom";
            break;
        case "dp-veepee"://remplace la valeur $scope.idPartner
            $scope.cssClassPartner = "veepee-custom";
            break;
    }
    $scope.disableDifferentFlightAndHotelDate = window.disableDifferentFlightAndHotelDate;
    this.saveHotelCityLabel = "";
    this.saveHotelCityKey = "";


    console.log("Hello from flightHotelSearchForm");
    //Ugly !!
    $scope.startWidget = function () {
        $i18next.i18n = i18next;
        $scope.show = true;
        $scope.$apply();
        $scope.forcehidehistory = false;
    
        $scope.histories = JSON.parse(window.site.basketFormHistory);
        $scope.histories.forEach(history => {
            history.rooms.forEach(room => {
                room.childrenAge.forEach(childAge => {
                    childAge.age = childAge.age.toString();
                });
            });
        });
        $scope.fillHistoryToForm(0);

    }

    $scope.clickOnButton = function () {
        $scope.checkErrors();

        //If nor error close popin
        if (!$scope.hasError) {
            setTimeout(function () {
                document.getElementById('i-search-flighthotel-who-pop').classList.remove("c-show");
            }, 100);
        }
    }

    $scope.intCalculateNightNumbers = function () {

        var goDateInput = "";
        var returnDateInput = "";

        if ($scope.differentFlightAndHotelDate == false) {
            goDateInput = angular.element('input[name="goDate"]').val();
            returnDateInput = angular.element('input[name="returnDate"]').val();
        } else {
            goDateInput = angular.element('input[name="goHotelDate"]').val();
            returnDateInput = angular.element('input[name="returnHotelDate"]').val();
        }

        if (goDateInput != null && returnDateInput != null) {
            var goDate = moment(goDateInput, "DD/MM/YYYY");
            var returnDate = moment(returnDateInput, "DD/MM/YYYY");

            return returnDate.diff(goDate, 'days');
        }

        return 0;
    }

    $scope.calculateNightNumbers = function (flightStartDate, flightEndDate, hotelStartDate, hotelEndDate) {
        var goDateInput = "";
        var returnDateInput = "";

        if (hotelStartDate == null || hotelStartDate == "") {
            goDateInput = flightStartDate;
            returnDateInput = flightEndDate;
        } else {
            goDateInput = hotelStartDate;
            returnDateInput = hotelEndDate;
        }
        if (goDateInput != null && returnDateInput != null) {
            var goDate = moment(goDateInput, "DD/MM/YYYY");
            var returnDate = moment(returnDateInput, "DD/MM/YYYY");

            return returnDate.diff(goDate, 'days');
        }
        return 0;
    }

    $scope.loadHistory = function () {
        console.log("Load history");

        var obj = tseUtil.Cookies.get("flightHotelHistory_" + window.site.partnerId);
        if (obj) {
            try {
                $scope.histories = JSON.parse(obj);
                console.log($scope.histories);
                console.log(JSON.parse(obj));
                console.log("Load history OK");
                return true;
            } catch (error) {
                tseUtil.Cookies.set("flightHotelHistory_" + window.site.partnerId, JSON.stringify([]));
                return false;
            }
        }
        console.log("Load history KO");

        return false;
    }


    $scope.onClickDirectFlight = function () {
        console.log("onClickDirectFlight $scope.directFlight -");
        console.log($scope.directFlight);

        $scope.directFlight = !$scope.directFlight;
        console.log("onClickDirectFlight $scope.directFlight -");
        console.log($scope.directFlight);

    }

    $scope.onFlightDateChanged = function () {
        console.log("date changed");
        if ($scope.differentFlightAndHotelDate == true) {
            this.saveHotelCityLabel = angular.element('input#i-where-destination-hotel').val();
            this.saveHotelCityKey = angular.element('input[name=destination-hotel]').val();
            console.log("save " + this.saveHotelCityLabel + "====" + this.saveHotelCityKey);
        }
        $scope.differentFlightAndHotelDate = false;
    }

    $scope.updateHotelDateWithFlightDate = function (resetHotelDate) {
        var goFlightDate = angular.element('input[name="goDate"]').val();

        var returnFlightDate = angular.element('input[name="returnDate"]').val();
        returnFlightDateInCalendar = moment(returnFlightDate, "DD/MM/YYYY").add(1, 'days').format('DD/MM/YYYY');

        $scope.goHotelDateDateMin = goFlightDate;
        $scope.goHotelDateDateMax = returnFlightDateInCalendar;

        $scope.returnHotelDateDateMin = goFlightDate;
        $scope.returnHotelDateDateMax = returnFlightDateInCalendar;

        console.log("update hotel dates");
        if (resetHotelDate == true) {
            angular.element('input[name=goHotelDate]').val(goFlightDate);
            angular.element('input[name=returnHotelDate]').val(returnFlightDate);
        }
    }


    $scope.onClickDifferentFlightAndHotelDate = function () {
        $scope.differentFlightAndHotelDate = !$scope.differentFlightAndHotelDate;
        if ($scope.differentFlightAndHotelDate) {
            $scope.updateHotelDateWithFlightDate(true);
            //Restore hotel city
            console.log("restore " + this.saveHotelCityLabel + "====" + this.saveHotelCityKey);
            if ((this.saveHotelCityLabel != '') && (this.saveHotelCityKey != '')) {
                angular.element('input#i-where-destination-hotel').val(this.saveHotelCityLabel);
                angular.element('input[name=destination-hotel]').val(this.saveHotelCityKey);
            }

        } else {
            //Save hotel city
            this.saveHotelCityLabel = angular.element('input#i-where-destination-hotel').val();
            this.saveHotelCityKey = angular.element('input[name=destination-hotel]').val();
            console.log("save " + this.saveHotelCityLabel + "====" + this.saveHotelCityKey);
            angular.element('#i-field-error').remove();
            angular.element('input[name=goHotelDate]').val('');
            angular.element('input[name=returnHotelDate]').val('');
            angular.element('input[name=destination-hotel]').val('');
            angular.element('input#i-where-destination-hotel').val('')
            angular.element('input#i-where-destination-hotel').attr('placeholder', getT("homepage.searchform.whereyougo"))
        }
    }

    $scope.fillHistoryToForm = function (historyIdx) {
        var obj = $scope.histories[historyIdx];
        if (!obj)
            return;
        obj = JSON.parse(JSON.stringify(obj));

        if (obj && angular.element('input[name=origin]') && angular.element('input[name=destination]')) {
            console.log("fillHistoryToForm " + obj);
            console.log(obj);
            $scope.rooms = obj.rooms;
            $scope.directFlight = obj.directFlight;
            angular.element('input[name=origin]').val(obj.fromValue);
            angular.element('#i-where-origin').val(obj.fromPlaceHolder);
            angular.element('input[name=destination]').val(obj.toValue);
            angular.element('#i-where-destination').val(obj.toPlaceHolder);

            angular.element('input[name=goDate]').val(obj.goDate);
            angular.element('input[name=returnDate]').val(obj.returnDate);
            angular.element('input[name=cabinClass]').val(obj.cabinClass);
            $scope.updateCabinClassText(obj.cabinClass);
            //Add hotel filter
            if (obj.goHotelDate != null && obj.returnHotelDate != null && obj.fromHotelValue != null && obj.fromHotelPlaceHolder != null) {
                $scope.differentFlightAndHotelDate = true;
                angular.element('input[name=goHotelDate]').val(obj.goHotelDate);
                angular.element('input[name=returnHotelDate]').val(obj.returnHotelDate);
                angular.element('input[name=destination-hotel]').val(obj.fromHotelValue);
                angular.element('#i-where-destination-hotel').val(obj.fromHotelPlaceHolder);
                $scope.updateHotelDateWithFlightDate(false);
            }
            else {
                $scope.differentFlightAndHotelDate = false;
                angular.element('input[name=goHotelDate]').val(null);
                angular.element('input[name=returnHotelDate]').val(null);
                angular.element('input[name=destination-hotel]').val(null);
                angular.element('#i-where-destination-hotel').val(null);
            }
        }
    }

    $scope.updateCabinClassText = function (cabinClass) {

        try {
            console.log("updateCabinClassText");
            console.log(cabinClass);
            var text = document.querySelector("ul#cabinClassSelector>li[data-value='" + cabinClass.toUpperCase() + "']").getAttribute('data-text')
            angular.element("#cabinClass-text")[0].innerText = text;

        } catch (ex) {

        }
    }

    $scope.fillLastHistory = function () {
        return $scope.fillHistoryToForm(0);
    }

    $scope.onDisplay = function () {
        try {
            const origin = angular.element('input[name=origin]');
            const destination = angular.element('input[name=destination]');
            if ((origin && origin.length > 0 && origin.val().length === 0)
                && (destination && destination.length > 0 && destination.val().length === 0)) {
                $scope.fillLastHistory();
            }
        }
        catch (ex) {
            console.error(ex.message);
        }
    }

    $scope.saveToHistory = function () {
        console.log("$scope.directFlight");
        console.log($scope.directFlight);
        var obj = {};
        obj.rooms = $scope.rooms;
        obj.fromValue = angular.element('input[name=origin]').val();
        obj.fromPlaceHolder = angular.element('#i-where-origin').val();
        obj.toValue = angular.element('input[name=destination]').val();
        obj.toPlaceHolder = angular.element('#i-where-destination').val();
        obj.goDate = angular.element('input[name=goDate]').val();
        obj.returnDate = angular.element('input[name=returnDate]').val();
        obj.cabinClass = angular.element('input[name=cabinClass]').val();
        if ($scope.differentFlightAndHotelDate == true) {
            obj.goHotelDate = angular.element('input[name=goHotelDate]').val();
            obj.returnHotelDate = angular.element('input[name=returnHotelDate]').val();

            obj.fromHotelValue = angular.element('input[name=destination-hotel]').val();
            obj.fromHotelPlaceHolder = angular.element('#i-where-destination-hotel').val();
        } else {
            obj.goHotelDate = null;
            obj.returnHotelDate = null;
            obj.fromHotelValue = null;
            obj.fromHotelPlaceHolder = null;
        }

        obj.adtCount = $scope.flightADTNumber();
        obj.chdCount = $scope.flightCHDNumber() + $scope.flightINFNumber();
        obj.roomCount = $scope.rooms.length;
        obj.directFlight = $scope.directFlight;

        var history = angular.element('input[name=flighthotelformhistory]');
        history.val(JSON.stringify(obj));
        //$scope.histories.unshift(obj);
        //tseUtil.Cookies.set("flightHotelHistory_" + window.site.partnerId, JSON.stringify($scope.histories));
    }

    $scope.checkInformationMessage = function () {
        $scope.informationMessage = "";

        if ($scope.totalTravellers() >= $scope.maxTravellers) {
            $scope.informationMessage = "airbooking.maxpassengersreached";

        }
    }

    $scope.checkErrors = function () {

        var roomError = false;

        $scope.checkInformationMessage();

        $scope.rooms.forEach(room => {

            var childError = false;

            room.childrenAge.forEach(childAge => {
                childAge.hasError = !((childAge.age >= 0) && (childAge.age <= 17));
                if (childAge.hasError == true) {
                    childError = true;
                    errorMessage = "homepage.searchform.hotel.childageerror"
                }
            })

            room.hasError = childError;
            if (room.hasError == true) {
                room.errorMessage = errorMessage;
                roomError = true;
            }

        });

        $scope.hasError = roomError;

        return roomError;
    }

    $scope.getT = function (key) {
        var res = $i18next.t(key.toLowerCase());
        if (!res)
            return "";
        return res;
    };

    $scope.summaryDescription = function () {

        var adtNb = 0;
        var chdNb = 0;

        $scope.rooms.forEach(room => {
            adtNb = adtNb + room.adultNumber;


            room.childrenAge.forEach(childAge => {
                chdNb = chdNb + 1;
            })

        });

        return $scope.summaryDescriptionFromParam(adtNb, chdNb, $scope.rooms.length);
    }

    $scope.summaryDescriptionFromParam = function (adtCount, chdCount, roomCount) {
        var ret = "";

        //ret += adtCount + " ";
        //ret += (adtCount > 1) ? $scope.getT('widgets.common.adults').toLowerCase() : $scope.getT('widgets.common.adult').toLowerCase();

        //if (chdCount > 0) {
        //    ret += ", " + chdCount + " ";
        //    ret += (chdCount > 1) ? $scope.getT('widgets.common.children').toLowerCase() : $scope.getT('widgets.common.child').toLowerCase();
        //}

        var totalPax = adtCount + chdCount;

        ret += totalPax + " ";
        ret += (totalPax > 1) ? $scope.getT('widgets.common.persons').toLowerCase() : $scope.getT('widgets.common.person').toLowerCase();

        ret += ", " + roomCount + " ";
        if (roomCount > 0)
            ret += (roomCount > 1) ? $scope.getT('homepage.searchform.hotels.rooms').toLowerCase() : $scope.getT('homepage.searchform.hotels.room').toLowerCase();

        return ret;
    }

    $scope.historyDescriptionFromParam = function (history) {
        //historyDescriptionFromParam(history.adtCount, history.chdCount, history.roomCount, calculateNightNumbers(history.goDate, history.returnDate, history.goHotelDate, history.returnHotelDate))
        var ret = "";
        var nightLabel = "";
        var adtCount = history.adtCount;
        var chdCount = history.chdCount;
        var roomCount = history.roomCount;
        var nightNumber = $scope.calculateNightNumbers(history.goDate, history.returnDate, history.goHotelDate, history.returnHotelDate);
        var totalPax = adtCount + chdCount;
        if (nightNumber > 0) {
            nightLabel = (nightNumber > 1) ? $scope.getT('homepage.searchform.nights').toLowerCase() : $scope.getT('homepage.searchform.night').toLowerCase();
            // add night number 
        }

        ret += "<span class='c-marg-lm'>(" + history.goDate + "-" + history.returnDate;
        ret += (nightNumber > 0 && history.fromHotelPlaceHolder == null) ? (" - " + nightNumber + " " + nightLabel + ")</span>") : ")</span>";

        if (history.fromHotelPlaceHolder != null) {
            ret += "<span class='c-marg-lm'>" + $scope.getT('b2baccountreservation.hotelin') + " </span><span class='c-cap'>" + history.fromHotelPlaceHolder + "</span><span class='c-marg-lm'>(" + history.goHotelDate + " - " + history.returnHotelDate;
            ret += (nightNumber > 0) ? (" - " + nightNumber + " " + nightLabel + ")</span>") : ")</span>";
        }

        ret += "<span class='nowrap c-marg-lm'>" + totalPax + " ";
        ret += (totalPax > 1) ? $scope.getT('widgets.common.persons').toLowerCase() : $scope.getT('widgets.common.person').toLowerCase();
        ret += ",</span>";

        ret += " <span class='nowrap'>" + roomCount + " ";
        if (roomCount > 0)
            ret += (roomCount > 1) ? $scope.getT('homepage.searchform.hotels.rooms').toLowerCase() : $scope.getT('homepage.searchform.hotels.room').toLowerCase();
        ret += "</span>";

        return ret;
    }


    $scope.FormValidation = function () {

        if (XLFormValidFlighHotelSearch() == false)
            return false;

        if ($scope.checkErrors() == true) {
            FPop({ oField: document.getElementById('i-search-who-field'), iPop: 'i-search-flighthotel-who-pop', iLoft: 'i-search-who-field', vPClass: 'c-on', vPos: 'bc', vTopM: 10 });

            //Animate to error
            for (var i = 0; i < $scope.rooms.length; i++) {
                var room = $scope.rooms[i];
                if (room.hasError) {
                    $('#i-search-flighthotel-who-pop').animate({ scrollTop: 0 }, 00)
                    $('#i-search-flighthotel-who-pop').animate({ scrollTop: $('#room_' + i).offset().top - 470 }, 1000)
                    break;
                }
            }

            return false;
        }

        //Check HotelInfo
        if ($scope.differentFlightAndHotelDate == true) {
            //Check hotel value
            var hotelValue = angular.element('input[name=destination-hotel]');
            if (hotelValue.val() == "") {
                hotelValue.parent().addClass('c-field-ko');
                //Add message
                var errorObj = [];
                errorObj["oObj"] = document.getElementById("i-where-destination-hotel");
                errorObj["vErrorCode"] = "empty";
                FErrorShow(errorObj);
                return false;
            }

            //Check hotel start date
            var hotelStartDateValue = angular.element('input[name=goHotelDate]');
            if (hotelStartDateValue.val() == "") {
                hotelStartDateValue.parent().addClass('c-field-ko');
                //Add message
                var errorObj = [];
                errorObj["oObj"] = document.getElementsByName("goHotelDate")[0];
                errorObj["vErrorCode"] = "empty";
                FErrorShow(errorObj);
                return false;
            }

            //Check hotel end date
            var hotelStartDateValue = angular.element('input[name=returnHotelDate]');
            if (hotelStartDateValue.val() == "") {
                hotelStartDateValue.parent().addClass('c-field-ko');
                //Add message
                var errorObj = [];
                errorObj["oObj"] = document.getElementsByName("returnHotelDate")[0];
                errorObj["vErrorCode"] = "empty"; 
                FErrorShow(errorObj);
                return false;
            }
        }

        //Tracking for dp-veepee
        callTrackingFunction('click_complete_search')       

        $scope.saveJson();
        $scope.saveToHistory();
        $(".btn--search").each(function () {
            $(this).addClass("c-search-go");
        });
        $("#i-search-flighthotel").trigger('submit');

        return true;
    }

    $scope.saveJson = function () {
        console.log("JsonSummary");
        console.log($scope.JsonSummary());
        var summary = angular.element('input[name=flighthotelformsummary]');
        summary.val($scope.JsonSummary());
        //tseUtil.Cookies.set("FlightHotelFormSummary", $scope.JsonSummary());

        return true;
    }

    $scope.JsonSummary = function () {
        var json = new Object();

        json.rooms = [];

        $scope.rooms.forEach(room => {
            var roomIns = new Object();
            roomIns.adultNumber = room.adultNumber;
            roomIns.childrenAge = [];
            room.childrenAge.forEach(childAge => {
                roomIns.childrenAge.push(childAge.age)
            });

            json.rooms.push(roomIns);
        });

        //Add custom hotel search
        if ($scope.differentFlightAndHotelDate == true) {
            json.goHotelDate = angular.element('input[name=goHotelDate]').val();;
            json.returnHotelDate = angular.element('input[name=returnHotelDate]').val();
            json.fromHotelValueKey = angular.element('input[name=destination-hotel]').val();

            var keyArray = angular.element('input[name=destination-hotel]').val().split('|');

            json.fromHotelValue = keyArray[0] + '_' + keyArray[2];
            json.fromHotelPlaceHolder = angular.element('#i-where-destination-hotel').val();
        }

        return JSON.stringify(json);
    }

    $scope.totalTravellers = function () {
        var ret = 0;

        $scope.rooms.forEach(room => {
            ret = ret + room.adultNumber + room.childrenAge.length;
        });


        return ret;
    }

    $scope.flightADTNumber = function () {
        var ret = 0;

        $scope.rooms.forEach(room => {
            ret = ret + room.adultNumber;
            room.childrenAge.forEach(childAge => {
                if (childAge.age >= 12)
                    ret = ret + 1;
            })
        });

        return ret;
    };

    $scope.flightCHDNumber = function () {

        var ret = 0;

        $scope.rooms.forEach(room => {
            room.childrenAge.forEach(childAge => {
                if ((childAge.age >= 2) && (childAge.age <= 11))
                    ret = ret + 1;
            })
        });

        return ret;
    };

    $scope.flightINFNumber = function () {

        var ret = 0;

        $scope.rooms.forEach(room => {
            room.childrenAge.forEach(childAge => {
                if (childAge.age < 2)
                    ret = ret + 1;
            })
        });

        return ret;
    };

    $scope.AddRoom = function () {
        console.log("Add Room");

        if (!$scope.CanAddRoom()) return false;

        $scope.rooms.push(
            {
                "adultNumber": 1,
                "childrenAge": [],
            });

        //$scope.checkErrors();
        return true;
    }

    $scope.CanAddRoom = function () {
        if ($scope.totalTravellers() >= $scope.maxTravellers)
            return false;

        if ($scope.rooms.length >= 5)
            return false;

        return true;
    }



    $scope.DelRoom = function () {
        console.log("Del Room");
        if (!$scope.CanDelRoom()) return false;

        $scope.rooms.pop();

        //$scope.checkErrors();
        return true;
    }

    $scope.CanDelRoom = function () {
        if ($scope.rooms.length <= 1)
            return false;

        return true;
    }

    $scope.AddAdult = function (roomId) {

        if (!$scope.CanAddAdult(roomId)) return false;

        $scope.rooms[roomId].adultNumber = $scope.rooms[roomId].adultNumber + 1;

        //$scope.checkErrors();
        return true;
    };

    $scope.CanAddAdult = function (roomId) {
        if ($scope.rooms[roomId].adultNumber >= 9)
            return false;

        if ($scope.totalTravellers() >= $scope.maxTravellers)
            return false;

        return true;
    }

    $scope.DelAdult = function (roomId) {

        if (!$scope.CanDelAdult(roomId)) return false;

        $scope.rooms[roomId].adultNumber = $scope.rooms[roomId].adultNumber - 1;

        //$scope.checkErrors();
        return true;
    };


    $scope.CanDelAdult = function (roomId) {

        if ($scope.rooms[roomId].adultNumber <= 1)
            return false;

        return true;
    }

    $scope.AddChild = function (roomId) {

        if (!$scope.CanAddChild(roomId)) return false;

        $scope.rooms[roomId].childrenAge.push({ "age": -1 });

        $scope.checkInformationMessage();
        return true;
    };

    $scope.CanAddChild = function (roomId) {
        if ($scope.rooms[roomId].childrenAge.length >= 5)
            return false;

        if ($scope.totalTravellers() >= $scope.maxTravellers)
            return false;

        return true;
    }

    $scope.DelChild = function (roomId) {

        if (!$scope.CanDelChild(roomId)) return false;

        $scope.rooms[roomId].childrenAge.pop()

        //$scope.checkErrors();
        return true;
    };

    $scope.CanDelChild = function (roomId) {
        if ($scope.rooms[roomId].childrenAge.length <= 0)
            return false;

        return true;
    }

    $scope.UpdateAge = function (roomId, childId, age) {

        $scope.rooms[roomId].childrenAge[childId].age = age;
        $scope.rooms[roomId].hasError = false;
        //$scope.checkErrors();
    }



    $scope.AddRoom();
    $scope.AddAdult(0);

    if ($scope.loadHistory()) {
        $scope.fillHistoryToForm(0);
    } else {
        $scope.forcehidehistory = true;
    }


    $interval(function () {
        $scope.nightNumbers = $scope.intCalculateNightNumbers();
    }, 1000);
}]);