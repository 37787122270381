webClient.directive('ngProductInfo', ['$window', '$timeout', '$i18next', 'templateService', 'trackingService',
    function ($window, $timeout, $i18next, templateService, trackingService) {
        var directive = {};
        directive.restrict = 'EA';
        directive.scope = {
            product: "=",
            segments: "=",
            summary: "=",
            airlines: "=",
            airports: "=",
            cities: "=",
            equipments: "=",
            cabinclasses: "=",
            responsesummary: "=",
            index: "=",
            minpriceproduct: "=",
            requestcabinclass: "=",
            showcovidflag: "=",
            isloadingco2: "=",
            baseprice: "=",
            purchaseid: "="
        };

        directive.link = function ($scope, $element) {

            templateService.compile($scope, $element, 'tpl/_ProductTPL.html');

            $scope.getUrl = $window.getUrl;
            $scope.siteType = $window.site.type;
            $scope.getTemplateUrl = $window.getTemplateUrl;
            $scope.getStaticFolder = $window.getStaticFolder;
            $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
            $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
            $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
            $scope.displayOptionMessage = $window.displayOptionMessage && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
            $scope.enableBestPrice = $window.enableBestPrice;
            $scope.enableFreeFlex = window.enableFreeFlex === true;
            $scope.isServair = window.site.partnerId === "servair";
            $scope.enableCO2 = window.enableCO2;

            $scope.product.outbound.isPreTrain = false;
            $scope.product.outbound.isPostTrain = false;

            if ($scope.baseprice) {

                var priceRelative = Number($scope.product.minTotalFareFeeMargin.value - parseFloat($scope.baseprice));
                var priceRelativeint = priceRelative.toFixed(2).split('.')[0];
                var priceRelativedec = priceRelative.toFixed(2).split('.')[1];
                $scope.product.baseRelativeFare = {
                    int: priceRelativeint,
                    dec: priceRelativedec,
                    intabs: Math.abs(parseInt(priceRelativeint)).toString(),
                    decabs: priceRelativedec,
                    value: priceRelative,
                    valueabs: Math.abs(priceRelative),
                    negative: (priceRelative < 0),
                };
                


                var priceAvg = Number(priceRelative / $scope.summary.totalPaxes);
                var priceAvgint = priceAvg.toFixed(2).split('.')[0];
                var priceAvgdec = priceAvg.toFixed(2).split('.')[1];

                $scope.product.baseAverageRelativeFare = {
                    int: priceAvgint,
                    dec: priceAvgdec,
                    intabs: Math.abs(parseInt(priceAvgint)).toString(),
                    decabs: priceAvgdec,
                    value: priceAvg,
                    valueabs: Math.abs(priceAvg),
                    negative: (priceAvg < 0),
                };


                //Update return price
                var i = 0;
                if ($scope.product.inbounds != null) {
                    $scope.product.inbounds.forEach(function (inbound) {
                        
                        if (inbound.proposal.supplementAverageFareFeeMargin.originalValue == undefined && inbound.proposal.supplementTotalFareFeeMargin.originalValue == undefined) {
                            inbound.proposal.supplementAverageFareFeeMargin.originalValue = inbound.proposal.supplementAverageFareFeeMargin.value;
                            inbound.proposal.supplementTotalFareFeeMargin.originalValue = inbound.proposal.supplementTotalFareFeeMargin.value;
                        }

                        //Restore *argin.value with original value for sort and filter
                        inbound.proposal.supplementAverageFareFeeMargin.value = inbound.proposal.supplementAverageFareFeeMargin.originalValue;
                        inbound.proposal.supplementTotalFareFeeMargin.value = inbound.proposal.supplementTotalFareFeeMargin.originalValue;


                        var diffAvgPrice = Number(parseFloat(inbound.proposal.supplementAverageFareFeeMargin.originalValue));
                        var diffAvgPriceint = diffAvgPrice.toFixed(2).split('.')[0];
                        var diffAvgPricedec = diffAvgPrice.toFixed(2).split('.')[1];
                        var diffPrice = Number(parseFloat(inbound.proposal.supplementTotalFareFeeMargin.originalValue));
                        var diffPriceint = diffPrice.toFixed(2).split('.')[0];
                        var diffPricedec = diffPrice.toFixed(2).split('.')[1];



                        inbound.proposal.supplementAverageFareFeeMargin.value = diffAvgPrice;
                        inbound.proposal.supplementAverageFareFeeMargin.int = diffAvgPriceint;
                        inbound.proposal.supplementAverageFareFeeMargin.dec = diffAvgPricedec;
                        inbound.proposal.supplementAverageFareFeeMargin.valueabs = Math.abs(diffAvgPrice)
                        
                        inbound.proposal.supplementTotalFareFeeMargin.value = diffPrice;
                        inbound.proposal.supplementTotalFareFeeMargin.int = diffPriceint;
                        inbound.proposal.supplementTotalFareFeeMargin.dec = diffPricedec;
                        i++;
                    });
                }
                
            }

            if ($scope.product.inbounds != null) {
                $scope.product.inbounds.forEach(function (inbound) {
                    inbound.proposal.supplementAverageFareFeeMargin.negative = (inbound.proposal.supplementAverageFareFeeMargin.value < 0);
                    inbound.proposal.supplementTotalFareFeeMargin.negative = (inbound.proposal.supplementTotalFareFeeMargin.value < 0);
                    inbound.proposal.supplementAverageFareFeeMargin.intabs = Math.abs(parseInt(inbound.proposal.supplementAverageFareFeeMargin.int)).toString();
                    inbound.proposal.supplementTotalFareFeeMargin.intabs = Math.abs(parseInt(inbound.proposal.supplementTotalFareFeeMargin.int)).toString();
                });
            }

            if (!$scope.product.outbound.isTrainOnly) {
                
                $scope.product.outbound.segments.forEach(function (segment) {
                    if (railHelper.IsTrainWithList(segment.equipment, Object.keys($scope.equipments).map((key) => $scope.equipments[key]))) {
                        if (segment.departure.airport == $scope.product.outbound.departure) {
                            $scope.product.outbound.isPreTrain = true;
                        }
                        if (segment.arrival.airport == $scope.product.outbound.arrival) {
                            $scope.product.outbound.isPostTrain = true;
                        }
                    }
                });
            }

            if ($scope.product.inbounds != null) {
                $scope.product.inbounds.forEach(function (inbound) {
                    inbound.isPreTrain = false;
                    inbound.isPostTrain = false;
                    if (!inbound.isTrainOnly) {
                        inbound.segments.forEach(function (segment) {
                            if (railHelper.IsTrainWithList(segment.equipment, Object.keys($scope.equipments).map((key) => $scope.equipments[key]))) {
                                if (segment.departure.airport == inbound.departure) {
                                    inbound.isPreTrain = true;
                                }
                                if (segment.arrival.airport == inbound.arrival) {
                                    inbound.isPostTrain = true;
                                }
                            }
                        });
                    }
                });
            }


            if ($window.isWonderBox) $scope.isWonderBox = $window.isWonderBox;
  
            $scope.listAyantDroitsPrice = null;
            if ($scope.isServair && $scope.product.minProposal.totalSubvention) {
                $scope.product.minProposal.subvention = {};
                $scope.product.minProposal.subvention.total = $scope.product.minProposal.totalSubvention;
                $scope.product.minProposal.subvention.int = $scope.product.minProposal.totalSubvention.toString().split('.')[0];
                $scope.product.minProposal.subvention.dec = $scope.product.minProposal.totalSubvention.toString().split('.')[1];
            }

            $scope.product.hasAnyCovidEligibleProduct = $scope.product.minProposal ? $scope.product.minProposal.covidEligibleProduct : false;

            //test if no bagage can be added on web
            if ($scope.product.inbounds != null && $scope.product.inbounds.length > 0) {//round-trip
                //if we find at least an inbound without included luggage, set a flag to raise an alert
                angular.forEach($scope.product.inbounds, function (inbound, key) {
                    if (inbound.baggage.include == false) {
                        $scope.product.noLuggageAlert = true;
                    }

                    if (inbound.proposal.covidEligibleProduct === true) {
                        $scope.product.hasAnyCovidEligibleProduct = true;
                    }
                });
                $scope.isKiwi = $scope.product.inbounds[0].proposal.kiwi;

                //if we find in inbounds proposal additionalbaggages with a key that has the same value as outbound key, we reset the flag to false
                if ($scope.product.noLuggageAlert == true) {
                    angular.forEach($scope.product.inbounds, function (inbound, key) {
                        angular.forEach(inbound.proposal.additionalBaggages, function (additionalBaggage, key) {
                            if (additionalBaggage.key == $scope.product.outbound.key) {
                                $scope.product.noLuggageAlert = false;
                            }
                        });
                    });
                }
            }
            else {//one-way
                if ($scope.product.outbound.proposal.additionalBaggages.length == 0) {
                    $scope.product.noLuggageAlert = true;
                }
                $scope.isKiwi = $scope.product.outbound.proposal.kiwi;
            }

            //if the flag is still set, we fill an alert text
            if ($scope.product.noLuggageAlert == true) {
                if ($scope.product.outbound.baggage.include == true && window.site.culture.toLowerCase() == "fr") $scope.product.noLuggageAlertText = "\n\r" + $i18next.t('airbooking.luggage.notonlineincluded');
                else if ($scope.product.outbound.baggage.include == true) $scope.product.noLuggageAlertText = "\n\r" + $i18next.t('airbooking.luggage.notonline1');
                else $scope.product.noLuggageAlertText = "\n\r" + $i18next.t('airbooking.luggage.notonline1') + "\n\r" + $i18next.t('airbooking.luggage.notonline2');
            }


            //Traduction
            $scope.getT = function (key) {
                var res = $i18next.t(key.toLowerCase());
                return res;
            };
            $scope.createTaxesDetailModal = function (key, paxType, paxFares, paxNumber, marketingCarriers) {
                $scope.$emit("displayTaxesDetailsClick", { key: key, paxType: paxType, paxFares: paxFares, paxNumber: paxNumber, marketingCarriers: marketingCarriers });
            };

            $scope.showPopInSelfConnect = function () {
                $scope.$emit("showPopInSelfConnect");
            }

            $scope.getText = function (key) {
                return window.culture.texts[key];
            };

            $scope.segmentIsTrain = function (equiment) {
                return railHelper.IsTrain(equiment);
            };
            $scope.inboundVisibleFilter = function (item) {
                return item.visible;
            };
            $scope.showRetour = false;
            $scope.showInbound = function () {

                if ($scope.product._lateInbounds === undefined) {
                    $scope.product._lateInbounds = $scope.product.inbounds;
                    $scope.showRetour = true;
                    $scope.showRetourLen = $scope.product.inbounds.filter(function (obj) { return obj.visible }).length;
                }
                else {
                    $scope.showRetour = !$scope.showRetour;
                }
            };
            $scope.ensureSegments = function (itinerary) {
                if (itinerary._lateSegments === undefined) {
                    itinerary._lateSegments = itinerary.segments;
                }
            };
            $scope.isReturnShowing = function () {
                return $scope.showRetour && $scope.product._lateInbounds !== undefined;
            };
            $scope.ffdet = function (idx, itinerary, _event) {
                $scope.ensureSegments(itinerary);
                $scope.showDetails = !$scope.showDetails;
                _event.stopPropagation();
                $timeout(function () {
                    $window.FFDet(idx);
                }, 0, false);
            };
            $scope.openDetailPriceServair = function (index, $event, tabToSwitch, product, productRetour) {
                $window.FLoading({ vAction: 'show' });

                var indexkey = (productRetour != null && productRetour.proposal && productRetour.proposal.index != null) ? productRetour.proposal.index : product.outbound.proposal ? product.outbound.proposal.index : null;
                var parentKey = $($event.currentTarget.closest(".c-flight")).attr("baggageKey");
                var inboundKey = "";
                var outboundKey = product.outbound.baggageKey;
                //on a selectionné le detail prix du premier vol aller
                if (parentKey === outboundKey) {
                    //dans ce cas il faut recuperer la clé du premier vol retour 
                    if (typeof index === 'undefined' && product.inbounds != null && product.inbounds.length > 0) {
                        indexkey = product.inbounds[0].proposal.index;
                        inboundKey = product.inbounds[0].baggageKey;
                    } else {
                        if (product.inbounds != null && product.inbounds.length > 0) {
                            indexkey = product.inbounds[index + 1].proposal.index;
                            inboundKey = product.inbounds[index + 1].baggageKey;
                        }
                    }
                }
                else {
                    //sinon c'est qu'on a selectionné le detail du vol retour
                    inboundKey = parentKey;
                }

                var uniqueKey = outboundKey + "_" + inboundKey + "_" + indexkey + "_" + $scope.summary.requestId;


                $window.Typescript.Servair.Services.ServairService.getTarifs(uniqueKey).then(function (result) {
                    var result = JSON.parse(result.data);
                    $scope.listAyantDroitsPrice = result;
                    $window.FLoading({ vAction: 'hide' });
                    FDTab($event.currentTarget, 2);
                    $scope.$apply();
                });
            };

            $scope.ffshowReturns = function (idx) {
                $("#i-smooth-back-" + idx + " .c-flight-back-wrapper").toggleClass("expanded");
                var fligtBackHeight = $("#i-flight-" + idx + " + div .c-flight-back-wrapper > div:last-child")[0].clientHeight;
                var fligtBackPickHeight = $("#i-flight-" + idx + " + div .c-flight-back-pick")[0].clientHeight + 4;
                var productsHeight = 0;
                for (var i = 0; i < 6; i++) {
                    productsHeight += $("#i-flight-" + idx + " + div .c-flight")[i].clientHeight + 4;
                    if (i === 5) productsHeight += $("#i-flight-" + idx + " + div .c-flight")[i + 1].clientHeight / 2 + 4;
                }
                if ($("#i-smooth-back-" + idx + " .c-flight-back-wrapper").hasClass("expanded")) $("#i-smooth-back-" + idx + " .c-flight-back-wrapper").height(fligtBackPickHeight + fligtBackHeight);
                else {
                    $("#i-smooth-back-" + idx + " .c-flight-back-wrapper").height(productsHeight - 4);
                    $('html, body').animate({
                        scrollTop: $("#i-flight-" + idx).offset().top
                    });
                }
                $("#i-smooth-back-" + idx + " .c-flight-back-plus .c-button-plus span").toggleText($i18next.t('airbooking.producttpl.showless'), $i18next.t('airbooking.producttpl.showmore'));
            };
            $.fn.toggleText = function (t1, t2) {
                if (this.text() == t1) this.text(t2);
                else this.text(t1);
                return this;
            };
            $scope.ffback = function (idx, _event) {

                //required to bind jQuery listener
                $(_event.target).trigger("selected");

                if ($scope.product.inbounds != null) {
                    $scope.showInbound();
                    _event.stopPropagation();
                    $timeout(function () {
                        $window.FFBack(idx);
                    }, 0, false);
                }
            };
            $scope.fsmoothopen = function (_oOpener, _iSmooth, _iToggle, _vToggleClass) {
                if (_oOpener.target)
                    _oOpener = _oOpener.target;
                var obj = { oOpener: _oOpener, iSmooth: _iSmooth };
                if (_iToggle) obj.iToggle = _iToggle;
                if (_vToggleClass) obj.vToggleClass = _vToggleClass;
                $window.FSmoothOpen(obj);
            };
            $scope.ffdetb = function (idx0, idx1, itinerary, _event) {
                $scope.ensureSegments(itinerary);
                $scope.showDetailsRetour = !$scope.showDetailsRetour;
                _event.stopPropagation();
                $timeout(function () {
                    $window.FFDetB(idx0 + "-" + idx1);
                }, 0, false);
            };

            //Seleccionar un producto
            $scope.onBookingClick = function (_product, _inbound, _event, _index) {

                if (document.getElementById("i-pop-nofee") && document.getElementById("i-pop-nofee").classList.contains("c-show")) {
                    document.getElementById("i-pop-nofee").classList.remove("c-show");
                }

                if (window.partnerName == "aeroports-voyages" && trackingService.isActivated() && _inbound == null && !$scope.showRetour) {
                    trackingService.neoDataLayer('addtocart_outbound', _product);
                }
                if (_inbound != null) {
                    if (window.partnerName == "aeroports-voyages" && trackingService.isActivated()) {
                        trackingService.neoDataLayer('addtocart_return', _inbound);
                    }

                    // Tracking for veepee, veepee-es and veepee-it
                    callTrackingFunction('addtocart_return', { "_outbound": _product.outbound, _inbound });
                }

                if ($scope.product.inbounds != null && _inbound == null)
                    return;

                var index = (_inbound != null && _inbound.proposal && _inbound.proposal.index != null) ? _inbound.proposal.index : _product.outbound.proposal ? _product.outbound.proposal.index : null;
                var group = _inbound != null && _inbound.proposal && _inbound.proposal.groupName ? _inbound.proposal.groupName : _product.outbound.proposal.groupName;

                if ($scope.purchaseid) {
                    //Create new purchase wuth old one add hotel
                    //Redirect to BasketUpdateFlight(string requestId, string purchaseId, int index, string groupName)
                    $window.FLoading({ vAction: "show" });
                    const url = new URL(document.URL);
                    const params = new URLSearchParams(url.search);
                    params.set("requestId", $scope.summary.requestId);
                    params.set("purchaseId", $scope.purchaseid);
                    params.set("index", index);
                    params.set("groupName", group);
                    //var redirect = '/BasketUpdateFlight?requestId=' + $scope.summary.requestId + '&purchaseId=' + $scope.purchaseid + '&index=' + index + '&groupName=' + group;
                    //if (urlParmas.has('hotelUrlParams')) redirect += "&hotelUrlParams=" + urlParmas.get('hotelUrlParams')

                    $window.location.href = 'BasketUpdateFlight?' + params.toString();
                    return;
                }

                var purchaseRequest = {
                    index: index,
                    group: group,
                    proposal: 0,
                    brandedFares: true,
                    requestId: $scope.summary.requestId
                };

                //required to bind jQuery listener
                if (_index) {
                    $(_event.target).trigger("bookingSelected", { product: _product, inbound: _inbound, index: $scope.index, retIndex: _index });
                }

                //comment this line to test stay on results page
                $scope.$emit("onProductBookingClick", { purchaseRequest: purchaseRequest, outbound: $scope.product.outbound, inbound: _inbound });
            };

            $scope.availableSeat = function (itinerary) {
                var seats = itinerary.availableSeats;
                if (seats > 0) {
                    seats = Math.max(seats, $scope.summary.totalPaxes);
                    if (seats > ($scope.summary.totalPaxes + 2))
                        seats = 0; //don't show the seats message
                    return seats;
                };
                return seats;
            };
            var firstSuplement = false;
            $scope.showSuplementLine = function (returnProduct, index, product) {

                if($scope.isServair && index==0){
                    firstSuplement = false;
                    return false;
                }

                if (index == 0) firstSuplement = false;     
                if ($scope.isServair && returnProduct.proposal.totalSubvention > product.minProposal.totalSubvention && !firstSuplement) {
                    firstSuplement = true;
                    return true;
                }       
                else if (!$scope.isServair && returnProduct.proposal.supplementAverageFareFeeMargin.value > 0 && !firstSuplement) {
                        firstSuplement = true;
                        return true;
                    }   

                return false;
            };

            //Check Cabin Classes for messages in segments
            $scope.alertCabinClass = function (segmentCabinClass) {

                if ($scope.requestcabinclass == segmentCabinClass)
                    return true;

                if ($scope.requestcabinclass == "C")
                    if (segmentCabinClass == "C" || segmentCabinClass == "J") return true;

                if ($scope.requestcabinclass == "F")
                    if (segmentCabinClass == "F" || segmentCabinClass == "P") return true;

                return false;
            };

            $scope.existTaxes = function (fares) {
                if (fares) {
                    if (fares.totalTax && fares.totalTax.value > 0)
                        if (fares.totalTaxSummary)
                            if (fares.totalTaxSummary.airport > 0 || fares.totalTaxSummary.fuel)
                                return true;
                }
                return false;
            };

            $scope.hoverInCO2 = function (event, index, isFlightBack) {
                if (!event || !event.currentTarget) {
                    return;
                }

                var element = event.currentTarget
                var popin = element.nextElementSibling

                if (!popin) {
                    return;
                }

                var popinOffset = popin.getBoundingClientRect().top + popin.getBoundingClientRect().height;
                var compareElement = isFlightBack ? $scope.findFlightBackContainer(index) : window.innerHeight;

                if (!popinOffset === undefined || !compareElement) {
                    return;
                }

                if (popinOffset >= compareElement) {
                    popin.classList.add('__top');
                }
            };

            $scope.hoverOutCO2 = function (event) {
                if (!event || !event.currentTarget) {
                    return;
                }

                var element = event.currentTarget
                var popin = element.nextElementSibling

                if (!popin) {
                    return;
                }

                popin.classList.remove('__top');
            };

            $scope.findFlightBackContainer = function (index) {
                var container = document.getElementById('i-smooth-back-' + index);
                if (container) {
                    return container.getBoundingClientRect().top + container.getBoundingClientRect().height;
                }
                return;
            }

            $scope.abs = function (val) {
                return Math.abs(parseInt(val));
            }
        };
        return directive;
    }]);